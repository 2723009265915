<template>
  <AccordionItem
    :number="number"
    title="AUDIT AND RECORD KEEPING"
  >
    <div class="first-level">
      <p class="number">
        {{ number }}.1.
      </p>
      <p>
        Either Party shall be entitled to audit at its own expense
        (and/or to appoint a third party auditor to audit) the other
        Party’s compliance with the terms of these terms and Works
        Order at any time during the Term of these terms and Works
        Order or during the twelve (12) months following termination
        or expiry. The Party requesting the audit shall provide
        reasonable notice, unless the audit arises as a result of
        suspected fraud or serious breach of these terms and Works Order
        in which case the notice may be shorter and in the event the
        auditor concludes the finding of fraud or serious breach the
        Parties will negotiate an appropriate split of the audit costs
        incurred. The Parties shall permit and/or shall procure that
        the other (and/or its auditor) shall be permitted to access the
        premises, facilities, personnel, records, books, accounts,
        procedures (including data processing, security and organisational
        procedures) and information as may be required by the Party
        requesting the audit and/or the auditor for the purpose of such
        auditing. Where the Party appoints a third party auditor, it
        shall ensure that such auditor is subject to appropriate
        confidentiality obligations.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.2.
      </p>
      <p>
        Both Parties shall maintain a complete and accurate set of records
        of all activities undertaken in relation to the performance of
        these terms and Works Order and its obligations under it, and
        shall retain all such records during the Term of these terms
        and Works Order for a period of not less than six (6) years
        following termination of these terms and Works Order
        (or such longer period as may be required by law).
      </p>
    </div>
  </AccordionItem>
</template>

<script>
import AccordionItem from '../../components/AccordionItem.vue';

export default {
  components: {
    AccordionItem,
  },
  props: {
    number: {
      type: String,
      default: null,
    },
  },
};
</script>
