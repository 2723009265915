<template>
  <div
    class="section hero"
    :style="{
      backgroundImage: `url('${imgSrc}')`,
    }"
  >
    <img :src="imgSrc">
    <div class="section-inner row">
      <h1>{{ title }}</h1>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    imgSrc: {
      type: String,
      default: null,
    },
  },
};
</script>

<style scoped>
.hero {
  width: 100%;
  height: 596px;
  /* background-image: url('/img/map/banner.jpg'); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  /* background-color: #ccc; */
  position: relative;
  overflow: hidden;
  justify-content: flex-end;
}

.hero::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  opacity: 0.3;
}

.hero .section-inner {
  justify-content: flex-end;
  height: 100%;
  position: relative;
}

.hero h1 {
  display: inline-block;
  max-width: 295px;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 0;
  color: #fff;
  position: absolute;
  bottom: 72px;
  right: 0;
}
img {
  display: none;
}
@media (max-width: 768px) {
  .section {
    height: auto;
    background: none;
    padding: 0;
  }
  .section-inner {
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 0;
  }
  img {
    display: block;
    /* min-width: 100%;
    min-height: 226px;
    max-width: 200%; */
    position: relative;
    width: 100%;
    min-width: 550px;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .hero h1 {
    color: #111111;
    font-size: 25px;
    width: 100%;
    max-width: 100%;
    line-height: 70px;
    background: #F4F4F4;
    text-align: center;
  }
}
</style>
