<template>
  <AccordionItem
    :number="number"
    title="WARRANTY"
  >
    <div class="first-level">
      <p class="number">
        {{ number }}.1.
      </p>
      <p>
        Cloud KB warrants that the Software provided to the Customer under the
        relevant Works Order will conform in all material respects to the
        specification (excluding any Beta Services) starting from the end of
        any Professional Services provided by Cloud KB under the relevant
        Works Order for the purposes of implementing the Solution, for a
        period of forty-five (45) calendar days  ("<strong>Warranty Period</strong>").
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.2.
      </p>
      <p>
        If, within the Warranty Period, the Customer notifies Cloud KB in
        writing of any defect or fault in the Software in consequence of
        which it fails to conform in all material respects to the specification,
        and such defect or fault does not result from the Customer, or anyone
        acting with the authority of the Customer, having amended the Software
        or used it outside the terms of these terms and Works Order for a purpose
        or in a context other than the purpose or context for which it was designed
        or in combination with any other software not provided by Cloud KB, or it
        has not been loaded onto Cloud KB specified or suitably configured equipment,
        Cloud KB shall, at its option, do one of the following:
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.2.1.
      </p>
      <p>
        repair the Software;
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.2.2.
      </p>
      <p>
        replace the Software; or
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.2.3.
      </p>
      <p>
        terminate these terms and Works Order immediately by notice in
        writing to the Customer and refund any of the Fees paid by the
        Customer as at the date of termination (on a pro rata basis relative
        to the Customer’s use of the Solution prior to the date on which the
        Customer notified Cloud KB of the defect or fault) on return of the
        Software and all copies thereof,
      </p>
    </div>
    <div class="first-level">
      <p class="number" />
      <p>
        provided the Customer provides all the
        information that may be necessary to assist Cloud KB in resolving the
        defect or fault, including a documented example of any defect or fault,
        or sufficient information to enable Cloud KB to re-create the defect or fault.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.3.
      </p>
      <p>
        Cloud KB does not warrant that the use of the Software will be
        uninterrupted or error-free.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.4.
      </p>
      <p>
        The Customer accepts responsibility for the selection of the Software
        to achieve its intended results and acknowledges that the Software
        has not been developed to meet the individual requirements of the Customer.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.5.
      </p>
      <p>
        Without limitation to Clause 24 (Force Majeure), the Customer
        acknowledges that Cloud KB has no direct control over the availability
        of bandwidth over the entirety of the internet and that, while Cloud
        KB will use such endeavours as it deems appropriate to facilitate
        the Solution, Cloud KB shall not be responsible for delays caused
        by such unavailability.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.6.
      </p>
      <p>
        Except as expressly provided in these terms and Works Order, the
        Solution is provided "as is" and to the extent permitted by law,
        Cloud KB disclaims all other conditions, warranties, representations,
        undertakings or other terms which might have effect between the
        parties with respect to the Solution, or be implied or incorporated
        into these terms and Works Order, whether by statute, common law,
        custom or otherwise, including any implied conditions, warranties,
        undertakings or other terms relating to satisfactory quality,
        reasonable skill and care, fitness for purpose, ability to achieve
        a particular result or arising from course of dealing or usage of trade.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.7.
      </p>
      <p>
        Cloud KB represents and warrants that any open source software
        provided by Cloud KB may be used according to the terms and
        conditions of the specific licence under which the relevant
        open source software is distributed. The Parties acknowledge
        and agree that any open source software provided by Cloud KB
        is on an ‘as is‘ basis.
      </p>
    </div>
  </AccordionItem>
</template>

<script>
import AccordionItem from '../../components/AccordionItem.vue';

export default {
  components: {
    AccordionItem,
  },
  props: {
    number: {
      type: String,
      default: null,
    },
  },
};
</script>
