<template>
  <AccordionItem
    :number="number"
    title="MODERN SLAVERY PRACTICES"
  >
    <div class="first-level">
      <p class="number">
        {{ number }}.1.
      </p>
      <p>
        Both Parties agree that they shall not engage in any Modern Slavery
        Practice.  Both Parties shall take all reasonable steps to ensure
        that there are no Modern Slavery Practices anywhere in their supply
        chain, whether that be by tier one contractors, agents, suppliers of
        goods or services or other parties further down their supply chain
        (<strong>Supply Chain Parties</strong>).
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.2.
      </p>
      <p>
        Both Parties shall provide to the other (at their own cost) any
        assistance and information (including access to records, persons
        and premises) as it may require from time to time to enable the
        requesting Party to (a) perform any activity required by any
        government, Regulatory Authority or agency in any relevant
        jurisdiction relating to Modern Slavery Practices or as required
        by the requesting Party, (b) prepare a slavery and human trafficking
        statement as required by section 54 of the Modern Slavery Act 2015
        or (c) identify any Modern Slavery Practice and carry out due
        diligence in relation to the effectiveness of any steps taken to
        counter any Modern Slavery Practice.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.3.
      </p>
      <p>
        Either Party shall immediately notify the other in writing if
        it becomes aware of a breach or suspected breach of any of its
        obligations under this Clause {{ number }} including the occurrence of
        any Modern Slavery Practice within its or any of its Supply
        Chain Party. Without prejudice to the other rights and remedies
        under this Master Agreement and Works Order, the Parties shall
        take all reasonable steps as requested by the other to address
        any instances of Modern Slavery Practices in their respective
        operations or the operations of any Supply Chain Party.
      </p>
    </div>
  </AccordionItem>
</template>

<script>
import AccordionItem from '../../components/AccordionItem.vue';

export default {
  components: {
    AccordionItem,
  },
  props: {
    number: {
      type: String,
      default: null,
    },
  },
};
</script>
