<template>
  <div class="icon-row">
    <h2>NEW EMERGING ENERGY OPPORTUNITIES</h2>
    <div>
      <div class="column">
        <img src="/img/icon-row/ev-charging.png">
        <p>EV CHARGING</p>
      </div>
      <div class="column">
        <img src="/img/icon-row/solar.png">
        <p>solar panels &amp; batteries</p>
      </div>
      <div class="column">
        <img src="/img/icon-row/heat-pumps.png">
        <p>heat pumps</p>
      </div>
      <div class="column">
        <img src="/img/icon-row/hydrogen.png">
        <p>hydrogen</p>
      </div>
      <div class="column">
        <img src="/img/icon-row/iea.png">
        <p>industry energy assets</p>
      </div>
      <div class="column">
        <img src="/img/icon-row/esm.png">
        <p>electric smart meters</p>
      </div>
      <div class="column">
        <img src="/img/icon-row/gsm.png">
        <p>gas smart meters</p>
      </div>
      <div class="column">
        <img src="/img/icon-row/water-meters.png">
        <p>water meters</p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
};
</script>

<style scoped>
  .icon-row {
    background-image: url('/img/icon-row/background.svg');
    background-size: auto 260px;
    background-position: bottom center;
    background-repeat: no-repeat;
    height: 381px;
  }

  h2 {
    text-align: center;
    font-size: 57px;
    letter-spacing: 2px;
    color: #1d1347;
    font-weight: 400;
    padding: 0 1rem;
  }

  .icon-row > div {
    max-width: 1252px;
    margin: 0 auto;
    padding: 0 1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 27px;
  }

  .icon-row .column {
    width: 135px;
    margin-left: 20px;
    text-align: center;
  }

  .icon-row .column:first-child {
    margin-left: 0;
  }

  .icon-row img {
    width: 100%;
    margin-bottom: 0.25rem;
  }

  .column p {
    font-family: "Roboto-Condensed-Bold";
    color: #1d1347;
    font-size: 1rem;
    text-transform: uppercase;
  }

  @media (max-width: 1210px) {
    h2 {
      font-size: 46px;
    }
  }

  @media (max-width: 1024px) {
    h2 {
      font-size: 40px;
    }
  }

  @media (max-width: 960px) {
    .icon-row {
      background-image: none;
      height: auto;
    }

    .icon-row > div {
      flex-wrap: wrap;
    }

    .icon-row .column,
    .icon-row .column:first-child {
      margin: 0 0.5rem;
    }

  }

  @media (max-width: 768px) {
    h2 {
      font-size: 24px;
      font-weight: 900;
    }

    .icon-row > div {
      flex-wrap: wrap;
      justify-content: space-around;
    }

    .icon-row .column {
      width: 120px;
      margin-bottom: 1.5rem;
    }
  }
</style>
