<template>
  <div class="section section-transparent">
    <div class="section-inner row">
      <div class="block-info">
        <h2 class="title">
          The Details
        </h2>
        <hr class="info-hr">
      </div>
    </div>
    <div
      :class=" `section-inner row cols-${columns.length}` "
    >
      <div
        v-for="(column, c) in columns"
        :key="c"
        class="content"
      >
        <template v-if="column.text">
          <p
            v-for="(paragraph, i) in column.text"
            :key="i"
          >
            {{ paragraph }}
          </p>
        </template>
        <ul v-if="column.list">
          <li
            v-for="(item, i) in column.list"
            :key="i"
          >
            {{ item.text }}
            <ul v-if="item.items">
              <li
                v-for="(li, j) in item.items"
                :key="j"
              >
                {{ li }}
              </li>
            </ul>
          </li>
        </ul>
        <template v-if="column.textAfter">
          <br>
          <p
            v-for="(paragraph, i) in column.textAfter"
            :key="i"
          >
            {{ paragraph }}
          </p>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped>
.section{
    padding-top: 105px;
    padding-bottom: 15px;
}

.section-inner > div {
  width: 100%;
  max-width: 100%;
  margin: 0;
}

.cols-2 > div {
  width: 50%;
  max-width: 510px
}

ul li ul li {
  margin-top: 0;
}
ul li ul {
  margin-top: 22px;
}

@media (max-width: 768px) {
  .section {
    padding-top: 59px;
    padding-bottom: 0;
  }

  .section-inner {
    flex-direction: column;
  }
  .section-inner > div {
    width: 100%;
    max-width: 100%;
    margin: 0;
  }

  .block-info  .title {
    text-align: left;
  }

  .info-hr {
    margin: 0;
  }

  .content {
    padding: 17px 0 37px 0;
  }
}
</style>
