<template>
  <AccordionItem
    :number="number"
    title="GOVERNING LAW AND JURISDICTION"
  >
    <div class="first-level">
      <p class="number">
        {{ number }}.1.
      </p>
      <p>
        These terms and Works Order and any dispute or claim arising
        out of or in connection with it or its subject matter or formation
        (including non-contractual disputes or claims) shall be governed
        by and construed in accordance with the law of England.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.2.
      </p>
      <p>
        The Parties irrevocably agree that the courts of England shall
        have exclusive jurisdiction to settle any dispute or claim that
        arises out of or in connection with these terms and Works Order
        or its subject matter or formation (including non-contractual
        disputes or claims).
      </p>
    </div>
  </AccordionItem>
</template>

<script>
import AccordionItem from '../../components/AccordionItem.vue';

export default {
  components: {
    AccordionItem,
  },
  props: {
    number: {
      type: String,
      default: null,
    },
  },
};
</script>
