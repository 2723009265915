<template>
  <div class="section section-problem-solution">
    <div class="section-inner row">
      <div>
        <div class="block-info">
          <h2 class="title">
            The Problem
          </h2>
          <hr class="info-hr">
        </div>
        <div class="content">
          <p
            v-for="(paragraph, i) in value.problem"
            :key="i"
          >
            {{ paragraph }}
          </p>
        </div>
      </div>
      <div>
        <div class="block-info">
          <h2 class="title">
            The Solution
          </h2>
          <hr class="info-hr">
        </div>
        <div class="content">
          <p
            v-for="(paragraph, i) in value.solution"
            :key="i"
          >
            {{ paragraph }}
          </p>
          <a
            href="/contact-us"
            class="btn btn-green btn-wide"
          >
            Contact us now to find out how
          </a>
          <p>
            <a
              v-if="value.brochureSrc"
              :href="value.brochureSrc"
              target="_blank"
              class="btn btn-white btn-wide"
            >
              DOWNLOAD BROCHURE
            </a>
          </p>
          <div
            v-if="value.solutionButtons"
            class="row buttons-row"
          >
            <a
              v-for="(item, i) in value.solutionButtons"
              :key="i"
              target="_blank"
              :href="item.href"
              class="btn btn-green"
            >
              {{ item.text }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style scoped>
.section {
  padding-top: 109px;
  padding-bottom: 112px;
}

.section-inner>div {
  width: 50%;
  max-width: 514px;
}

.btn-wide {
  padding: 0 61px;
  width: 100%;
  max-width: 400px;
}

.btn-white {
  color: #63afb7;
  margin-top: 1rem;
}
.buttons-row {
  max-width: 100%;
  margin-top: 15px;
  justify-content: flex-start;
}

.buttons-row a {
  margin-right: 15px;
  padding: 0 15px;
    line-height: 41px;
}

@media (max-width: 768px) {
  .section {
    padding-top: 59px;
    padding-bottom: 0;
  }

  .section-inner {
    flex-direction: column;
  }

  .section-inner>div {
    width: 100%;
    max-width: 100%;
    margin: 0;
  }

  .block-info .title {
    text-align: left;
  }

  .info-hr {
    margin: 0;
  }

  .content {
    padding: 17px 0 37px 0;
  }

  .btn-wide {
    display: block;
    margin: 0 auto;
    padding: 0 15px;
  }
  .buttons-row {
    justify-content: space-between;
  }

  .buttons-row a {
    margin: 0;
    padding: 0 15px;
    max-width: 48%;
    max-width: calc(50% - 7.5px);
  }
}
</style>
