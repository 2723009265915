<!-- eslint-disable -->
<template>
  <div class="wrapper">
    <div class="card">
      <img src="/img/home/jobs.png">
      <h4>uMESH: Jobs</h4>
      <p>Supporting your Customer and Field Installation Journey. uMESH Capacity &amp; Scheduling modules will ensure
        that the right volume of appointments are booked to capacity. <a style="font-size:20px"
          href="/contact-us">Contact us</a> to find out more</p>
    </div>
    <div class="card">
      <img src="/img/home/assets.png">
      <h4>uMESH: Assets</h4>
      <p>Tracking the LifeCycle to reduce waste and protect your Assets</p>
    </div>
    <div class="card">
      <img src="/img/home/meterpoints.png">
      <h4>uMESH: MeterPoints</h4>
      <p>Automating industry flows using real-time data logic</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.wrapper {
  max-width: 1230px;
  margin: 3rem auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.card {
  width: 33.33333%;
  padding: 0 1rem;
}

.card img {
  display: block;
  width: 100%;
}

.card h4 {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  margin: 2rem 0 0.75rem;
  font-family: "Roboto-Bold";
}

.card p {
  font-size: 20px;
  color: rgb(0, 0, 0);
  line-height: 1.66;
  text-align: center;
}

@media (max-width: 768px) {
  .card {
    width: 100%;
    max-width: 375px;
    margin-bottom: 3rem;
  }
}
</style>
