<template>
  <AccordionItem
    :number="number"
    title="TERM AND TERMINATION"
  >
    <div class="first-level">
      <p class="number">
        {{ number }}.1.
      </p>
      <p>
        These terms shall commence on the Commencement Date. Unless
        terminated earlier in accordance with its terms, these terms
        shall continue in force for the duration of the Initial Term
        (stated in the Works Order) and shall automatically extend for
        a period of twelve (12) months upon the expiry of the Initial
        Term and on each subsequent anniversary of such expiry (each
        such extension, a <strong>"Renewal Period"</strong>) unless
        either Party gives at least ninety (90) days’ written notice
        to the other Party before the end of the Initial Term (or the
        applicable Renewal Period) to terminate these terms and Works
        Order at the end of the Initial Term or the applicable Renewal
        Period, in which case these terms and Works Order shall terminate
        at 23:59pm (GMT) on the last day of the Initial Term (or the
        applicable Renewal Period).
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.2.
      </p>
      <p>
        The Initial Term together with any subsequent Renewal Periods
        shall constitute the <strong>"Term"</strong>
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.3.
      </p>
      <p>
        Without affecting any other right or remedy available to it,
        within the first six (6) months of the Initial Term Cloud KB may
        terminate for convenience these terms and Works Order in whole by
        providing at least thirty (30) days prior written notice to Customer.
        In the event Cloud KB terminates under this Clause {{ number }}.3 all Fees paid
        shall be non-refundable.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.4.
      </p>
      <p>
        Without affecting any other right or remedy available to it, during
        the Term of these terms and Works Order Cloud KB may terminate these
        terms and Works Order as a result of:
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.4.1.
      </p>
      <p>
        the agreed volumes under the Works Order do not materialise
        resulting in a lower than expected Fee in comparison to the
        effort involved in providing the Solution; and/or
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.4.2.
      </p>
      <p>
        the Solution does not go-live on the date agreed in the Works
        Order due to reasons within the reasonable control of the Customer.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.5.
      </p>
      <p>
        Without affecting any other right or remedy available to it, either
        Party may terminate these terms and Works Order at any time and with
        immediate effect by giving written notice to the other Party if:
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.5.1.
      </p>
      <p>
        the other Party fails to pay any amount due under these terms and
        Works Order on the due date for payment and remains in default not
        less than thirty (30) calendar days after being notified in writing
        to make such payment;
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.5.2.
      </p>
      <p>
        the other Party commits a material breach of any other term of
        these terms and Works Order which breach is irremediable or (if
        such breach is remediable) fails to remedy that breach within a
        period of thirty (30) calendar days after being notified in
        writing to do so;
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.5.3.
      </p>
      <p>
        the other Party suspends, or threatens to suspend, payment of its
        debts or is unable to pay its debts as they fall due or admits
        inability to pay its debts or is deemed unable to pay its debts
        within the meaning of section 123 of the Insolvency Act 1986;
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.5.4.
      </p>
      <p>
        the other Party commences negotiations with all or any class of
        its creditors with a view to rescheduling any of its debts, or makes
        a proposal for or enters into any compromise or arrangement with
        its creditors other than for the sole purpose of a scheme for a
        solvent amalgamation of that other Party with one or more other
        companies or the solvent reconstruction of that other Party;
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.5.5.
      </p>
      <p>
        a petition is filed, a notice is given, a resolution is passed,
        or an order is made, for or in connection with the winding up of
        that other Party other than for the sole purpose of a scheme for
        a solvent amalgamation of that other Party with one or more other
        companies or the solvent reconstruction of that other Party;
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.5.6.
      </p>
      <p>
        an application is made to court, or an order is made, for the
        appointment of an administrator, or if a notice of intention to
        appoint an administrator is given or if an administrator is
        appointed, over the other Party;
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.5.7.
      </p>
      <p>
        the holder of a qualifying floating charge over the assets of
        that other Party has become entitled to appoint or has appointed
        an administrative receiver;
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.5.8.
      </p>
      <p>
        a person becomes entitled to appoint a receiver over the assets
        of the other Party or a receiver is appointed over the assets of
        the other Party;
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.5.9.
      </p>
      <p>
        a creditor or encumbrancer of the other Party attaches or takes
        possession of, or a distress, execution, sequestration or other
        such process is levied or enforced on or sued against, the whole
        or any part of the other Party’s assets and such attachment or
        process is not discharged within fourteen (14) calendar days;
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.5.10.
      </p>
      <p>
        any event occurs, or proceeding is taken, with respect to the other
        Party in any jurisdiction to which it is subject that has an effect
        equivalent or similar to any of the events mentioned in Clauses
        {{ number }}.5.3 to {{ number }}.5.9 (inclusive); or
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.5.11.
      </p>
      <p>
        the other Party suspends or ceases, or threatens to suspend or
        cease, carrying on all or a substantial part of its business.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.6.
      </p>
      <p>
        Termination or expiry of these terms and Works Order shall not
        affect any rights, remedies, obligations or liabilities of the
        Parties that have accrued up to the date of termination or expiry,
        including the right to claim damages in respect of any breach of
        these terms and Works Order which existed at or before the date
        of termination or expiry.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.7.
      </p>
      <p>
        On termination for any reason:
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.7.1.
      </p>
      <p>
        all rights granted to the Customer and the Authorised Users under
        these terms and Works Order shall immediately cease;
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.7.2.
      </p>
      <p>
        the Customer and the Authorised Users shall cease all activities
        authorised by these terms and Works Order; and
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.7.3.
      </p>
      <p>
        the Customer shall immediately pay to Cloud KB any sums due to it
        under these terms and Works Order, or Cloud KB shall provide a pro
        rata refund for any fees paid in advance for use not yet made at
        the point of termination.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.8.
      </p>
      <p>
        Within thirty (30) calendar days of termination, Cloud KB shall
        delete all Customer Data in its possession except as may be required
        to manage any claim, legal requirement or residual obligation under
        these terms and Works Order.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.9.
      </p>
      <p>
        Any provision of these terms and Works Order which expressly or
        by implication is intended to come into or continue in force on or
        after termination of these terms and Works Order, shall remain in
        full force and effect.
      </p>
    </div>
  </AccordionItem>
</template>

<script>
import AccordionItem from '../../components/AccordionItem.vue';

export default {
  components: {
    AccordionItem,
  },
  props: {
    number: {
      type: String,
      default: null,
    },
  },
};
</script>
