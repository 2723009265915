<template>
  <AccordionItem
    :number="number"
    title="NO PARTNERSHIP OR AGENCY"
  >
    <div class="first-level">
      <p class="number">
        {{ number }}.1.
      </p>
      <p>
        Nothing in these terms is intended to, or shall be deemed to,
        establish any partnership or joint venture between any of the parties,
        constitute any party the agent of another party, or authorise any
        party to make or enter into any commitments for or on behalf of
        any other party.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.2.
      </p>
      <p>
        Each Party confirms it is acting on its own behalf and not for
        the benefit of any other person.
      </p>
    </div>
  </AccordionItem>
</template>

<script>
import AccordionItem from '../../components/AccordionItem.vue';

export default {
  components: {
    AccordionItem,
  },
  props: {
    number: {
      type: String,
      default: null,
    },
  },
};
</script>
