<template>
  <AccordionItem
    :number="number"
    title="VARIATION"
  >
    <div class="first-level">
      <p class="number">
        {{ number }}.1.
      </p>
      <p>
        Subject to Clause 26, no variation of these terms and Works Order
        shall be effective unless it is in writing and signed by the
        Parties (or their authorised representatives).
      </p>
    </div>
  </AccordionItem>
</template>

<script>
import AccordionItem from '../../components/AccordionItem.vue';

export default {
  components: {
    AccordionItem,
  },
  props: {
    number: {
      type: String,
      default: null,
    },
  },
};
</script>
