<template>
  <AccordionItem
    :number="number"
    title="GRANT OF RIGHTS"
  >
    <div class="first-level">
      <p class="number">
        {{ number }}.1.
      </p>
      <p>
        In consideration of the Fee paid by the Customer to Cloud KB and
        subject to the other terms and conditions herein and in the Works Order,
        Cloud KB grants to the Customer a non-exclusive, revocable, UK only
        non-transferable, non-sublicensable right to use and access the
        Software and Solution and to permit the Authorised Users to use
        and access, the Software and Solution in the Authorised Location,
        solely for the Customer's permitted purposes as stated in the
        Works Order during the Term.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.2.
      </p>
      <p>
        Cloud KB may amend the Solution and the service description contained
        within the Works Order from time to time provided such amendments
        apply to the majority of customers for the Solution and do not
        materially impact the functionality, performance or security of
        the Solution. Cloud KB shall endeavour to inform the Customer
        of such amendments in advance of its implementation and shall
        endeavour to apply the amendments during periods that cause the
        least disruption to the functionality of the Solution.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.3.
      </p>
      <p>
        <strong>Acceptable use</strong>: the Customer shall use the Solution in accordance
        with the terms of these terms, the Works Order and any Cloud KB
        policies; and all Applicable Law. The Customer shall not knowingly
        and shall procure that its Authorised Users shall not knowingly:
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.3.1.
      </p>
      <p>
        use the Solution other than in object code form for the purpose of
        processing its data for the specific purpose stated in the Works
        Order (which shall not include allowing the use of the Solution by,
        or for the benefit of, any person other than an employee or agent
        of the Customer); or
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.3.2.
      </p>
      <p>
        use the Solution other than as specified in this Clause {{ number }} without
        the prior written consent of Cloud KB, and the Customer acknowledges
        that additional fees may be payable on any change of use approved
        by Cloud KB; or
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.3.3.
      </p>
      <p>
        (except as expressly stated in this Clause 4), allow or permit any third party to:
      </p>
    </div>
    <div class="third-level">
      <p class="number">
        {{ number }}.3.3.1.
      </p>
      <p>
        attempt to copy, reproduce, duplicate, adapt, reverse engineer, decompile,
        disassemble, modify, manufacture, adapt, create derivative works from or
        distribute all or any portion of the Solution or make error corrections
        to the Software in whole or in part except to the extent that any
        reduction of the Software to human readable form (whether by reverse
        engineering, decompilation or disassembly) is necessary for the purposes
        of integrating the operation of the Software with the operation of other
        software or systems used by the Customer, unless Cloud KB is prepared
        to carry out such action at a reasonable commercial fee or has provided
        the information necessary to achieve such integration within a reasonable
        period, and the Customer shall request Cloud KB to carry out such action
        or to provide such information (and shall pay Cloud KB reasonable costs
        in providing that information) before undertaking any such reduction;
      </p>
    </div>
    <div class="third-level">
      <p class="number">
        {{ number }}.3.3.2.
      </p>
      <p>
        access all or any part of the Solution in order to build a product or
        service which competes with the Solution;
      </p>
    </div>
    <div class="third-level">
      <p class="number">
        {{ number }}.3.3.3.
      </p>
      <p>
        attempt to undertake any security testing of the Solution without its
        prior written consent; or
      </p>
    </div>
    <div class="third-level">
      <p class="number">
        {{ number }}.3.3.4.
      </p>
      <p>
        allow any third parties to access or use the Solution (save for
        its Authorised Users in accordance with the terms of these terms,
        any Works Order or policies); or
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.3.4.
      </p>
      <p>
        use any such information provided by Cloud KB or obtained by the
        Customer during any such reduction permitted under Clause {{ number }}.3.3.1
        to create any software whose expression is substantially similar to
        that of the Software nor use such information in any manner which
        would be restricted by any copyright subsisting in it; or
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.3.5.
      </p>
      <p>
        insert or introduce any Malicious Software or viruses into the Solution; or
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.3.6.
      </p>
      <p>
        store, distribute or transmit any material, information or data
        through the Solution that is unlawful, harmful, threatening,
        defamatory, obscene, infringing, harassing or racially or
        ethnically offensive; facilitates illegal activity; depicts
        sexually explicit images; or promotes unlawful violence, discrimination
        based on race, gender, colour, religious belief, sexual orientation,
        disability, or any other illegal activities.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.4.
      </p>
      <p>
        For the avoidance of doubt, any Third-Party Software (if applicable)
        shall be deemed to be incorporated within the Solution for the
        purposes of these terms, as specifically stated in the relevant
        Works Order and the Customer agrees that they shall at all
        times adhere to the terms of the Third-Party Additional Terms.
        The Customer shall indemnify and hold Cloud KB harmless against
        any loss or damage suffered or incurred as a result of the
        Customer’s breach of any Third-Party Additional Terms; howsoever arising.
      </p>
    </div>
  </AccordionItem>
</template>

<script>
import AccordionItem from '../../components/AccordionItem.vue';

export default {
  components: {
    AccordionItem,
  },
  props: {
    number: {
      type: String,
      default: null,
    },
  },
};
</script>
