<template>
  <div class="section umesh">
    <div class="sky" />
    <div class="section-inner row">
      <div class="block-info">
        <h2 class="title">
          Our Field Service App
        </h2>
        <hr class="info-hr">
        <div class="content">
          <p>
            Our native Android app provides a reliable commissioning tool,
            the uMESH Field Service Android App gives you the additional
            benefits:
          </p>
          <ul class="content">
            <li>
              Ability to collect data via Smart Worksheets. These configurable
              lists of questions minimise human error by requesting only
              required information and validating inputs in real-time.
            </li>
            <li>
              Control the creation of jobs and monitor their completion via the
              uMESH platform’s screens and reports.
            </li>
            <li>
              Obtain PDF summaries of the data collected in each of the
              engineer visits.
            </li>
          </ul>
        </div>
      </div>
      <div class="umesh-device">
        <img src="/img/smc/uMESH-app.png">
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.section {
  position: relative;
  padding-top: 88px;
  padding-bottom: 88px;
}

.section-inner {
  align-items: center;
}

.block-info {
  width: 60%;
  max-width: 615px;
}

.umesh-device {
  max-width: 335px;
}

.umesh-device img {
  height: 637px;
}

.sky {
  position: absolute;
  bottom: -310px;
  right: 50%;
  width: 761px;
  height: 1571px;
  transform: translate(110%, 0);
  background-image: url(/img/smc/sky2.png);
  background-size: auto 100%;
  z-index: 1;

}

@media (max-width: 768px) {
  .section {
    padding-top: 59px;
    padding-bottom: 0;
  }

  .section-inner {
    flex-direction: column-reverse;
  }

  .section-inner>div {
    width: 100%;
    max-width: 100%;
    margin: 0;
  }

  .block-info .title {
    text-align: left;
  }

  .info-hr {
    margin: 0;
  }

  .content {
    padding: 17px 0 37px 0;
  }

  .section-inner>.umesh-device {
    width: auto;
    margin-bottom: 71px;
  }

  .umesh-device img {
    height: 399px;
  }

  .sky {
    display: none;
    top: -310px;
    right: -91px;
    width: 476px;
    height: 984px;
  }
}

@media (max-width: 600px) {
  .sky {
    display: block;
    top: -310px;
    right: -91px;
    width: 476px;
    height: 984px;
  }
}
</style>
