import Vue from 'vue';
import Router from 'vue-router';
import home from './views/home/index.vue';
import cookiePolicy from './views/cookiePolicy.vue';
import privacyPolicy from './views/privacyPolicy.vue';
import termsOfService from './views/termsOfService.vue';
import termsOfServiceNew from './views/termsOfServiceNew.vue';
import smartMeterCommissioning from './views/smart-meter-commissioning/index.vue';
import blog from './views/blog/index.vue';
// import meterAssetProvider from './views/meter-asset-provider/index.vue';
import contact from './views/contact/index.vue';
import company from './views/company/index.vue';
// import meterAssetManager from './views/meter-asset-manager/index.vue';
// import mamCaseStudy from './views/mam-case-study/index.vue';
// import mapCaseStudy from './views/map-case-study/index.vue';
// import bespokeflowCaseStudy from './views/bespokeflow-case-study/index.vue';
// import mop from './views/mop/index.vue';
import abcPolicy from './views/abcPolicy.vue';
import modernSlavery from './views/modernSlavery.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: home,
    },
    {
      path: '/cookie-policy',
      name: 'Cookie Policy',
      component: cookiePolicy,
    },
    {
      path: '/privacy-policy',
      name: 'Privacy Policy',
      component: privacyPolicy,
    },
    {
      path: '/terms-of-service',
      name: 'Terms of Service',
      component: termsOfService,
    },
    {
      path: '/terms-and-conditions',
      name: 'Terms and Conditions',
      component: termsOfServiceNew,
    },
    {
      path: '/smart-meter-commissioning',
      name: 'Smart Meter Commissioning',
      component: smartMeterCommissioning,
    },
    {
      path: '/blog',
      name: 'Blog',
      component: blog,
    },
    // {
    //   path: '/meter-asset-provider',
    //   name: 'Meter Asset Provider',
    //   component: meterAssetProvider,
    // },
    {
      path: '/contact-us',
      name: 'Contact us',
      component: contact,
    },
    {
      path: '/company',
      name: 'Сompany',
      component: company,
    },
    // {
    //   path: '/meter-asset-manager',
    //   name: 'Meter Asset Manager',
    //   component: meterAssetManager,
    // },
    // {
    //   path: '/mop',
    //   name: 'Mop',
    //   component: mop,
    // },
    // {
    //   path: '/casestudy-mam',
    //   name: 'MAM Case study',
    //   component: mamCaseStudy,
    // },
    // {
    //   path: '/casestudy-map',
    //   name: 'MAP Case study',
    //   component: mapCaseStudy,
    // },
    // {
    //   path: '/casestudy-bespokeflow',
    //   name: 'Case study bespokeflow',
    //   component: bespokeflowCaseStudy,
    // },
    {
      path: '/abc',
      name: 'ABC Policy',
      component: abcPolicy,
    },
    {
      path: '/modern-slavery',
      name: 'Modern Slavery',
      component: modernSlavery,
    },
  ],
});
