<template>
  <div>
    <div class="section privacy-policy">
      <div class="section-inner">
        <div class="block-info">
          <h2 class="title">
            Cloud kb Ltd – anti-bribery and corruption policy
          </h2>
          <hr class="info-hr">
        </div>

        <div class="content">
          <p>
            <strong>1. INTRODUCTION</strong>
          </p>
          <p>
            1.1 This Anti-Bribery and Corruption policy (the "<strong>Policy</strong>") applies to:
          </p>
          <ul>
            <li>
              (a) Cloud KB Ltd;
            </li>
            <li>
              (b) all Cloud KB Ltd employees including but not limited to officers,
              directors, interns and freelance workers; and
            </li>
            <li>
              (c) any other individuals or entities, agency workers, seconded workers,
              volunteers, interns, agents, contractors, external consultants,
              third-party representatives and business partners, or any other
              person performing services for and on behalf of any entity in
              Cloud KB Ltd, wherever located
            </li>
            <li>
              (collectively "<strong>Associated Persons</strong>").
            </li>
          </ul>
          <p>
            1.2 The Policy provides guidance to assist Associated Persons in conducting
            business activities with integrity, free from any form of bribery and corruption.
          </p>
          <p>
            1.3 As applicable, Associated Persons must comply with:
          </p>
          <ul>
            <li>
              (a) this Policy, including any future updates;
            </li>
            <li>
              (b) Cloud KB Ltd’s Expenses Policy; and
            </li>
            <li>
              (c) any other related materials issued from time to time.
            </li>
          </ul>

          <p>
            1.4 Cloud KB Ltd takes a zero-tolerance approach to bribery and corruption
            and is committed to acting professionally, fairly and with integrity in
            all our business dealings and relationships wherever we operate and
            implementing and enforcing effective systems to counter bribery and
            corruption. It is Cloud KB Ltd’s policy to conduct all of our business
            in an honest and ethical manner.
          </p>
          <p>
            1.5 We will uphold all laws relevant to countering bribery and corruption
            in all the jurisdictions in which we operate. In particular, we remain
            bound by UK laws, including the Bribery Act 2010, in respect of our
            conduct both at home and abroad.
          </p>
          <p>
            1.6 Any breach of the Policy by any Associated Persons will be regarded
            as an extremely serious matter and will result in disciplinary action up
            to or including dismissal and/or termination of contract.
          </p>
          <p>
            1.7 If you have any questions about the Policy or its associated procedures,
            please contact a member of the senior management team.
          </p>
        </div>

        <hr class="info-hr">

        <div class="content">
          <p>
            <strong>2. About the policy</strong>
          </p>
          <p>
            2.1 The purpose of the Policy is to:
          </p>
          <ul>
            <li>
              (a) Promote compliance with international anti-bribery laws,
              including the Bribery Act 2010;
            </li>
            <li>
              (b) Set out our responsibilities, and the responsibilities of those
              working for and on our behalf, in observing and
              upholding our position on bribery and corruption; and
            </li>
            <li>
              (c) Provide information and guidance to those working for and on
              our behalf on how to recognise and deal with bribery and corruption issues.
            </li>
          </ul>
          <p>
            2.2 It is a criminal offence to offer, promise, give, request, or accept
            a bribe. Individuals found guilty can be punished by up to ten years’ imprisonment
            and/or a fine. If Cloud KB Ltd fails to prevent bribery it could face an
            unlimited fine and damage to its reputation and the reputation of all
            Associated Persons.
          </p>
          <p>
            2.3 In the Policy, third party means any individual or organisation
            you come into contact with during the course of your work for us,
            and includes actual and potential clients, customers, suppliers,
            distributors, business contacts, agents, advisers and government and
            public bodies. Third party also includes advisors, representatives
            and officials of any of the bodies referred to in this paragraph,
            politicians and political parties.
          </p>
          <p>
            2.4 The Policy does not form part of any employee’s contract of employment
            and we may amend it at any time.
          </p>
        </div>

        <hr class="info-hr">

        <div class="content">
          <p>
            <strong>3. who is responsible for the policy?</strong>
          </p>

          <p>
            3.1 The board of directors of Cloud KB Ltd has overall responsibility
            for ensuring the Policy complies with our legal and ethical obligations,
            and that all those under our control comply with it.
          </p>
          <p>
            3.2 Management at all levels are responsible for ensuring those reporting
            to them understand and comply with the Policy and are given adequate and
            regular training on it.
          </p>
        </div>

        <hr class="info-hr">

        <div class="content">
          <p>
            <strong>4. what are bribery and corruption?</strong>
          </p>
          <p>
            4.1 Corruption can take innumerable forms and pervade all aspects of
            life; it is often difficult to identify. Transparency International
            defines corruption generally as "the abuse of entrusted power for private gain".
          </p>
          <p>
            4.2 Bribery is a form of corruption. It is the offering, promising,
            giving or accepting of any financial or other advantage, to induce the
            recipient or any other person to act improperly in the performance of their
            functions, or to reward them for acting improperly, or where the recipient
            would act improperly by accepting the advantage. Bribery can be retrospective
            as well with a financial or other advantage being provided for past improper
            performance.
          </p>
          <p>
            4.3 An advantage includes money, gifts, loans, fees, hospitality, services,
            discounts, the award of a contract or anything else of value.
          </p>
          <p>
            4.4 A person acts improperly where they act illegally, unethically, or
            contrary to an expectation of good faith or impartiality, or where they
            abuse a position of trust. The improper acts may be in relation to any
            business or professional activities, public functions, acts in the course
            of employment, or other activities by or on behalf of any organisation of
            any kind.
          </p>
          <p>
            4.5 It does not matter if a bribe is requested, agreed to or accepted
            directly or indirectly, each may still amount to an offence under the
            Bribery Act 2010.
          </p>
        </div>

        <hr class="info-hr">

        <div class="content">
          <p>
            <strong>5. what you must not do</strong>
          </p>
          <p>
            5.1 It is not acceptable for you (or someone on your behalf) to:
          </p>
          <ul>
            <li>
              (a) Give, promise to give, or offer, a payment, gift or hospitality with
              the expectation or hope that a business advantage will be received, or to
              reward a business advantage already given;
            </li>
            <li>
              (b) Give or accept a gift or hospitality during any commercial negotiations
              or tender process, if this could be perceived as intended or likely to
              influence the outcome;
            </li>
            <li>
              (c) Accept a payment, gift or hospitality from a third party that you
              know or suspect is offered with the expectation that it will provide a
              business advantage for them or anyone else in return;
            </li>
            <li>
              (d) Accept hospitality from a third party that is unduly lavish or extravagant
              under the circumstances;
            </li>
            <li>
              (e) Offer or accept a gift to or from government officials or
              representatives, or politicians or political parties, without the prior
              written approval of a director of Cloud KB Ltd.
            </li>
            <li>
              (f) Threaten or retaliate against another individual who has refused to
              commit a bribery offence or who has raised concerns under the Policy; or
            </li>
            <li>
              (g) Engage in any other activity that might lead to a breach of the Policy.
            </li>
          </ul>
        </div>

        <hr class="info-hr">

        <div class="content">
          <p>
            <strong>6. Facilitation payments and kickbacks</strong>
          </p>
          <p>
            6.1 We do not make, and will not accept, facilitation payments or
            "kickbacks" of any kind.
          </p>
          <p>
            6.2 Facilitation payments, also known as "back-handers" or "grease payments",
            are typically small, unofficial payments made to secure or expedite a
            routine or necessary action (for example by a government official).
            They are not common in the UK, but are common in some other jurisdictions
            in which we may operate.
          </p>
          <p>
            6.3 Kickbacks are typically payments made in return for a business favour
            or advantage.
          </p>
          <p>
            6.4 It should be noted that the Bribery Act 2010 makes no distinction between
            facilitation payments and bribes – regardless of size or local cultural
            expectations, even if that is "how business is done here".
          </p>
          <p>
            6.5 You must avoid any activity that might lead to a facilitation payment or
            kickback being made or accepted by us or on our behalf, or that might suggest
            that such a payment will be made or accepted. If you are asked to make a payment on
            our behalf, you should always be mindful of what the payment is for and whether
            the amount requested is proportionate to the goods or services provided. You should
            always ask for a receipt which details the reason for the payment. If you
            have any suspicions, concerns or queries regarding a payment, you should raise these
            with your immediate supervisor.
          </p>
        </div>

        <hr class="info-hr">

        <div class="content">
          <p>
            <strong>7. Gifts, Hospitality and expenses</strong>
          </p>
          <p>
            7.1 The Policy allows appropriate, transparent and bona fide hospitality or
            entertainment given to or received from third parties, for the purposes of:
          </p>
          <ul>
            <li>
              (a) Establishing or maintaining good business relationships;
            </li>
            <li>
              (b) Improving or maintaining our image or reputation; or
            </li>
            <li>
              (c) Marketing or presenting our products and/or services effectively.
            </li>
          </ul>

          <p>
            7.2 The giving and accepting of gifts is allowed if the following
            requirements are met:
          </p>
          <ul>
            <li>
              (a) It is not made with the intention of influencing a third party
              to obtain or retain business or a business advantage, or to reward
              the provision or retention of business or a business advantage, or
              in explicit or implicit exchange for
              favours or benefits;
            </li>
            <li>
              (b) It is given in the name of Cloud KB Ltd, not in the name of an
              Associated Person;
            </li>
            <li>
              (c) It does not include cash or a cash equivalent (such as gift
              certificates or vouchers);
            </li>
            <li>
              (d) It is appropriate in the circumstances, taking account of the
              reason for the gift, its timing and value. For example, in the UK
              it is customary for small gifts to be given at Christmas;
            </li>
            <li>
              (e) It is not lavish and/or obviously excessive;
            </li>
            <li>
              (f) The recipient is not a person with whom Cloud KB Ltd or an
              Associated Person is currently negotiating any contractual relationship;
            </li>
            <li>
              (g) It is given openly, not secretly; and
            </li>
            <li>
              (h) It complies with any applicable local law.
            </li>
          </ul>

          <p>
            7.3 No gift or entertainment may be given to any public official. If
            you wish to provide any gifts or entertainment to any public official,
            you are required first to obtain the written consent of a director of Cloud KB Ltd.
          </p>
          <p>
            7.4 Promotional gifts of low value such as branded stationery to or from
            existing customers, suppliers and business partners will usually be acceptable.
          </p>
          <p>
            7.5 Reimbursing a third party’s expenses, or accepting an offer to
            reimburse our expenses (for example, the costs of attending a business meeting)
            would not usually amount to bribery. However, a payment in excess of genuine
            and reasonable business expenses (such as the cost of an extended hotel stay) is not acceptable.
          </p>
          <p>
            7.6 We appreciate that practice varies between countries and regions and
            what may be normal and acceptable in one region may not be in another.
            The test to be applied is whether in all the circumstances the gift,
            hospitality or payment is reasonable and justifiable. The intention behind
            it should always be considered.
          </p>
        </div>

        <hr class="info-hr">

        <div class="content">
          <p>
            <strong>8. Record-keEping</strong>
          </p>

          <p>
            8.1 We must keep financial records and have appropriate internal
            controls in place which will evidence the business reason for making
            payments to third parties.
          </p>
          <p>
            8.2 You must declare and keep a written record of all hospitality or
            gifts given or received, which will be subject to managerial review.
          </p>
          <p>
            8.3 You must submit all expenses claims relating to hospitality, gifts
            or payments to third parties in accordance with our Expenses Policy
            and record the reason for expenditure.
          </p>
          <p>
            8.4 All accounts, invoices, and other records relating to dealings with
            third parties including suppliers and customers should be prepared with
            strict accuracy and completeness. Accounts must not be kept "off-book" to
            facilitate or conceal improper payments.
          </p>
        </div>

        <hr class="info-hr">

        <div class="content">
          <p>
            <strong>9. Your responsibilities</strong>
          </p>
          <p>
            9.1 You must ensure that you read, understand and comply with the Policy.
          </p>
          <p>
            9.2 The prevention, detection and reporting of bribery and other forms
            of corruption are the responsibility of all those working for us or
            under our control. You are required to avoid any activity that might
            lead to, or suggest, a breach of the Policy.
          </p>
          <p>
            9.3 You must notify your immediate supervisor as soon as possible if
            you believe or suspect that a breach of the Policy has occurred, or
            may occur in the future. For example, if a client or potential client
            offers you something to gain a business advantage with us, or indicates
            to you that a gift or payment is required to secure their business.
          </p>
        </div>

        <hr class="info-hr">

        <div class="content">
          <p>
            <strong>10. How to raise a concern</strong>
          </p>
          <p>
            10.1 You are encouraged to raise concerns about any issue or suspicion
            of bribery or corruption at the earliest possible stage.
          </p>
          <p>
            10.2 If you are offered a bribe, or are asked to make one, or if you
            believe or suspect that any bribery, corruption or other breach of
            the Policy has occurred or may occur, you must notify your immediate
            supervisor as soon as possible. If it is not possible to notify your
            immediate supervisor, you should notify a director of Cloud KB Ltd.
          </p>
          <p>
            10.3 If you are unsure about whether a particular act constitutes
            bribery or corruption, raise it with your immediate supervisor.
          </p>
          <p>
            10.4 Cloud KB Ltd maintains a [twenty-four hour phone] hotline for
            employees to raise concerns without fear of retaliation. Employees
            can confidentially report any concerns, which may include concerns
            relating to bribery and corruption. These reports are then investigated
            in accordance with company policies and procedures.
          </p>
        </div>

        <hr class="info-hr">

        <div class="content">
          <p>
            <strong>11. Protection</strong>
          </p>
          <p>
            11.1 We are committed to ensuring no one suffers any detrimental
            treatment as a result of refusing to take part in bribery or corruption,
            or because of reporting in good faith their suspicion that an actual or
            potential bribery or other corruption offence has taken place, or may
            take place in the future.
          </p>
        </div>

        <hr class="info-hr">

        <div class="content">
          <p>
            <strong>12. Breaches of the policy</strong>
          </p>
          <p>
            12.1 Any employee who breaches the Policy will face disciplinary
            action, which could result in dismissal for misconduct or gross misconduct.
          </p>
          <p>
            12.2 We may terminate our relationship with other individuals and
            organisations working on our behalf if they breach the Policy.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.content > p:first-child > strong {
  text-transform: uppercase;
}
ul {
  margin: 0 0 2rem 0;
}
@media (max-width: 768px) {
  .block-info .title {
    text-align: left;
  }

  .info-hr {
    margin: 0;
  }
}
</style>
