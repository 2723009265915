<template>
  <div class="section section-transparent">
    <div class="section-inner row">
      <div
        v-for="(item, i) in items"
        :key="i"
        class="card"
      >
        <h3>{{ item.title }}</h3>
        <img :src="item.icon">
        <p>{{ item.text }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          icon: '/img/smc/first-data-transfer.png',
          title: 'INSTANTANEOUS DATA TRANSFER',
          text: `The uMESH APIs drive instantaneous data transfer between systems and
          give you the power of real-time validation for all your system inputs.`,
        },
        {
          icon: '/img/smc/cloud-technology.png',
          title: 'CLOUD TECHNOLOGY',
          text: `uMESH is hosted on Amazon Web Services (AWS) and therefore is fast,
          always available, and expands to meet your data and performance needs.`,
        },
        {
          icon: '/img/smc/industry-wide-monitoring.png',
          title: 'REPORTING AND MONITORING',
          text: `uMESH’s dashboard present the data and statistics that best help
          you monitor your business.`,
        },
      ],
    };
  },
};
</script>

<style scoped>

.card {
  min-height: 400px;
  max-width: 359px;
  width: 32%;
  background-color: #fff;
  padding: 32px 20px;
  box-sizing: border-box;
}

.card h3 {
  font-size: 23px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
  text-align: center;
  min-height: 56px;
}

.card img {
  width: 139px;
  height: 139px;
  margin: 22px auto 24px auto;
  display: block;
}

.card p {
  color: #878B91;
  font-size: 19px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.1px;
}

@media (max-width: 768px) {
  .section {
    padding-top: 59px;
    padding-bottom: 50px;
  }

  .section-inner {
    flex-direction: column;
  }
  .section-inner > div {
    width: 100%;
    max-width: 100%;
    margin: 0;
    margin: 9px 0;
  }

}
</style>
