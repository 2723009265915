<template>
  <div class="content">
    <NestedList
      v-for="(item, i) in inputData"
      :key="`${i+1}`"
      :items="item.items"
      :number="`${i+1}`"
      :title="item.title"
    />
  </div>
</template>

<script>
import NestedList from '../../components/NestedList.vue';
import inputData from './data/schedule.json';

export default {
  components: {
    NestedList,
  },
  data() {
    return {
      inputData,
    };
  },
};
</script>
