<template>
  <AccordionItem
    :number="number"
    title="DEFINITIONS"
  >
    <div class="first-level">
      <p class="number">
        {{ number }}.1.
      </p>
      <p>
        In these terms, including the introduction and the schedules,
        the following definitions shall apply unless the context otherwise requires:
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.2.
      </p>
      <p>
        <strong>Additional Services</strong>: any services ordered by the Customer which Cloud
        KB agrees to provide which are in addition to those included within the Fee (for example,
        consultancy, training services, special configuration work, enhanced support, creating
        specific application programme interfaces and enhanced reporting).
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.3.
      </p>
      <p>
        <strong>Affiliate</strong>: includes, in relation to either Party, each and
        any subsidiary or holding company of that Party and each and any subsidiary
        of a holding company of that Party.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.4.
      </p>
      <p>
        <strong>Applicable Law</strong>: (a) all laws (including the common law); (b) all
        regulations, policies and codes of conduct which are legally binding; and (c)
        all directions of a Regulatory Authority, in each case in so far as they apply
        to the provisions of the Solution.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.5.
      </p>
      <p>
        <strong>Authorised Locations</strong>: the authorised locations identified
        in the Works Order in and from which the Customer and the Authorised Users
        may access and use the Solution in accordance with these terms.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.6.
      </p>
      <p>
        <strong>Authorised Users</strong>: the individual employee, agent, contractor
        or subcontractor of the Customer specified in the Works Order who are authorised
        by the Customer to access and use the Solution solely on behalf of and for
        the benefit of the Customer for the Customer's internal business purposes as
        specifically stated in the Works Order.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.7.
      </p>
      <p>
        <strong>Beta Services</strong>: services or features which Cloud KB may provide on
        an experimental basis and which are identified as such to the Customer using phrases
        that may include alpha, beta, early release or evaluation. The Customer shall be
        under no obligation to use any Beta Services and Beta Services are excluded from
        any Cloud KB stated warranty, maintenance and support obligations within these terms.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.8.
      </p>
      <p>
        <strong>Business Hours</strong>: the hours between 09:00 and 17:00 Monday
        through Friday excluding recognised public and bank holidays in the United Kingdom.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.9.
      </p>
      <p>
        <strong>Cloud KB</strong>: a company incorporated and registered in England and
        Wales with company number 07944619, and whose registered address is at Lansdowne
        Gate, 65 New Road, Solihull B91 3DL.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.10.
      </p>
      <p>
        <strong>Confidential Information</strong>: all information in any medium or format
        (including written, oral, visual or electronic, and whether or not marked or
        described as "confidential"), together with any copies, which relates to a Party
        (the "Disclosing Party"), to its Group, or to its (or its Group members’) employees,
        officers, customers or suppliers, and which is directly or indirectly disclosed by or
        on behalf of the Disclosing Party to the Party (the "Receiving Party") under or in
        connection with these terms (or which is learnt or acquired by the Receiving Party
        in connection with these terms), whether before or after the date of these terms,
        and which would reasonably be regarded as confidential, but shall not include (i)
        information which is in the public domain other than as a result of a breach of
        these terms or any separate confidentiality undertaking between the Parties; (ii)
        information which the Receiving Party received, free of any obligation of confidence,
        from a third party which was not itself under any obligation of confidence in relation
        to that information, whether before the date of its disclosure by the Disclosing Party
        or otherwise; or (iii) information which the Receiving Party can show by its written
        or other records was developed or created independently by the Receiving Party or
        any member of the Receiving Party's Group.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.11.
      </p>
      <p>
        <strong>Contract Year</strong>: means a twelve (12) month period during the
        term of these terms, with the first Contract Year commencing on the signature
        of the Works Order and each subsequent Contract Year commencing on any
        anniversary of that date.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.12.
      </p>
      <p>
        <strong>Control</strong>: a business entity shall be deemed to "control”
        another business entity if it owns, directly or indirectly, in excess of
        50% of the outstanding voting securities or capital stock of such business
        entity, or any other comparable equity or ownership interest with respect
        to a business entity other than a corporation.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.13.
      </p>
      <p>
        <strong>Customer</strong>: the entity which enters into a Works Order
        for the Solution or Additional Services.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.14.
      </p>
      <p>
        <strong>Customer Data</strong>: the data provided by the Customer
        as inputs to the Software, including Relevant Personal Data, which
        shall remain where relevant the property and responsibility of the Customer.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.15.
      </p>
      <p>
        <strong>Data Protection Legislation</strong>: all national and local laws,
        regulations and rules by any government, agency or authority applicable
        to the processing of personal data under these terms, including any other
        European Union legislation relating to personal data and all other
        legislation and regulatory requirements in force from time to time
        which apply to a Party relating to the use of personal data (including,
        without limitation, the privacy of electronic communications).
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.16.
      </p>
      <p>
        <strong>Fee</strong>: the subscription fee, fixed price fee and/or
        time and materials fee payable by the Customer to Cloud KB as set
        out in the applicable Works Order, as may be amended in accordance
        with these terms from time to time.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.17.
      </p>
      <p>
        <strong>Force Majeure Event</strong>: includes (but not limited to)
        compliance with any law, ordinance, regulation, ruling, order or
        other governmental action or arising out of acts of God, fire,
        flood, war, sabotage, accidents, labour disputes, shortages, pandemic,
        failure to supply materials or equipment, interruption of or delay
        in transportation, or any other similar circumstance beyond the
        control of the Party.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.18.
      </p>
      <p>
        <strong>Holding company</strong> and <strong>subsidiary</strong> mean
        a "holding company” and "subsidiary” as defined in section 1159 of the
        Companies Act 2006 and a company shall be treated, for the purposes
        only of the membership requirement contained in subsections 1159(1)(b)
        and (c), as a member of another company even if its shares in that other
        company are registered in the name of (a) another person (or its nominee),
        whether by way of security or in connection with the taking of security,
        or (b) its nominee. In the case of a limited liability partnership which
        is a subsidiary of a company or another limited liability partnership,
        section 1159 of the Companies Act 2006 shall be amended so that: (a)
        references in sub sections 1159(1)(a) and (c) to voting rights are to
        the members’ rights to vote on all or substantially all matters which
        are decided by a vote of the members of the limited liability partnership;
        and (b) the reference in section 1159(1)(b) to the right to appoint or
        remove a majority of its board of directors is to the right to appoint
        or remove members holding a majority of the voting rights.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.19.
      </p>
      <p>
        <strong>Intellectual Property Rights</strong>: any and all including
        (but not limited to) patents, utility models, rights to inventions,
        designs, copyright and related rights, database rights, trademarks,
        service marks and trade names (whether registered or unregistered),
        and rights to apply for registration; proprietary rights in domain
        names, rights in get-up, goodwill; knowhow and the right to sue
        for passing off or unfair competition, rights in designs, rights
        in computer software, database rights, rights to preserve the
        confidentiality of Confidential Information (including know-how
        and trade secrets); application, extensions and renewals in
        relation to any of these rights; and all other intellectual
        property rights, including all applications for (and rights
        to apply for and be granted), renewals or extensions rights of,
        and rights to claim priority from, such rights and all a similar
        nature or having an equivalent effect which subsist or will subsist,
        now or in the future, in any part of the world.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.20.
      </p>
      <p>
        <strong>Maintenance Processes</strong>: the maintenance processes set out in
        Cloud KB’s maintenance policy as published on its website (or other link
        as the Supplier may provide) updated from time to time.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.21.
      </p>
      <p>
        <strong>Maintenance Release</strong>: release of the Software that corrects
        faults, adds functionality or otherwise amends or upgrades the Software,
        but which does not constitute a New Version.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.22.
      </p>
      <p>
        <strong>Malicious Software</strong>: any software program or code
        intended to destroy, interfere with, corrupt, or cause undesired
        effects on hardware, program files, data or other information,
        executable code or application software macros, whether or not
        its operation is immediate or delayed, and whether the malicious
        software is introduced wilfully, negligently or without knowledge
        of its existence and including but not limited to computer programs
        commonly referred to as worms, trojan horses, time or logic bombs,
        and disabling codes or routines.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.23.
      </p>
      <p>
        <strong>Minimum Technical Requirements</strong>: the minimum requirements
        to ensure the Solution is implemented successfully for the Customer
        specific purpose as stated in the Works Order.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.24.
      </p>
      <p>
        <strong>Modern Slavery Practice</strong>: encompasses but is not limited
        to a) slavery, servitude, forced, compulsory and bonded labour in
        any form (prison, indentured, bonded or otherwise) or requiring its
        employees to lodge papers or deposits on starting work; (b) child
        labour (that deprives or would reasonably be expected to deprive,
        children of their childhood, their potential and/or their dignity,
        and that is or could reasonably be foreseen to be harmful to their
        physical or mental development); (c) human trafficking including
        where victims are coerced, deceived and forced against their free
        will into providing work or services; (d) breaches of the UK’s
        Modern Slavery Act 2015; (e) unethical recruitment and selection
        processes, charging or using third parties who charge recruitment
        fees to workers as a pre-requisite to being employed; (f) retention
        of identification documents or important personal effects; (g)
        other practices which may contribute to limit an employee’s
        freedom and ability to voluntarily terminate employment; (h) the payment
        of wages less that the acceptable minimum wage in that jurisdiction or
        any other unlawful money deductions or payments; and (i) any other
        practices which would breach other similar laws and conventions
        including the International Labour Organisation’s Forced Labour
        Convention 1930 (No. 29) and Protocol.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.25.
      </p>
      <p>
        <strong>New Version</strong>: any new version of the Software
        which from time to time is publicly marketed and offered for
        purchase by Cloud KB in the course of its normal business,
        being a version which contains such significant differences
        from the previous versions as to be generally accepted in the
        marketplace as constituting a new product.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.26.
      </p>
      <p>
        <strong>Professional Services</strong>: the services provided
        (and chargeable) by Cloud KB to support the implementation of
        the Solution as more specifically stated in the Works Order.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.27.
      </p>
      <p>
        <strong>Regulatory Authority</strong>: any governmental, statutory
        or regulatory body or other competent authority in any jurisdiction
        having responsibility for the regulation or governance of any of
        the activities of Cloud KB or the Customer, including data protection
        authorities and law enforcement agencies.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.28.
      </p>
      <p>
        <strong>Related Persons</strong>: the Cloud KB Affiliates; and
        Cloud KB and its Affiliates’ employees, directors, officers,
        agents and subcontractors.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.29.
      </p>
      <p>
        <strong>Relevant Personal Data</strong>: any personal data (as
        defined by the Data Protection Legislation) processed on the
        Customer’s behalf by Cloud KB when performing its obligations
        under these terms.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.30.
      </p>
      <p>
        <strong>Software</strong>: the computer programs listed in the
        Specification and any Maintenance Releases which are used by
        the Customer during the subsistence of these terms.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.31.
      </p>
      <p>
        <strong>Solution</strong>: the software as a service (SaaS) solution
        that Cloud KB provides to the Customer via web interface, including
        the Software and the Professional Services, pursuant to these terms.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.32.
      </p>
      <p>
        <strong>Special Conditions</strong>: any special conditions as set
        out in the Works Order which are intended to override some of these terms;
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.33.
      </p>
      <p>
        <strong>Specification</strong>: the Standard Specification and any
        additional specifications as described in any agreed Works
        Orders with the Customer.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.34.
      </p>
      <p>
        <strong>Standard Specification</strong>: the specification of the
        Software provided by Cloud KB as published on its website (or
        other link as Cloud KB may provide) or included in the relevant
        Works Order and updated from time to time.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.35.
      </p>
      <p>
        <strong>Third-Party Additional Terms</strong>: the additional terms
        and conditions relating to Third-Party Software.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.36.
      </p>
      <p>
        <strong>Third-Party Software</strong>: the third-party software
        (if any) identified in the Specification.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.37.
      </p>
      <p>
        <strong>Works Order</strong>: one or more counterparts to
        these terms setting out the Customer specific terms, projects,
        specifications and Fees. The Works Orders agreed with the
        Customer form part of these terms and shall have effect as
        if set out in full in the body of these terms. Any
        reference to these terms includes the Works Orders.
      </p>
    </div>
  </AccordionItem>
</template>

<script>
import AccordionItem from '../../components/AccordionItem.vue';

export default {
  components: {
    AccordionItem,
  },
  props: {
    number: {
      type: String,
      default: null,
    },
  },
};
</script>
