<template>
  <AccordionItem
    :number="number"
    title="ASSIGNMENT AND SUB-CONTRACTING"
  >
    <div class="first-level">
      <p class="number">
        {{ number }}.1.
      </p>
      <p>
        The Customer shall not without the prior written consent (such
        consent not to be unreasonably withheld or delayed) of Cloud KB:
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.1.1.
      </p>
      <p>
        sub-license, assign or novate the benefit or burden of these terms in whole or in part;
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.1.2.
      </p>
      <p>
        allow the Software to become the subject of any charge, lien or encumbrance; and
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.1.3.
      </p>
      <p>
        deal in any other manner with any or all of its rights and obligations under these terms.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.2.
      </p>
      <p>
        Cloud KB may at any time sub-license, assign, novate, charge or deal
        in any other manner with any or all of its rights and obligations
        under these terms, provided it gives written notice to the Customer.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.3.
      </p>
      <p>
        Each Party confirms it is acting on its own behalf and not for the benefit of any other person.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.4.
      </p>
      <p>
        Notwithstanding Clause 10, a Party assigning any or all of its
        rights under this Agreement may disclose to a proposed assignee
        any information in its possession that relates to these terms
        or its subject matter, the negotiations relating to it and the
        other Party which is reasonably necessary to disclose for the
        purposes of the proposed assignment, provided that no disclosure
        pursuant to this Clause {{ number }}.4 shall be made until notice of the
        identity of the proposed assignee has been given to the other Party.
      </p>
    </div>
  </AccordionItem>
</template>

<script>
import AccordionItem from '../../components/AccordionItem.vue';

export default {
  components: {
    AccordionItem,
  },
  props: {
    number: {
      type: String,
      default: null,
    },
  },
};
</script>
