<template>
  <AccordionItem
    :number="number"
    title="DATA PROTECTION"
  >
    <div class="second-level">
      <p class="number">
        {{ number }}.1.
      </p>
      <p>
        With reference to the relevant Data Protection Legislation, where
        the Customer Data includes personal data, the Customer agrees that
        it shall be the data controller and Cloud KB shall be a data processor.
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.2.
      </p>
      <p>
        The parties shall comply with all applicable Data Protection
        Legislation in the processing of Relevant Personal Data.
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.3.
      </p>
      <p>
        Subject to Clause {{ number }}.4, Cloud KB shall process Relevant Personal
        Data only to provide the services detailed within these terms
        and Works Order or otherwise in accordance with instructions
        received from the Customer from time to time.
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.4.
      </p>
      <p>
        Where Cloud KB is obliged by any applicable law to process Relevant
        Personal Data otherwise than in accordance with Clause {{ number }}.3, it shall
        inform the Customer of that obligation before processing (unless
        prohibited from doing so by any Applicable Law on important grounds of public interest).
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.5.
      </p>
      <p>
        Cloud KB shall take reasonable steps to ensure that only those of
        its personnel who need access to the Relevant Personal Data are able
        to access it, are informed of the confidential nature of the Relevant
        Personal Data and are subject to appropriate obligations of confidentiality.
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.6.
      </p>
      <p>
        Cloud KB shall use reasonable endeavours to protect the Customer Data
        and Relevant Personal Data against unauthorised or unlawful processing
        and against accidental loss, destruction, damage, alteration or disclosure.
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.7.
      </p>
      <p>
        Cloud KB shall provide the Customer with assistance in relation to
        any requests for exercising the rights of data subjects pursuant to
        the Data Protection Legislation, and/or as the Customer reasonably
        requires in order to demonstrate compliance with the Data Protection
        Legislation (including in relation to the requirements of prior
        consultation and the implementation of data protection impact assessments).
        Cloud KB reserves the right to charge the Customer a reasonable fee for such services.
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.8.
      </p>
      <p>
        Cloud KB shall host the Software and data within facilities located
        within the United Kingdom or European Economic Union.
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.9.
      </p>
      <p>
        Where Cloud KB uses third-party hosting or cloud services to process
        Customer Data, those facilities and services shall be provided by third
        parties who have achieved ISO 27001 compliance (or an internationally
        recognised equivalent standard) commensurate with the services provided
        under these terms and Works Order.
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.10.
      </p>
      <p>
        Cloud KB shall as soon as reasonably practical inform the Customer
        of any discovery by Cloud KB of a security breach involving the
        Customer Data or any personal data breach (as defined in the Data
        Protection Legislation) involving the Relevant Personal Data.
      </p>
    </div>
  </AccordionItem>
</template>

<script>
import AccordionItem from '../../components/AccordionItem.vue';

export default {
  components: {
    AccordionItem,
  },
  props: {
    number: {
      type: String,
      default: null,
    },
  },
};
</script>
