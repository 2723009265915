<template>
  <div>
    <div class="section main section-transparent">
      <div class="section-inner row">
        <div class="block-info">
          <h2 class="title">
            We’re specialists in
            implementing, adapting
            and streamlining
            industry workflows
          </h2>
        </div>
      </div>
    </div>
    <Info />
    <Collage />
  </div>
</template>

<script>
import Info from './Info.vue';
import Collage from './Collage.vue';

export default {
  components: {
    Info,
    Collage,
  },
  data() {
    return {

    };
  },
};
</script>

<style scoped="">
  .block-info {
    width: 50%;
    max-width: 424px;
    margin-left: -1px;
    margin-top: 5px;
  }
  .main{
    background: url(/img/about-us/main.png) no-repeat right;
    background-size: 58.26388889% auto;
    height: 458px;
  }

  @media (max-width: 768px) {
    .main {
      padding-top: 240px;
      padding-bottom: 30px;
      background-size: 375px auto;
      background-position: center top;
      height: auto;
    }

    .section-inner {
      flex-direction: column;
    }
    .section-inner > div {
      width: 100%;
    }

    .block-info {
      max-width: 100%;
    }
  }
</style>
