<template>
  <AccordionItem
    :number="number"
    title="REMEDIES"
  >
    <div class="first-level">
      <p class="number">
        {{ number }}.1.
      </p>
      <p>
        Except as expressly provided herein, the rights and
        remedies provided under these terms and Works Order are in
        addition to, and not exclusive of, any rights or remedies
        provided by law.
      </p>
    </div>
  </AccordionItem>
</template>

<script>
import AccordionItem from '../../components/AccordionItem.vue';

export default {
  components: {
    AccordionItem,
  },
  props: {
    number: {
      type: String,
      default: null,
    },
  },
};
</script>
