<template>
  <AccordionItem
    :number="number"
    title="CONFIDENTIALITY AND PUBLICITY"
  >
    <div class="first-level">
      <p class="number">
        {{ number }}.1.
      </p>
      <p>
        Each Party shall, during the Term of these terms and Works Order (and thereafter),
        keep confidential all Confidential Information, and shall not use for its own
        purposes (other than implementation of these terms and Works Order) nor without
        the prior written consent of the other Party disclose to any third party
        (except its professional advisors or as may be required by any law or any
        legal or regulatory authority) any information of a confidential nature
        (including trade secrets and information of commercial value) which may
        become known to such Party from the other Party and which relates to
        the other Party or any of its Affiliates, unless that information is
        public knowledge or already known to such party at the time of disclosure,
        or subsequently becomes public knowledge other than by breach of these
        terms or Works Order, or subsequently comes lawfully into the possession
        of such Party from a third party. Each Party shall use its reasonable
        endeavours to prevent the unauthorised disclosure of any such information.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.2.
      </p>
      <p>
        Neither Party shall make, or permit any person to make, any public
        announcement concerning these terms and Works Order without the prior
        written consent of the other Party (such consent not to be unreasonably
        withheld or delayed), except as required by law, any governmental or
        regulatory authority (including, without limitation, any relevant
        securities exchange), any court or other authority of competent jurisdiction.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.3.
      </p>
      <p>
        Each Party shall:
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.3.1.
      </p>
      <p>
        only use (including making copies of) Confidential Information in
        connection with and to the extent necessary for the purposes of
        these terms and those specific purposes stated in a Works Order;
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.3.2.
      </p>
      <p>
        not disclose the Confidential Information to any person except
        with the prior written consent of the Disclosing Party or in
        accordance with this Clause; and
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.3.3.
      </p>
      <p>
        keep all Confidential Information secret and securely protected
        against theft or unauthorised access.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.4.
      </p>
      <p>
        In the case of any permitted disclosure, the Receiving Party shall
        ensure that the persons to whom it discloses the Confidential
        Information are aware of, and shall procure that such persons
        comply with, these obligations as to confidentiality.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.5.
      </p>
      <p>
        The Receiving Party may disclose any Confidential Information to
        any regulator, law enforcement agency or other third party if it is
        required to do so by law, regulation, or similar authority.
        In those circumstances the Receiving Party shall (to the extent practical
        and lawful to do so) notify the Disclosing Party in writing as soon as
        practicable before the disclosure and use all reasonable endeavours to
        consult with the Disclosing Party with a view to agreeing the timing,
        manner and extent of the disclosure.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.6.
      </p>
      <p>
        All Confidential Information shall remain the property of the Disclosing
        Party and the Disclosing Party reserves all rights in its Confidential
        Information. Nothing in these terms and Works Order or the disclosures
        envisaged by these terms and Works Order shall (except as expressly
        agreed otherwise) operate to transfer, or operate as a grant of any
        licences or right to use, to any Intellectual Property Rights in the
        Confidential Information.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.7.
      </p>
      <p>
        The Parties' obligations under this Clause {{ number }} shall continue in
        force notwithstanding the termination or expiry of these terms and Works Order.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.8.
      </p>
      <p>
        Each Party acknowledges that damages alone would not be an adequate
        remedy in the event of breach by the other Party of the provisions of
        this Clause {{ number }}. Accordingly, it is agreed that either Party shall be
        entitled, without proof of special damages, to seek an injunction or
        other interim remedy for any threatened or actual breach of this
        Clause 10 by the other Party, without prejudice to any other rights and
        remedies which that first Party may have.
      </p>
    </div>
  </AccordionItem>
</template>

<script>
import AccordionItem from '../../components/AccordionItem.vue';

export default {
  components: {
    AccordionItem,
  },
  props: {
    number: {
      type: String,
      default: null,
    },
  },
};
</script>
