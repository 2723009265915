<template>
  <AccordionItem
    :number="number"
    title="NO WAIVER"
  >
    <div class="first-level">
      <p class="number">
        {{ number }}.1.
      </p>
      <p>
        No failure or delay by a Party to exercise any right or remedy
        provided under these terms and Works Order or by law shall
        constitute a waiver of that or any other right or remedy, nor
        shall it prevent or restrict the further exercise of that or any
        other right or remedy. No single or partial exercise of such
        right or remedy shall prevent or restrict the further
        exercise of that or any other right or remedy.
      </p>
    </div>
  </AccordionItem>
</template>

<script>
import AccordionItem from '../../components/AccordionItem.vue';

export default {
  components: {
    AccordionItem,
  },
  props: {
    number: {
      type: String,
      default: null,
    },
  },
};
</script>
