<template>
  <AccordionItem
    :number="number"
    title="ENTIRE AGREEMENT"
  >
    <div class="first-level">
      <p class="number">
        {{ number }}.1.
      </p>
      <p>
        These terms and Works Orders agreed with the Customer together
        with the Standard Specification and Cloud KBs policies in place
        from time to time and referred to herein, contain the whole
        agreement between the Parties relating to the subject matter
        hereof and supersede all prior agreements, arrangements and
        understandings between the Parties relating to that subject matter.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.2.
      </p>
      <p>
        Each Party acknowledges that, in entering into these terms and Works
        Order, it does not rely on any statement, representation, assurance
        or warranty (whether it was made negligently or innocently) of any
        person (whether a party to these terms or not) (<strong>Representation</strong>)
        other than as expressly set out in these terms and Works Order.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.3.
      </p>
      <p>
        Each Party agrees that the only rights and remedies available to
        it arising out of or in connection with a Representation shall be
        for breach of contract as expressly provided in these terms and Works Order.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.4.
      </p>
      <p>
        Nothing in this Clause {{ number }} shall limit or exclude any liability for fraud.
      </p>
    </div>
  </AccordionItem>
</template>

<script>
import AccordionItem from '../../components/AccordionItem.vue';

export default {
  components: {
    AccordionItem,
  },
  props: {
    number: {
      type: String,
      default: null,
    },
  },
};
</script>
