<template>
  <AccordionItem
    :number="number"
    title="INDEMNITIES"
  >
    <div class="first-level">
      <p class="number">
        {{ number }}.1.
      </p>
      <p>
        The Customer shall defend, indemnify and hold harmless Cloud KB
        and the Related Persons against any and all claims, actions,
        proceedings, losses, damages, expenses and costs (including
        without limitation court costs and reasonable legal fees)
        arising out of or in connection with:
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.1.1.
      </p>
      <p>
        the Customer's or the Authorised Users' use of the Software in breach
        of these terms, any Works Order or any reasonable instructions from Cloud KB; and
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.1.2.
      </p>
      <p>
        claims made or brought against Cloud KB and the Related Persons by a
        third party that the Customer Data or the Customer’s use of Customer
        Data, infringes the rights of, or has otherwise harmed, a third party.
      </p>
    </div>
    <div class="first-level">
      <p class="number" />
      <p>
        The Customer shall defend, indemnify and hold harmless Cloud KB and
        the Related Persons against any and all claims, actions, proceedings,
        losses, damages, expenses and costs (including without limitation court
        costs and reasonable legal fees) arising out of or in connection with
        the Customer's or the Authorised Users' use of the Software.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.2.
      </p>
      <p>
        Cloud KB undertakes at its own expense to defend the Customer or, at its
        option, settle any claim or action brought against the Customer alleging
        that the possession or use of the Software (or any part thereof) in
        accordance with the terms of these terms and Works Order infringes the
        UK Intellectual Property Rights of a third party (a <strong>"Claim"</strong>)
        and shall be responsible for any reasonable losses, damages, costs (including
        legal fees) and expenses incurred by or awarded against the Customer as a
        result of or in connection with any such Claim, subject to the Customer
        complying fully with Clause {{ number }}.3. For the avoidance of doubt, this Clause
        {{ number }}.2 shall not apply where the Claim in question is attributable to
        possession or use of the Software (or any part thereof) by the Customer
        other than in accordance with the terms of these terms and Works Order,
        use of the Software in combination with any hardware or software not
        supplied or specified by Cloud KB if the infringement would have been
        avoided by the use of the Software not so combined, or use of a non-current
        release of the Software.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.3.
      </p>
      <p>
        If any third party makes a Claim, or notifies an intention to make a
        Claim against the Customer, Cloud KBs obligations under Clause {{ number }}.1
        are conditional on the Customer:
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.3.1.
      </p>
      <p>
        as soon as reasonably practicable, giving written notice of the
        Claim to Cloud KB, specifying the nature of the Claim in reasonable detail;
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.3.2.
      </p>
      <p>
        not making any admission of liability, agreement or compromise in
        relation to the Claim without the prior written consent of Cloud KB
        (such consent not to be unreasonably conditioned, withheld or delayed);
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.3.3.
      </p>
      <p>
        giving Cloud KB and its professional advisers access at reasonable times
        (on reasonable prior notice) to its premises and its officers, directors,
        employees, agents, representatives or advisers, and to any relevant assets,
        accounts, documents and records within the power or control of the Customer,
        so as to enable Cloud KB and its professional advisers to examine them and
        to take copies (at Cloud KBs expense) for the purpose of assessing the Claim;
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.3.4.
      </p>
      <p>
        subject to Cloud KB providing security to the Customer to the Customer’s
        reasonable satisfaction against any claim, liability, costs, expenses, damages
        or losses which may be incurred, taking such action as Cloud KB may reasonably
        request to avoid, dispute, compromise or defend the Claim; and
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.3.5.
      </p>
      <p>
        taking all reasonable steps to mitigate any liabilities which are
        the subject of the indemnity in Clause {{ number }}.2.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.4.
      </p>
      <p>
        If any Claim is made, or in Cloud KBs reasonable opinion is likely
        to be made, against the Customer, Cloud KB may at its sole option and expense:
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.4.1.
      </p>
      <p>
        procure for the Customer the right to continue to use the Software
        (or any part thereof) in accordance with the terms of these terms and Works Order;
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.4.2.
      </p>
      <p>
        modify the Software so that it ceases to be infringing;
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.4.3.
      </p>
      <p>
        replace the Software with non-infringing software; or
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.4.4.
      </p>
      <p>
        terminate these terms and Works Order immediately by notice in
        writing to the Customer and refund any of the Fee paid by the
        Customer as at the date of termination (on a pro rata basis)
        on return of the Software and all copies thereof,
      </p>
    </div>
    <div class="first-level">
      <p class="number" />
      <p>
        provided that if Cloud KB modifies or replaces the Software, the
        modified or replacement Software must comply with the
        warranties contained in Clause 11.1 and the Customer
        shall have the same rights in respect thereof as it would have
        had under those clauses had the references to the date of these
        terms and Works Order been references to the date on which such
        modification or replacement was made.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.5.
      </p>
      <p>
        this Clause {{ number }}  constitutes the Customer’s exclusive remedy and Cloud
        KBs only liability in respect of Claims and, for the avoidance of
        doubt, is subject to Clause 12.1.
      </p>
    </div>
  </AccordionItem>
</template>

<script>
import AccordionItem from '../../components/AccordionItem.vue';

export default {
  components: {
    AccordionItem,
  },
  props: {
    number: {
      type: String,
      default: null,
    },
  },
};
</script>
