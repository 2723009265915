<template>
  <div class="hero-section">
    <div class="top-text">
      <h1><span>Decarbonisation:</span> don't be a sitting duck!</h1>
    </div>
    <div class="hero-row">
      <div class="col">
        <h2>ASSET TRACKING FOR THE WHOLE ENERGY INDUSTRY</h2>
        <p>
          uMESH is an unrivalled, industry-specific, asset tracking and data
          management solution for the whole Energy Industry. Fully automated and
          system-ready for decarbonisation uMESH can optimise your installation rates and
        </p>
      </div>
      <div class="flying-duck-spacer">
        <img src="/img/home/flying-duck.png" class="flying-duck">
      </div>
      <div class="col">
        <div class="equal-height">
          <a href="/contact-us">
            <img src="img/home/migrate-now-logo.jpg">
          </a>
          <h2>ASSET TRACKING FOR THE WHOLE ENERGY INDUSTRY</h2>
        </div>
        <p>
          maximise your data performance like no other system via its smooth end-to-end integration. uMESH synchronises
          with every major CRM platform and offers both Capacity &amp; Scheduling capability to support your operation.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="red-line" />
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
};
</script>

<style scoped>
.hero-section {
  position: relative;
  background-image: url('/img/home/bare-header.png');
  background-position: top center;
  background-size: auto 468px;
  background-repeat: no-repeat;
  padding-top: 340px;
}

.top-text {
  position: absolute;
  top: 27px;
  left: 0;
  width: 100%;
  height: 102px;
  display: flex;
  justify-content: center;
  align-items: center;
}

h1 {
  text-transform: uppercase;
  font-size: 50.4px;
  font-family: "Roboto-Black";
  color: rgb(190, 30, 45);
  text-align: center;
  letter-spacing: 0px;
}

h1 span {
  font-family: inherit;
  display: inline-block;
}

@media (max-width: 1270px) {
  h1 {
    font-size: 45px
  }
}

@media (max-width: 1170px) {
  h1 {
    font-size: 40px
  }
}

@media (max-width: 1070px) {
  h1 {
    font-size: 35px;
  }
}

@media (max-width: 970px) {
  h1 {
    font-size: 30px;
  }
}

.hero-row {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-content: space-between;
}

.flying-duck-spacer {
  padding: 8px 34px 0 34px;
}

.flying-duck {
  display: block;
  width: 184px;
  margin: 0 auto;
}

.col {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

h2 {
  font-size: 36px;
  font-family: "Roboto-Black";
  color: rgb(190, 30, 45);
  line-height: 1.233;
  text-align: left;
  max-width: 400px;
  padding: 1rem 0;
}

p {
  font-size: 20px;
  color: rgb(0, 0, 0);
  line-height: 1.66;
}

.col p {
  margin-top: 58px;
}

.equal-height {
  position: relative;
}

.equal-height h2 {
  visibility: hidden;
  color: transparent;
  user-select: none;
}

.equal-height a {
  position: absolute;
  display: inline-block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.equal-height img {
  max-width: 460px;
  width: 100%;
  display: block;
  margin: 0 auto;
}

.red-line {
  max-width: 425px;
  width: 100%;
  height: 6px;
  display: block;
  margin: 2rem auto 3rem auto;
  background: #ed1d25;
}

@media (max-width: 900px) {
  .flying-duck-spacer {
    padding: 8px 8px 0 8px;
  }
}

@media (max-width: 768px) {
  .hero-section {
    background-image: none;
    padding-top: 0;
  }

  .top-text {
    position: relative;
    height: auto;
    top: 0;
    background: #fedd03;
    margin-bottom: 1rem;
    padding: 2rem 0 1rem 0;
  }

  h1 span {
    font-size: 32px;
    font-family: inherit;
    width: 100%;
  }

  .hero-row {
    flex-direction: column;
  }

  .flying-duck-spacer {
    margin: 2rem 0 3rem 0;
  }

  h2 {
    margin: 1rem 0 1rem 0;
  }

  .equal-height h2 {
    display: none;
  }

  .equal-height a {
    position: relative;
    margin: 0 auto;
    display: block;
    transform: none;
    left: auto;
    top: auto;
    text-align: center;
  }

  .red-line {
    max-width: 300px;
  }
}
</style>
