<template>
  <AccordionItem
    :number="number"
    title="MAINTENANCE RELEASES AND SOFTWARE UPDATES"
  >
    <div class="first-level">
      <p class="number">
        {{ number }}.1.
      </p>
      <p>
        Cloud KB shall ensure that any Third-Party Software used to provide
        the Solution (if any) is updated or patched according to the relevant
        security recommendations within a reasonable timeframe given the severity
        of any identified vulnerability and its obligations to provide
        uninterrupted services to its customers.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.2.
      </p>
      <p>
        The Customer hereby acknowledges that the Solution is provided to
        multiple customers and that the standard specification and Software may
        therefore need to be patched or updated to new versions for reasons that
        may include (but not be limited) to meet demands from other customers; to
        add or remove Beta Services or Third-Party Software; to make the Software
        easier to maintain; to fix vulnerabilities; to meet additional or changing
        industry standards; or to adopt best practice. Although Cloud KB will
        make reasonable efforts to minimise the impact on the Customer of such
        patches or updates, the Customer acknowledges that they may be required
        to make reasonable changes in order to continue to use the Software and
        that (provided that Cloud KB does not remove services from the Software
        that were material to the Works Order entered into by the Customer) such
        patches and updates shall not constituted a breach of these terms or Works Order.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.3.
      </p>
      <p>
        Provided that Cloud KB is reasonably satisfied that no impact will be made to the Customer,
        it may apply upgrades and patches to the Software at its sole discretion and convenience.
        Where reasonably practical, Cloud KB will nevertheless notify the Customer in advance of
        any planned updates or patches.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.4.
      </p>
      <p>
        Where an upgrade or patch requires the Software to be made unavailable,
        Cloud KB will provide the Customer with reasonable notification of a
        planned maintenance window, which Cloud KB shall make reasonable endeavours
        to schedule outside of normal Business Hours. The Customer shall have the
        right to request that the maintenance window is changed if it reasonably
        believes it would materially impact its normal business operations.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.5.
      </p>
      <p>
        Where Cloud KB releases a new version of its Software that will
        require the Customer to make changes to continue to use the Software,
        Cloud KB will maintain and support the existing version for up to
        twelve (12) months following notification to the Customer of the
        new version. Cloud KB may at its sole discretion reduce this
        support period if it reasonably believes maintaining the existing
        version presents security risks or because it is otherwise obliged to
        cease to provide the existing version due to reasons beyond its control
        including changes in legislation, Third Party licensing terms or to
        maintain compliance with industry standards.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.6.
      </p>
      <p>
        Cloud KB warrants that no Maintenance Release will adversely affect
        the then existing facilities or functions of the Software as being
        used by the Customer. This warranty shall not apply to any Beta Services.
      </p>
    </div>
  </AccordionItem>
</template>

<script>
import AccordionItem from '../../components/AccordionItem.vue';

export default {
  components: {
    AccordionItem,
  },
  props: {
    number: {
      type: String,
      default: null,
    },
  },
};
</script>
