<template lang="html">
  <div>
    <div class="section section-transparent section-contact-form">
      <div class="section-inner row">
        <div class="block-info">
          <h2 class="title">
            Contact us
          </h2>
          <hr class="info-hr">
          <div class="sub-title">
            We’d love to hear from
            you. If you have any
            questions about any of
            our products and services
            please get in contact.
          </div>
        </div>
        <div id="hsformContainer" class="contact-form" />
      </div>
    </div>

    <div class="section section-transparent section-contact-details">
      <div class="section-inner row">
        <div class="item">
          <img src="/img/contact-us/address.png">
          <h3>Lansdowne Gate, 65 New Road, Solihull, B91 3DL</h3>
        </div>
        <div class="item">
          <img src="/img/contact-us/mail.png">
          <h3>umesh@correla.com</h3>
        </div>
      </div>
    </div>

    <div class="map">
      <iframe width="100%" height="551" frameborder="0" style="border:0" :src="mapSrc" allowfullscreen />
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      formScriptSrc: '//js.hsforms.net/forms/shell.js',
    };
  },
  computed: {
    mapSrc() {
      return `https://www.google.com/maps/embed/v1/view?zoom=17&center=52.4119819,-1.7744083&key=${process.env.VUE_APP_GOOGLE_MAPS_API_KEY}`; // es-lint-disable
    },
  },
  mounted() {
    this.loadFormScript();
  },
  methods: {
    loadFormScript() {
      const tag = document.createElement('script');
      tag.onload = this.createForm;
      tag.src = this.formScriptSrc;
      document.body.appendChild(tag);
    },
    createForm() {
      window.hbspt.forms.create({
        portalId: '5296025',
        formId: '98466e59-0576-4a7c-83c4-9449249a64e9',
        target: '#hsformContainer',
      });
    },
  },
};
</script>

<style lang="css" scoped>
.section-contact-form .block-info {
  width: 34%;
  max-width: 326px;
}

.section-contact-form .contact-form {
  width: 66%;
  max-width: 738px;
}

.section-contact-details {
  padding: 15px;
  margin-bottom: -89px;
}

.section-contact-details .item {
  width: 30%;
  max-width: 354px;
  background: #fff;
  padding: 64px 19px 15px 19px;
  min-height: 120px;
  text-align: center;
  position: relative;
}

.section-contact-details .item img {
  display: block;
  position: absolute;
  height: 67px;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.map {
  width: 100%;
}

@media (max-width: 992px) {
  .section-contact-details .item h3 {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .section-inner {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .section-contact-form .block-info {
    width: 100%;
    max-width: 100%;
  }

  .section-contact-form .contact-form {
    margin-top: 2rem;
    text-align: center;
    width: 100%;
    max-width: 480px;
    overflow: hidden;
  }

  .section-contact-form .contact-form iframe {
    margin: auto;
  }

  .section-contact-details {
    margin: 0;
  }

  .section-contact-details .item {
    width: 100%;
    margin: 10px 0;
    padding: 5px 15px 5px 96px;
    height: 95px;
    min-height: 95px;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .section-contact-details .item img {
    left: 48px;
    top: 50%;
    margin-right: 1rem;
  }
}
</style>
