<template>
  <div>
    <HeroSection
      :title="'Smart Meter Commissioning'"
      :img-src="'/img/smc/banner.jpg'"
    />
    <ProblemAndSolution :value="problemAndSolutionData" />
    <DetailsComponent :columns="details" />
    <FieldServiceApp />
    <Apis />
    <Cards />
  </div>
</template>

<script>
import HeroSection from '../templates/configurations/Hero.vue';
import ProblemAndSolution from '../templates/configurations/ProblemAndSolution.vue';
import DetailsComponent from '../templates/configurations/Details.vue';
import FieldServiceApp from './FieldServiceApp.vue';
import Apis from './Apis.vue';
import Cards from '../templates/configurations/Cards.vue';

export default {
  components: {
    HeroSection,
    ProblemAndSolution,
    DetailsComponent,
    FieldServiceApp,
    Apis,
    Cards,
  },
  data() {
    return {
      problemAndSolutionData: {
        problem: [
          `Integrating different DCC adapters into existing MAM/MOP systems
          and field applications can, for most meter operators, be an
          insurmountable technical problem. The variety of DCC adapters,
          their unique testing requirements, and the different data each
          requires before connecting to the DCC means retrofitting each set
          of commands into an existing system is both complicated and expensive.`,
        ],
        solution: [
          `Using our hard-won experience with DCC adaptors means we can
         guide and assist you – and possibly speed up the process,
         too – through the intricacies of your SMETS2 onboarding process,
         system integration testing (SIT), and end-to-end testing (E2E).`,
        ],
        brochureSrc: 'https://www.cloudkb.co.uk/CloudKB%2BSmart%2BMeter%2BCommissioning.pdf',
      },
      details: [
        {
          text: [
            `Our uMESH Commissioning module can be deployed in one of two ways
            according to your business needs:`,
          ],
          list: [
            {
              text: 'Via our native android Field Service App',
            },
            {
              text: 'Via our standard set of APIs',
            },
          ],
          textAfter: [
            `Whichever route you take, the uMESH Commissioning module acts
            as a central data collection point for all commissioning trafﬁc.`,
          ],
        },
        {
          text: [
            'The uMESH Commissioning module gives you the following benefits:',
          ],
          list: [
            {
              text: `Ability to onboard any supplier as our module already works
              with all DCC adaptors`,
            },
            {
              text: `Reduced commissioning and flow errors and their associated
              costs as data collected is validated in real-time`,
            },
            {
              text: `Lower training required for engineers as all the DCC Adapter
              error codes and responses have been standardised and translated into
              engineer-friendly alerts and updates`,
            },
          ],
        },
      ],
    };
  },
};
</script>
