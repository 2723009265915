<template>
  <AccordionItem
    :number="number"
    :title="title"
  >
    <ListItem
      v-for="item in updatedItems"
      :key="item.number"
      :level="item.level"
      :number="item.number"
      :content="item.content"
    />
  </AccordionItem>
</template>

<script>
import AccordionItem from './AccordionItem.vue';
import ListItem from './ListItem.vue';

function convertList(list, parentNumber, parentLevel = 0) {
  let items = [];
  const level = parentLevel + 1;

  list.forEach((item, i) => {
    const number = `${parentNumber}.${i + 1}`;

    if (typeof item === 'string') {
      items.push({
        content: item,
        number,
        level,
      });
      return;
    }

    items.push({
      content: item.content,
      number,
      level,
    });

    if (item.children) {
      const childItems = convertList(item.children, number, level);
      items = [
        ...items,
        ...childItems,
      ];
    }
  });

  return items;
}

export default {
  components: {
    AccordionItem,
    ListItem,
  },
  props: {
    number: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: null,
    },
  },
  computed: {
    updatedItems() {
      if (!this.items) {
        return [];
      }

      const items = convertList(this.items, this.number);
      return items;
    },
  },
};
</script>

<style scoped>
.accordion-item {
  border: 1px solid #efefef;
  border-radius: 0.5rem;
  margin: 1rem 0;
  overflow: hidden;
}
.accordion-header {
  line-height: 4rem;
  cursor: pointer;
  padding: 0 1rem;
  font-weight: bold;
  color: #000;
}
.accordion-header:hover {
  background-color: #efefef;
}

.accordion-header span {
  min-width: 3rem;
}

.accordion-header > * {
  display: inline-block;
}

.accordion-content {
  padding: 0 1rem;
  max-height: 0;
  overflow: hidden;
  transition: all 0.35s ease-in-out;
}

.animating {
  pointer-events: none;
}

.accordion-content.show {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-top: 1px solid #efefef;
}
</style>
