<template>
  <AccordionItem
    :number="number"
    title="MODIFICATION OF TERMS"
  >
    <div class="first-level">
      <p class="number">
        {{ number }}.1.
      </p>
      <p>
        Cloud KB may reasonably (including but not limited to change in
        Applicable Law or due to ambiguity of terms) modify the terms
        within these terms, the Works Order and its policies as published
        on its website (or other link as Cloud KB may provide) from time
        to time. The most recent version will be published on Cloud KB’s
        website (or other link as it may provide).
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.1.
      </p>
      <p>
        Cloud KB will provide reasonable notification via email of
        any such changes of terms before they become effective and,
        if the Customer provides reasonable grounds why it cannot
        agree to the revised terms and Cloud KB is unable to accommodate
        the Customer’s position, the Customer may within thirty (30)
        calendar days of receiving such notification provide notice that
        they wish to terminate in accordance with Clause 15.3. Otherwise,
        the Customer agrees to be bound by the revised terms and accept
        the revised specification and policies as they become effective.
      </p>
    </div>
  </AccordionItem>
</template>

<script>
import AccordionItem from '../../components/AccordionItem.vue';

export default {
  components: {
    AccordionItem,
  },
  props: {
    number: {
      type: String,
      default: null,
    },
  },
};
</script>
