<template>
  <!-- eslint-disable max-len -->
  <div class="cookies-container">
    <div class="section cookie-policy">
      <div class="section-inner">
        <div class="block-info">
          <h2 class="title">
            Cookie Policy
          </h2>
          <hr class="info-hr">
        </div>
        <div class="content">
          <p>
            We use cookies to distinguish you from other users of our website. This helps us to provide you with a good
            experience when you browse our website and also allows us to improve and monitor the use of our site.
          </p>

          <p><strong>What Are Cookies?</strong></p>

          <p>
            A Cookie is a small file of letters and numbers that are sent to and stored on your computer, smartphone or
            other device if you agree whenever you visit a website. Cookies contain information that is transferred to
            your computer&#39;s hard drive. Cookies are useful because they allow a website to recognise a user&#39;s
            device. More information about cookies can be found on <a href="http://www.allaboutcookies.org"
              title="All About Cookies Website">http://www.allaboutcookies.org</a>.
          </p>

          <p><strong>Our Cookies</strong></p>

          <p>
            We use or may use the following cookies:
          </p>

          <p>
            <strong>Strictly Necessary Cookies:</strong> These are cookies that are required for the operation of our
            website.
          </p>

          <p>
            <strong>Analytical Cookies:</strong> These allow us to recognise and count the number of visitors, see how
            visitors move around our website when they are using it and estimate and report our total audience size and
            traffic. This helps us to improve the way our website works by ensuring that users are finding what they are
            looking for easily. All information about how you used our website is collected anonymously. The service we
            use is Google Analytics, a web analytics tool provided by Google. For more details please visit the Google
            Privacy Centre.
          </p>

          <p>
            <strong>Functionality Cookies:</strong> These are used to recognise you when you return to our website, when
            completing online forms and logging in to the site. This would enable us to personalise our content for you
            and remember your search preferences.
          </p>

          <p>
            <strong>Session Cookies:</strong> These are used when you log in to an application and it remembers that you
            are logged in as you navigate the website, e.g. where you apply online for jobs. When you close
            the browser the cookie is deleted, as a result when visiting the website again you would need to log in.
          </p>

          <p>
            <strong>Persistent Cookies:</strong> These are used if you were prompted to take part in a website survey
            and you completed the survey. A persistent cookie would be stored on your computer to record that you have
            completed that survey. This would prevent you being prompted with the survey each time you visited the
            website.
          </p>

          <p>
            Cloud KB does not use cookies to target advertising, share information with social networking sites or other
            services.
          </p>

          <p><strong>Third Parties</strong></p>

          <p>
            Please note that third parties (including, for example, providers of external services like web traffic
            analysis services) may also use cookies, over which we have no control. These cookies are likely to be
            analytical/performance cookies or targeting cookies. When we include links to other websites, please bear in
            mind they will have their own privacy and cookie policies that will govern the use of any information you
            submit. We recommend you read their policies as Cloud KB is not responsible or liable for their privacy
            practices.
          </p>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
@media (max-width: 768px) {
  .block-info .title {
    text-align: left;
  }

  .info-hr {
    margin: 0;
  }
}
</style>
