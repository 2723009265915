<template>
  <div>
    <div class="section cookie-policy">
      <div class="section-inner">
        <a
          class="new-tc-button btn"
          href="/terms-and-conditions"
        >
          New Terms of Service Proposal
        </a>
        <div class="block-info">
          <h2 class="title">
            Terms of Service
          </h2>
          <hr class="info-hr">
        </div>
        <div class="content">
          <p>
            <strong>POSTED: 1 December 2019</strong>
          </p>
          <p>
            <strong>EFFECTIVE: 1 December 2019</strong>
          </p>
          <p>
            This Agreement (the “Agreement”) is between Cloud KB Limited, a company incorporated and
            registered in England and Wales with company number 7944619 (“Cloud KB”) and the
            organisation agreeing to these terms (“Customer”). By clicking “I agree”, signing a
            Works Order for the Services, or using the Services, you agree to this Agreement as a
            Customer.
          </p>
        </div>
        <hr class="info-hr">
        <div class="content">
          <AccordionItem
            :number="1"
            title="DEFINITIONS"
          >
            <div class="first-level">
              <p class="number">
                1.1
              </p>
              <p>
                The definitions and rules of interpretation in
                this clause apply in this Agreement.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                1.2
              </p>
              <p>
                <strong>Affliate</strong>: includes, in relation to either party, each and any
                subsidiary or holding company of that party and each and any subsidiary of a holding
                company of that party.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                1.3
              </p>
              <p>
                <strong>Beta Services</strong>: services or features which Cloud KB may provide on
                an experimental basis and which are identified as such to the Customer using phrases
                that may include alpha, beta, early release or evaluation. The Customer shall be
                under no obligation to use any Beta Services and Beta Services are excluded from any
                warranty, maintenance and support obligations.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                1.4
              </p>
              <p>
                <strong>Control</strong>: a business entity shall be deemed to “control” another
                business entity if it owns, directly or indirectly, in excess of 50% of the
                outstanding voting securities or capital stock of such business entity, or any other
                comparable equity or ownership interest with respect to a business entity other than
                a corporation.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                1.5
              </p>
              <p>
                <strong>Custom Workflows</strong>: the workflows created by Cloud KB specifically to
                be used for the benefit of the Customer.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                1.6
              </p>
              <p>
                <strong>Customer Data</strong>: the data provided by the customer as inputs to the
                Software, including Relevant Personal Data, which shall remain where relevant the
                property and responsibility of the Customer.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                1.7
              </p>
              <p>
                <strong>Data Protection Legislation</strong>: the UK Data Protection Legislation and
                any other European Union legislation relating to personal data and all other
                legislation and regulatory requirements in force from time to time which apply to a
                party relating to the use of personal data (including, without limitation, the
                privacy of electronic communications).
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                1.8
              </p>
              <p>
                <strong>Fee</strong>: the licence fee payable by the Customer to Cloud KB under
                Clause 6.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                1.9
              </p>
              <p>
                <strong>Intellectual Property Rights</strong>: patents, utility models, rights to
                inventions, copyright and related rights, trademarks and service marks, trade names
                and domain names, rights in get-up, goodwill and the right to sue for passing off or
                unfair competition, rights in designs, rights in computer software, database rights,
                rights to preserve the confidentiality of information (including know-how and trade
                secrets) and any other intellectual property rights, including all applications for
                (and rights to apply for and be granted), renewals or extensions of, and rights to
                claim priority from, such rights and all similar or equivalent rights or forms of
                protection which subsist or will subsist, now or in the future, in any part of the
                world.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                1.10
              </p>
              <p>
                <strong>Maintenance Processes</strong>: the maintenance processes set out in Cloud
                KB’s maintenance policy as published on Cloud KB’s website (or other link as Cloud
                KB may provide) and updated from time to time.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                1.11
              </p>
              <p>
                <strong>Maintenance Release</strong>: release of the Software that corrects faults,
                adds functionality or otherwise amends or upgrades the Software, but which does not
                constitute a New Version.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                1.12
              </p>
              <p>
                <strong>New Version</strong>: any new version of the Software which from time to
                time is publicly marketed and offered for purchase by Cloud KB in the course of its
                normal business, being a version which contains such significant differences from
                the previous versions as to be generally accepted in the marketplace as constituting
                a new product.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                1.13
              </p>
              <p>
                <strong>Relevant Personal Data</strong>: any personal data (as defined by the Data
                Protection Legislation) processed on the Customer’s behalf by Cloud KB when
                performing its obligations under this Agreement.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                1.14
              </p>
              <p>
                <strong>Software</strong>: the computer programs listed in the Specification and any
                Maintenance Releases which are used by the Customer during the subsistence of this
                Agreement.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                1.15
              </p>
              <p>
                <strong>Specification</strong>: the Standard Specification and any additional
                specifications as described in any agreed Works Orders with the Customer.
              </p>
            </div>

            <div class="first-level">
              <p class="number">
                1.16
              </p>
              <p>
                <strong>Standard Specification</strong>: the specification of the Software provided
                by Cloud KB as published on Cloud KB’s website (or other link as Cloud KB may
                provide) and updated from time to time.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                1.17
              </p>
              <p>
                <strong>Third-Party Additional Terms</strong>: the additional terms and conditions
                relating to Third-Party Software.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                1.18
              </p>
              <p>
                <strong>Third-Party Software</strong>: the third-party software (if any) identified
                in the Specification.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                1.19
              </p>
              <p>
                <strong>Holding company</strong> and
                <strong>subsidiary</strong> mean a “holding
                company” and “subsidiary” as defined in section 1159 of the Companies Act 2006 [and
                a company shall be treated, for the purposes only of the membership requirement
                contained in subsections 1159(1)(b) and (c), as a member of another company even if
                its shares in that other company are registered in the name of (a) another person
                (or its nominee), whether by way of security or in connection with the taking of
                security, or (b) its nominee]. In the case of a limited liability partnership which
                is a subsidiary of a company or another limited liability partnership, section 1159
                of the Companies Act 2006 shall be amended so that: (a) references in sub sections
                1159(1)(a) and (c) to voting rights are to the members’ rights to vote on all or
                substantially all matters which are decided by a vote of the members of the limited
                liability partnership; and (b) the reference in section 1159(1)(b) to the right to
                appoint or remove a majority of its board of directors is to the right to appoint or
                remove members holding a majority of the voting rights.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                1.20
              </p>
              <p>
                <strong>Works Order</strong>: one or more counterparts to this Agreement setting out
                the Customer-specific terms, projects, specifications, Custom Workflows and Fees.
                The Works Orders agreed with the Customer form part of this Agreement and shall have
                effect as if set out in full in the body of this Agreement. Any reference to this
                Agreement includes the Works Orders.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                1.21
              </p>
              <p>Paragraph headings shall not affect the interpretation of this agreement.</p>
            </div>
            <div class="first-level">
              <p class="number">
                1.22
              </p>
              <p>Unless the context otherwise requires:</p>
            </div>
            <div class="second-level">
              <p class="number">
                1.22.1
              </p>
              <p>
                words in the singular shall include the plural and in the plural shall include the
                singular;
              </p>
            </div>
            <div class="second-level">
              <p class="number">
                1.22.2
              </p>
              <p>
                a reference to a statute or statutory provision is a reference to it as amended,
                extended or re-enacted from time to time;
              </p>
            </div>
            <div class="second-level">
              <p class="number">
                1.22.3
              </p>
              <p>a reference to one gender shall include a reference to the other genders; and</p>
            </div>
            <div class="second-level">
              <p class="number">
                1.22.4
              </p>
              <p>
                any words following the terms
                <strong>including</strong>,
                <strong>include</strong>,
                <strong>in particular</strong>,
                <strong>for example</strong> or any similar
                expression shall be construed as illustrative and shall not limit the sense of the
                words, description, definition, phrase or term preceding those terms.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                1.23
              </p>
              <p>
                In the case of conflict or ambiguity between any provision contained in the body of
                this Agreement and any provision contained in the Standard Specification or the
                policies referred to herein, the provision in the body of this Agreement shall take
                precedence.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                1.24
              </p>
              <p>
                In the case of conflict or ambiguity between any provision contained in the body of
                this Agreement and an applicable Works Order, the terms in the Works Order shall
                apply.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                1.25
              </p>
              <p>
                A
                <strong>person</strong> includes a natural person, corporate or unincorporated
                body (whether or not having separate legal personality) and that person’s personal
                representatives, successors and permitted assigns.
              </p>
            </div>
          </AccordionItem>

          <AccordionItem
            :number="2"
            title="HOSTING, SECURITY AND MAINTENANCE STANDARDS"
          >
            <div class="first-level">
              <p class="number">
                2.1
              </p>
              <p>
                With reference to the relevant Data Protection Legislation, where the Customer Data
                includes personal data, the Customer agrees that it shall be the data controller and
                Cloud KB shall be a data processor.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                2.2
              </p>
              <p>
                The parties shall comply with all applicable Data Protection Legislation in the
                processing of Relevant Personal Data.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                2.3
              </p>
              <p>
                Subject to Clause 2.4, Cloud KB shall process Relevant Personal Data only to provide
                the services detailed within this Agreement or otherwise in accordance with
                instructions received from the Customer from time to time.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                2.4
              </p>
              <p>
                Where Cloud KB is obliged by any applicable law to process Relevant Personal Data
                otherwise than in accordance with Clause 2.3, Cloud KB shall inform the Customer of
                that obligation before processing (unless prohibited from doing so by any applicable
                law on important grounds of public interest).
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                2.5
              </p>
              <p>
                Cloud KB shall take reasonable steps to ensure that only those of its personnel who
                need access to the Relevant Personal Data are able to access it, are informed of the
                confidential nature of the Relevant Personal Data and are subject to appropriate
                obligations of confidentiality.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                2.6
              </p>
              <p>
                Cloud KB shall use reasonable endeavours to protect the Customer Data and Relevant
                Personal Data against unauthorised or unlawful processing and against accidental
                loss, destruction, damage, alteration or disclosure.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                2.7
              </p>
              <p>
                Cloud KB shall provide the Customer with assistance in relation to any requests for
                exercising the rights of data subjects pursuant to the Data Protection Legislation,
                and/or as the Customer reasonably requires in order to demonstrate compliance with
                the Data Protection Legislation (including in relation to the requirements of prior
                consultation and the implementation of data protection impact assessments). Cloud KB
                reserves the right to charge the Customer a reasonable fee for such services.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                2.8
              </p>
              <p>
                Cloud KB shall host the Software and data within facilities located within the
                United Kingdom or European Economic Union.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                2.9
              </p>
              <p>
                Where Cloud KB uses third-party hosting or cloud services to process Customer Data,
                those facilities and services shall be provided by best-practice industry suppliers
                who have achieved compliance with industry recognised security and personal data
                protection standards commensurate with the services provided under this Agreement.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                2.10
              </p>
              <p>
                Cloud KB shall as soon as reasonably practical inform the Customer of any discovery
                by Cloud KB of a security breach involving the Customer Data or any personal data
                breach (as defined in the Data Protection Legislation) involving the Relevant
                Personal Data.
              </p>
            </div>
          </AccordionItem>

          <AccordionItem
            :number="3"
            title="DELIVERY, ACCEPTANCE AND INSTALLATION"
          >
            <div class="first-level">
              <p class="number">
                3.1
              </p>
              <p>
                Cloud KB shall make the Software available for the Customer to access from the
                Customer’s own IT environment and such access shall constitute valid delivery of the
                Software.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                3.2
              </p>
              <p>
                In beginning to use the Software for business purposes, the Customer hereby confirms
                that it has had the opportunity to test the Software and the Software operates in
                accordance with the Specification.
              </p>
            </div>
          </AccordionItem>
          <AccordionItem
            :number="4"
            title="LICENCE"
          >
            <div class="first-level">
              <p class="number">
                4.1
              </p>
              <p>
                In consideration of the Fee paid by the Customer to Cloud KB, Cloud KB grants to the
                Customer a non-exclusive, revocable, UK-only, royalty-free, non-assignable or
                sub-licensable, licence for a commencing on and including the date of this Agreement
                to use the Software.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                4.2
              </p>
              <p>In relation to scope of use:</p>
            </div>
            <div class="second-level">
              <p class="number">
                4.2.1
              </p>
              <p>
                for the purposes of Clause 4.1, use of the Software shall be restricted to use of
                the Software in object code form for the purpose of processing the Customer’s data
                for the normal business purposes of the Customer (which shall not include allowing
                the use of the Software by, or for the benefit of, any person other than an employee
                or agent of the Customer).
              </p>
            </div>
            <div class="second-level">
              <p class="number">
                4.2.2
              </p>
              <p>
                the Customer may not use the Software other than as specified in Clause 4.1 and
                Clause 4.2.1 without the prior written consent of Cloud KB, and the Customer
                acknowledges that additional fees may be payable on any change of use approved by
                Cloud KB.
              </p>
            </div>
            <div class="second-level">
              <p class="number">
                4.2.3
              </p>
              <p>
                except as expressly stated in this Clause 4, the Customer has no right (and shall
                not permit any third party) to copy, adapt, reverse engineer, decompile,
                disassemble, modify, adapt or make error corrections to the Software in whole or in
                part except to the extent that any reduction of the Software to human readable form
                (whether by reverse engineering, decompilation or disassembly) is necessary for the
                purposes of integrating the operation of the Software with the operation of other
                software or systems used by the Customer, unless Cloud KB is prepared to carry out
                such action at a reasonable commercial fee or has provided the information necessary
                to achieve such integration within a reasonable period, and the Customer shall
                request Cloud KB to carry out such action or to provide such information (and shall
                meet Cloud KB’s reasonable costs in providing that information) before undertaking
                any such reduction.
              </p>
            </div>
            <div class="second-level">
              <p class="number">
                4.2.4
              </p>
              <p>
                the Third-Party Software shall be deemed to be incorporated within the Software for
                the purposes of this Agreement (except where expressly provided to the contrary) and
                the Customer agrees that they shall at all times adhere to the terms of the
                Third-Party Additional Terms.
              </p>
            </div>
            <div class="second-level">
              <p class="number">
                4.2.5
              </p>
              <p>
                the Customer shall indemnify and hold the Cloud KB harmless against any loss or
                damage which it may suffer or incur as a result of the Customer’s breach of any
                Third-Party Additional Terms howsoever arising.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                4.3
              </p>
              <p>
                The Customer may not use any such information provided by Cloud KB or obtained by
                the Customer during any such reduction permitted under Clause 4.2.3 to create any
                software whose expression is substantially similar to that of the Software nor use
                such information in any manner which would be restricted by any copyright subsisting
                in it.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                4.4
              </p>
              <p>The Customer shall not without the prior written consent of Cloud KB:</p>
            </div>
            <div class="second-level">
              <p class="number">
                4.4.1
              </p>
              <p>
                sub-license, assign or novate the benefit or burden of this Agreement in whole or in
                part;
              </p>
            </div>
            <div class="second-level">
              <p class="number">
                4.4.2
              </p>
              <p>
                allow the Software to become the subject
                of any charge, lien or encumbrance; and
              </p>
            </div>
            <div class="second-level">
              <p class="number">
                4.4.3
              </p>
              <p>
                deal in any other manner with any or all of its rights and obligations under this
                Agreement,
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                4.5
              </p>
              <p>
                Cloud KB may at any time sub-license, assign, novate, charge or deal in any other
                manner with any or all of its rights and obligations under this Agreement, provided
                it gives written notice to the Customer.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                4.6
              </p>
              <p>
                Each party confirms it is acting on its own behalf and not for the benefit of any
                other person.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                4.7
              </p>
              <p>
                Notwithstanding Clause 7, a party assigning any or all of its rights under this
                Agreement may disclose to a proposed assignee any information in its possession that
                relates to this Agreement or its subject matter, the negotiations relating to it and
                the other party which is reasonably necessary to disclose for the purposes of the
                proposed assignment, provided that no disclosure pursuant to this Clause 4.7 shall
                be made until notice of the identity of the proposed assignee has been given to the
                other party.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                4.8
              </p>
              <p>The Customer shall:</p>
            </div>
            <div class="second-level">
              <p class="number">
                4.8.1
              </p>
              <p>
                ensure that the number of persons using the Software does not exceed any limits
                agreed between the parties from time to time;
              </p>
            </div>
            <div class="second-level">
              <p class="number">
                4.8.2
              </p>
              <p>
                keep a complete and accurate record of the Customer’s use of the Software and its
                users, and produce such record to Cloud KB on request from time to time;
              </p>
            </div>
            <div class="second-level">
              <p class="number">
                4.8.3
              </p>
              <p>
                notify Cloud KB as soon as it becomes aware of any unauthorized use of the Software
                by any person; and
              </p>
            </div>
            <div class="second-level">
              <p class="number">
                4.8.4
              </p>
              <p>
                pay for broadening the scope of the licences granted under this Agreement to cover
                the unauthorized use, an amount equal to the fees which Cloud KB would have levied
                (in accordance with its normal commercial terms then current) had it licensed any
                such unauthorised use on the date when such use commenced.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                4.9
              </p>
              <p>
                The Customer shall permit Cloud KB to inspect and have access to any premises (and
                to the computer equipment located there) at or on which the Software is being used,
                and have access to any records kept in connection with this Agreement, for the
                purposes of ensuring that the Customer is complying with the terms of this
                Agreement, provided that Cloud KB provides reasonable advance notice to the Customer
                of such inspections, which shall take place at reasonable times.
              </p>
            </div>
          </AccordionItem>
          <AccordionItem
            :number="5"
            title="MAINTENANCE RELEASES AND SOFTWARE UPDATES"
          >
            <div class="first-level">
              <p class="number">
                5.1
              </p>
              <p>
                Cloud KB shall ensure that any third-party software used to provide the Software is
                updated or patched according to the relevant supplier’s security recommendations
                within a reasonable timeframe given the severity of any identified vulnerability and
                its obligations to provide uninterrupted services to its customers.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                5.2
              </p>
              <p>
                The Customer hereby acknowledges that Cloud KB’s Software is provided to multiple
                customers and that the Standard Specification and Software may therefore need to be
                patched or updated to new versions for reasons that may include: to meet demands
                from other customers; to add or remove Beta Services or 3rd party software; to make
                the Software easier to maintain; to fix vulnerabilities; to meet additional or
                changing industry standards; or to adopt best practice. Although Cloud KB will make
                reasonable efforts to minimise the impact on the Customer of such patches or
                updates, the Customer acknowledges that they may be required to make reasonable
                changes in order to continue to use the Software and that (provided that Cloud KB
                does not remove services from the Software that were material to the Works Orders
                entered into by the Customer) such patches and updates shall not constituted a
                breach of this Agreement.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                5.3
              </p>
              <p>
                Provided that Cloud KB is reasonably satisfied that no impact will be made to the
                Customer, Cloud KB may apply upgrades and patches to the Software at its sole
                discretion and convenience. Where reasonably practical, Cloud KB will nevertheless
                notify the Customer in advance of any planned updates or patches.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                5.4
              </p>
              <p>
                Where an upgrade or patch requires the Software to be made unavailable, Cloud KB
                will provide the Customer with reasonable notification of a planned maintenance
                window, which Cloud KB shall make reasonable endeavours to schedule outside of
                normal business hours. The Customer shall have the right to request that the
                maintenance window is changed if it reasonably believes it would materially impact
                its normal business operations.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                5.5
              </p>
              <p>
                Where Cloud KB releases a new version of its Software that will require the Customer
                to make changes to continue to use the Software, Cloud KB will maintain and support
                the existing version for up to 12 months following notification to the Customer of
                the new version. Cloud KB may at its sole discretion reduce this support period if
                it reasonably believes maintaining the existing version presents security risks or
                because it is otherwise obliged to cease to provide the existing version due to
                reasons beyond its control including changes in legislation, 3rd party licensing
                terms or to maintain compliance with industry standards.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                5.6
              </p>
              <p>
                Cloud KB warrants that no Maintenance Release will adversely affect the then
                existing facilities or functions of the Software as being used by the Customer. This
                warranty shall not apply to any Beta Services.
              </p>
            </div>
          </AccordionItem>
          <AccordionItem
            :number="6"
            title="FEES"
          >
            <div class="first-level">
              <p class="number">
                6.1
              </p>
              <p>
                In respect of this Agreement the Customer shall pay Cloud KB the sums set out in any
                Works Order according to the terms of the Works Order.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                6.2
              </p>
              <p>
                If the Customer fails to make any payment due to Cloud KB under this Agreement by
                the due date for payment, then, without limiting Cloud KB’s remedies under Clause
                11, the Customer shall pay interest on the overdue amount at the rate of 4% per
                annum above Bank of England’s base rate from time to time. Such interest shall
                accrue on a daily basis from the due date until actual payment of the overdue
                amount, whether before or after judgment. The Customer shall pay the interest
                together with the overdue amount.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                6.3
              </p>
              <p>
                The Customer acknowledges that all fees will be reviewed annually and Cloud KB has
                the right to adjust them in line with inflationary increases.
              </p>
            </div>
          </AccordionItem>
          <AccordionItem
            :number="7"
            title="CONFIDENTIALITY AND PUBLICITY"
          >
            <div class="first-level">
              <p class="number">
                7.1
              </p>
              <p>
                Each party shall, during the term of this Agreement and thereafter, keep
                confidential all, and shall not use for its own purposes (other than implementation
                of this Agreement) nor without the prior written consent of the other disclose to
                any third party (except its professional advisors or as may be required by any law
                or any legal or regulatory authority) any information of a confidential nature
                (including trade secrets and information of commercial value) which may become known
                to such party from the other party and which relates to the other party or any of
                its Affiliates, unless that information is public knowledge or already known to such
                party at the time of disclosure, or subsequently becomes public knowledge other than
                by breach of this Agreement, or subsequently comes lawfully into the possession of
                such party from a third party. Each party shall use its reasonable endeavours to
                prevent the unauthorised disclosure of any such information.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                7.2
              </p>
              <p>
                No party shall make, or permit any person to make, any public announcement
                concerning this Agreement without the prior written consent of the other parties
                (such consent not to be unreasonably withheld or delayed), except as required by
                law, any governmental or regulatory authority (including, without limitation, any
                relevant securities exchange), any court or other authority of competent
                jurisdiction.
              </p>
            </div>
          </AccordionItem>
          <AccordionItem
            :number="8"
            title="CLOUD KB’S WARRANTIES"
          >
            <div class="first-level">
              <p class="number">
                8.1
              </p>
              <p>
                Cloud KB warrants that the Software provided to the Customer under the relevant
                Works Order will conform in all material respects to the Specification (excluding
                any Beta Services) for a period of 45 days from the date of the corresponding Works
                Order (<strong>Warranty Period</strong>). If, within the Warranty Period, the
                Customer notifies Cloud KB in writing of any defect or fault in the Software in
                consequence of which it fails to conform in all material respects to the
                Specification, and such defect or fault does not result from the Customer, or anyone
                acting with the authority of the Customer, having amended the Software or used it
                outside the terms of this Agreement for a purpose or in a context other than the
                purpose or context for which it was designed or in combination with any other
                software not provided by Cloud KB, or it has not been loaded onto Cloud KB-specified
                or suitably configured equipment, Cloud KB shall, at Cloud KB’s option, do one of
                the following:
              </p>
            </div>
            <div class="second-level">
              <p class="number">
                8.1.1
              </p>
              <p>repair the Software;</p>
            </div>
            <div class="second-level">
              <p class="number">
                8.1.2
              </p>
              <p>replace the Software; or</p>
            </div>
            <div class="second-level">
              <p class="number">
                8.1.3
              </p>
              <p>
                terminate this Agreement immediately by notice in writing to the Customer and refund
                any of the Fee paid by the Customer as at the date of termination (less a reasonable
                sum in respect of the Customer’s use of the Software to the date of termination) on
                return of the Software and all copies thereof,
              </p>
            </div>
            <div class="second-level">
              <p class="number">
                8.1.4
              </p>
              <p>
                provided the Customer provides all the information that may be necessary to assist
                Cloud KB in resolving the defect or fault, including a documented example of any
                defect or fault, or sufficient information to enable Cloud KB to re-create the
                defect or fault.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                8.2
              </p>
              <p>
                Cloud KB does not warrant that the use of the Software will be uninterrupted or
                error-free.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                8.3
              </p>
              <p>
                The Customer accepts responsibility for the selection of the Software to achieve its
                intended results and acknowledges that the Software has not been developed to meet
                the individual requirements of the Customer.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                8.4
              </p>
              <p>
                All other conditions, warranties or other terms which might have effect between the
                parties or be implied or incorporated into this Agreement or any collateral
                contract, whether by statute, common law or otherwise, are hereby excluded,
                including the implied conditions, warranties or other terms as to satisfactory
                quality, fitness for purpose or the use of reasonable skill and care.
              </p>
            </div>
          </AccordionItem>
          <AccordionItem
            :number="9"
            title="LIMITS OF LIABILITY"
          >
            <div class="first-level">
              <p class="number">
                9.1
              </p>
              <p>
                Except as expressly stated in Clause 9.2 Cloud KB shall not in any circumstances
                have any liability for any losses or damages which may be suffered by the Customer
                (or any person claiming under or through the Customer), whether the same are
                suffered directly or indirectly or are immediate or consequential, and whether the
                same arise in contract, tort (including negligence) or otherwise howsoever, which
                fall within any of the following categories:
              </p>
            </div>
            <div class="second-level">
              <p class="number">
                9.1.1
              </p>
              <p>
                special damage even if Cloud KB was aware of the circumstances in which such special
                damage could arise;
              </p>
            </div>
            <div class="second-level">
              <p class="number">
                9.1.2
              </p>
              <p>loss of profits;</p>
            </div>
            <div class="second-level">
              <p class="number">
                9.1.3
              </p>
              <p>loss of anticipated savings;</p>
            </div>
            <div class="second-level">
              <p class="number">
                9.1.4
              </p>
              <p>loss of business opportunity;</p>
            </div>
            <div class="second-level">
              <p class="number">
                9.1.5
              </p>
              <p>loss of goodwill;</p>
            </div>
            <div class="second-level">
              <p class="number">
                9.1.6
              </p>
              <p>loss or corruption of data,</p>
            </div>
            <div class="first-level">
              <p class="number">
                9.2
              </p>
              <p>
                The total liability of Cloud KB, whether in contract, tort (including negligence) or
                otherwise and whether in connection with this Agreement or any collateral contract,
                shall in no circumstances exceed a sum equal to the monthly Fee.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                9.3
              </p>
              <p>
                The Customer agrees that, in entering into this Agreement, either it did not rely on
                any representations (whether written or oral) of any kind or of any person other
                than those expressly set out in this Agreement or (if it did rely on any
                representations, whether written or oral, not expressly set out in this Agreement)
                that it shall have no remedy in respect of such representations and (in either case)
                Cloud KB shall have no liability in any circumstances otherwise than in accordance
                with the express terms of this Agreement.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                9.4
              </p>
              <p>
                The exclusions in Clause 8.4 and Clause 9.1 shall apply to the fullest extent
                permissible at law, but Cloud KB does not exclude liability for:
              </p>
            </div>
            <div class="second-level">
              <p class="number">
                9.4.1
              </p>
              <p>
                death or personal injury caused by the negligence of Cloud KB, its officers,
                employees, contractors or agents;
              </p>
            </div>
            <div class="second-level">
              <p class="number">
                9.4.2
              </p>
              <p>fraud or fraudulent misrepresentation;</p>
            </div>
            <div class="second-level">
              <p class="number">
                9.4.3
              </p>
              <p>
                breach of the obligations implied by section 12 of the Sale of Goods Act 1979 or
                section 2 of the Supply of Goods and Services Act 1982; or
              </p>
            </div>
            <div class="second-level">
              <p class="number">
                9.4.4
              </p>
              <p>any other liability which may not be excluded by law.</p>
            </div>
            <div class="first-level">
              <p class="number">
                9.5
              </p>
              <p>
                All dates supplied by Cloud KB for the delivery of the Software shall be treated as
                approximate only. Cloud KB shall not in any circumstances be liable for any loss or
                damage arising from any delay in delivery beyond such approximate dates.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                9.6
              </p>
              <p>
                All references to “Cloud KB” in this Clause 9 shall, for the purposes of this
                clause, be treated as including all employees and subcontractors of Cloud KB and its
                Affiliates, all of whom shall have the benefit of the exclusions and limitations of
                liability set out in this clause.
              </p>
            </div>
          </AccordionItem>
          <AccordionItem
            :number="10"
            title="INTELLECTUAL PROPERTY RIGHTS"
          >
            <div class="first-level">
              <p class="number">
                10.1
              </p>
              <p>
                The Customer acknowledges that all Intellectual Property Rights in the Software
                and any Maintenance Releases, except for Custom Workflows, belong and shall belong
                to Cloud KB, and the Customer shall have no rights in or to the Software other than
                the right to use it in accordance with the terms of this Agreement. All Intellectual
                Property Rights in the Custom Workflows shall vest on creation in the Customer
                absolutely, and Customer hereby grants to Cloud KB a non-exclusive licence to use
                such Custom Workflows to operate the Software on Customer’s behalf
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                10.2
              </p>
              <p>
                Cloud KB undertakes at its own expense to defend the Customer or, at its option,
                settle any claim or action brought against the Customer alleging that the possession
                or use of the Software (or any part thereof) in accordance with the terms of this
                Agreement infringes the UK Intellectual Property Rights of a third party
                (<strong>Claim</strong>)
                and shall be responsible for any reasonable losses, damages, costs (including
                legal fees) and expenses incurred by or awarded against the Customer as a result
                of or in connection with any such Claim. For the avoidance of doubt, this Clause
                10.2 shall not apply where the Claim in question is attributable to possession
                or use of the Software (or any part thereof) by the Customer other than in
                accordance with the terms of this Agreement, use of the Software in combination
                with any hardware or software not supplied or specified by Cloud KB if the
                infringement would have been avoided by the use of the Software not so combined,
                or use of a non-current release of the Software.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                10.3
              </p>
              <p>
                If any third party makes a Claim, or notifies an intention to make a Claim
                against the Customer, Cloud KB’s obligations under Clause 10.2 are conditional
                on the Customer:
              </p>
            </div>
            <div class="second-level">
              <p class="number">
                10.3.1
              </p>
              <p>
                as soon as reasonably practicable, giving written notice of the Claim to Cloud
                KB, specifying the nature of the Claim in reasonable detail;
              </p>
            </div>
            <div class="second-level">
              <p class="number">
                10.3.2
              </p>
              <p>
                not making any admission of liability, agreement or compromise in relation
                to the Claim without the prior written consent of Cloud KB (such consent
                not to be unreasonably conditioned, withheld or delayed);
              </p>
            </div>
            <div class="second-level">
              <p class="number">
                10.3.3
              </p>
              <p>
                giving Cloud KB and its professional advisers access at reasonable times
                (on reasonable prior notice) to its premises and its officers, directors,
                employees, agents, representatives or advisers, and to any relevant
                assets, accounts, documents and records within the power or control
                of the Customer, so as to enable Cloud KB and its professional
                advisers to examine them and to take copies (at Cloud KB’s expense)
                for the purpose of assessing the Claim; and
              </p>
            </div>
            <div class="second-level">
              <p class="number">
                10.3.4
              </p>
              <p>
                subject to Cloud KB providing security to the Customer to the Customer’s
                reasonable satisfaction against any claim, liability, costs, expenses,
                damages or losses which may be incurred, taking such action as Cloud
                KB may reasonably request to avoid, dispute, compromise or defend
                the Claim.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                10.4
              </p>
              <p>
                If any Claim is made, or in Cloud KB’s reasonable opinion is likely
                to be made, against the Customer, Cloud KB may at its sole option
                and expense:
              </p>
            </div>
            <div class="second-level">
              <p class="number">
                10.4.1
              </p>
              <p>
                procure for the Customer the right to continue to use the Software
                (or any part thereof) in accordance with the terms of this
                Agreement;
              </p>
            </div>
            <div class="second-level">
              <p class="number">
                10.4.2
              </p>
              <p>modify the Software so that it ceases to be infringing;</p>
            </div>
            <div class="second-level">
              <p class="number">
                10.4.3
              </p>
              <p>replace the Software with non-infringing software; or</p>
            </div>
            <div class="second-level">
              <p class="number">
                10.4.4
              </p>
              <p>
                terminate this Agreement immediately by notice in writing to the
                Customer and refund any of the Fee paid by the Customer as at
                the date of termination (less a reasonable sum in respect of
                the Customer’s use of the Software to the date of termination)
                on return of the Software and all copies thereof,
              </p>
            </div>
            <div class="second-level">
              <p class="number">
                10.4.5
              </p>
              <p>
                provided that if Cloud KB modifies or replaces the Software,
                the modified or replacement Software must comply with the
                warranties contained in Clause 8.1 and the Customer shall
                have the same rights in respect thereof as it would have had
                under those clauses had the references to the date of this
                Agreement been references to the date on which such
                modification or replacement was made.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                10.5
              </p>
              <p>
                This Clause 10 constitutes the Customer’s exclusive remedy
                and Cloud KB’s only liability in respect of Claims and, for
                the avoidance of doubt, is subject to Clause 9.1.
              </p>
            </div>
          </AccordionItem>
          <AccordionItem
            :number="11"
            title="DURATION AND TERMINATION"
          >
            <div class="first-level">
              <p class="number">
                11.1
              </p>
              <p>
                The Agreement shall come into effect from earliest date
                of execution of any applicable Works Order and shall
                continue to run for the initial fixed term and subsequent
                extended terms as set out in that Works Order and as
                amended by any subsequent Works Orders.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                11.2
              </p>
              <p>
                Without affecting any other right or remedy available to it,
                within the first six months of the initial fixed term
                either party may elect to terminate this License without
                reason (“No-Fault Termination”) by providing written
                notice to the other party.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                11.3
              </p>
              <p>Should Cloud KB trigger a No-Fault Termination, it shall:</p>
            </div>
            <div class="second-level">
              <p class="number">
                11.3.1
              </p>
              <p>
                at the Customer’s sole discretion, provide a reasonable
                notice period of between zero and three (3) months before
                Termination during which the Customer will continue to pay
                all fees under this Agreement;
              </p>
            </div>
            <div class="second-level">
              <p class="number">
                11.3.2
              </p>
              <p>
                unless agreed otherwise with Customer, refund any Professional
                Services Fees related to activity solely requested to
                implement this Agreement.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                11.4
              </p>
              <p>Should Customer trigger a No-Fault Termination, it shall:</p>
            </div>
            <div class="second-level">
              <p class="number">
                11.4.1
              </p>
              <p>
                agree with Cloud KB a reasonable notice period before
                Termination (neither party being obliged to agree to more
                than three (3) months’ notice) during which the Customer
                will continue to pay all fees under this Agreement;
              </p>
            </div>
            <div class="second-level">
              <p class="number">
                11.4.2
              </p>
              <p>
                unless agreed otherwise with Cloud KB, pay for any Professional
                Services work already started prior to the notice being given.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                11.5
              </p>
              <p>
                Without affecting any other right or remedy available to it,
                either party may terminate this Agreement at any time and with
                immediate effect by giving written notice to the other party if:
              </p>
            </div>
            <div class="second-level">
              <p class="number">
                11.5.1
              </p>
              <p>
                the other party fails to pay any amount due under this Agreement
                on the due date for payment and remains in default not less than
                30 calendar days after being notified in writing to make such
                payment;
              </p>
            </div>
            <div class="second-level">
              <p class="number">
                11.5.2
              </p>
              <p>
                the other party commits a material breach of any other term of
                this Agreement which breach is irremediable or (if such breach
                is remediable) fails to remedy that breach within a period of 30
                calendar days after being notified in writing to do so;
              </p>
            </div>
            <div class="second-level">
              <p class="number">
                11.5.3
              </p>
              <p>
                the other party suspends, or threatens to suspend, payment of its
                debts or is unable to pay its debts as they fall due or admits
                inability to pay its debts or is deemed unable to pay its debts
                within the meaning of section 123 of the Insolvency Act 1986;
              </p>
            </div>
            <div class="second-level">
              <p class="number">
                11.5.4
              </p>
              <p>
                the other party commences negotiations with all or any class of
                its creditors with a view to rescheduling any of its debts, or
                makes a proposal for or enters into any compromise or arrangement
                with its creditors other than for the sole purpose of a scheme
                for a solvent amalgamation of that other party with one or more
                other companies or the solvent reconstruction of that other party;
              </p>
            </div>
            <div class="second-level">
              <p class="number">
                11.5.5
              </p>
              <p>
                a petition is filed, a notice is given, a resolution is passed,
                or an order is made, for or in connection with the winding up of
                that other party other than for the sole purpose of a scheme for a
                solvent amalgamation of that other party with one or more other
                companies or the solvent reconstruction of that other party;
              </p>
            </div>
            <div class="second-level">
              <p class="number">
                11.5.6
              </p>
              <p>
                an application is made to court, or an order is made, for the
                appointment of an administrator, or if a notice of intention to
                appoint an administrator is given or if an administrator is
                appointed, over the other party;
              </p>
            </div>
            <div class="second-level">
              <p class="number">
                11.5.7
              </p>
              <p>
                the holder of a qualifying floating charge over the assets of
                that other party has become entitled to appoint or has appointed
                an administrative receiver;
              </p>
            </div>
            <div class="second-level">
              <p class="number">
                11.5.8
              </p>
              <p>
                a person becomes entitled to appoint a receiver over the assets
                of the other party or a receiver is appointed over the assets
                of the other party;
              </p>
            </div>
            <div class="second-level">
              <p class="number">
                11.5.9
              </p>
              <p>
                a creditor or encumbrancer of the other party attaches or takes
                possession of, or a distress, execution, sequestration or other
                such process is levied or enforced on or sued against, the whole
                or any part of the other party’s assets and such attachment or
                process is not discharged within 14 days;
              </p>
            </div>
            <div class="second-level">
              <p class="number">
                11.5.10
              </p>
              <p>
                any event occurs, or proceeding is taken, with respect to the
                other party in any jurisdiction to which it is subject that
                has an effect equivalent or similar to any of the events
                mentioned in Clauses 11.5.3 to 11.5.9 (inclusive); or
              </p>
            </div>
            <div class="second-level">
              <p class="number">
                11.5.11
              </p>
              <p>
                the other party suspends or ceases, or threatens to suspend or
                cease, carrying on all or a substantial part of its business.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                11.6
              </p>
              <p>
                Termination or expiry of this Agreement shall not affect any
                rights, remedies, obligations or liabilities of the parties
                that have accrued up to the date of termination or expiry,
                including the right to claim damages in respect of any breach
                of the Agreement which existed at or before the date of
                termination or expiry.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                11.7
              </p>
              <p>On termination for any reason:</p>
            </div>
            <div class="second-level">
              <p class="number">
                11.7.1
              </p>
              <p>
                all rights granted to the Customer under this Agreement
                shall cease;
              </p>
            </div>
            <div class="second-level">
              <p class="number">
                11.7.2
              </p>
              <p>
                the Customer shall cease all activities authorised by this
                Agreement; and
              </p>
            </div>
            <div class="second-level">
              <p class="number">
                11.7.3
              </p>
              <p>
                the Customer shall immediately pay to Cloud KB any sums due
                to Cloud KB under this Agreement, or Cloud KB shall provide a
                pro rata refund for any fees paid in advance for use not yet
                made at the point of termination.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                11.8
              </p>
              <p>
                Within one month of termination, Cloud KB shall delete all
                Customer Data in its possession except as may be required to
                manage any claim, legal requirement or residual obligation
                under this Agreement.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                11.9
              </p>
              <p>
                Any provision of this Agreement which expressly or by
                implication is intended to come into or continue in force on
                or after termination of this Agreement, shall remain in full
                force and effect.
              </p>
            </div>
          </AccordionItem>
          <AccordionItem
            :number="12"
            title="WAIVER"
          >
            <div class="first-level">
              <p class="number">
                12.1
              </p>
              <p>
                No failure or delay by a party to exercise any right or remedy
                provided under this Agreement or by law shall constitute a waiver
                of that or any other right or remedy, nor shall it prevent or
                restrict the further exercise of that or any other right or
                remedy. No single or partial exercise of such right or remedy
                shall prevent or restrict the further exercise of that or any
                other right or remedy.
              </p>
            </div>
          </AccordionItem>
          <AccordionItem
            :number="13"
            title="REMEDIES"
          >
            <div class="first-level">
              <p class="number">
                13.1
              </p>
              <p>
                Except as expressly provided in this Agreement, the rights and
                remedies provided under this Agreement are in addition to, and
                not exclusive of, any rights or remedies provided by law.
              </p>
            </div>
          </AccordionItem>
          <AccordionItem
            :number="14"
            title="ENTIRE AGREEMENT"
          >
            <div class="first-level">
              <p class="number">
                14.1
              </p>
              <p>
                This Agreement and the Works Orders agreed with the Customer
                together with the Standard Specification and Cloud KB’s policies
                in place from time to time and referred to herein contain the
                whole agreement between the parties relating to the subject matter
                hereof and supersede all prior agreements, arrangements and
                understandings between the parties relating to that subject matter.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                14.2
              </p>
              <p>
                Each party acknowledges that, in entering into this Agreement, it
                does not rely on any statement, representation, assurance or
                warranty (whether it was made negligently or innocently) of any
                person (whether a party to this Agreement or not) (
                <strong>Representation</strong>) other than as expressly set
                out in this Agreement.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                14.3
              </p>
              <p>
                Each party agrees that the only rights and remedies available to it
                arising out of or in connection with a Representation shall be
                for breach of contract as expressly provided in this Agreement.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                14.4
              </p>
              <p>
                Nothing in this Clause 14 shall limit or exclude any liability for
                fraud.
              </p>
            </div>
          </AccordionItem>
          <AccordionItem
            :number="15"
            title="VARIATION"
          >
            <div class="first-level">
              <p class="number">
                15.1
              </p>
              <p>
                Subject to clause 22, no variation of this Agreement shall be effective
                unless it is in writing and signed by the parties (or their authorised
                representatives).
              </p>
            </div>
          </AccordionItem>
          <AccordionItem
            :number="16"
            title="SEVERANCE"
          >
            <div class="first-level">
              <p class="number">
                16.1
              </p>
              <p>
                If any provision or part-provision of this Agreement is or becomes invalid,
                illegal or unenforceable, it shall be deemed modified to the minimum extent
                necessary to make it valid, legal and enforceable. If such modification is
                not possible, the relevant provision or part-provision shall be deemed
                deleted. Any modification to or deletion of a provision or part-provision
                under this clause shall not affect the validity and enforceability of the
                rest of this Agreement.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                16.2
              </p>
              <p>
                If any provision or part-provision of this Agreement is invalid, illegal
                or unenforceable, the parties shall negotiate in good faith to amend
                such provision so that, as amended, it is legal, valid and enforceable,
                and, to the greatest extent possible, achieves the intended commercial
                result of the original provision.
              </p>
            </div>
          </AccordionItem>
          <AccordionItem
            :number="17"
            title="COUNTERPARTS"
          >
            <div class="first-level">
              <p class="number">
                17.1
              </p>
              <p>
                This Agreement including any Works Orders may be executed in any number
                of counterparts, each of which when executed shall constitute a duplicate
                original, but all the counterparts shall together constitute the one
                agreement.
              </p>
            </div>
          </AccordionItem>
          <AccordionItem
            :number="18"
            title="THIRD-PARTY RIGHTS"
          >
            <div class="first-level">
              <p class="number">
                18.1
              </p>
              <p>
                A person who is not a party to this Agreement shall not have any rights
                under the Contracts (Rights of Third Parties) Act 1999 to enforce any
                term of this Agreement, but this does not affect any right or remedy
                of a third party which exists, or is available, apart from that Act.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                18.2
              </p>
              <p>
                The rights of the parties to terminate, rescind or agree any variation,
                waiver or settlement under this Agreement are not subject to the
                consent of any person that is not a party to this Agreement.
              </p>
            </div>
          </AccordionItem>
          <AccordionItem
            :number="19"
            title="NO PARTNERSHIP OR AGENCY"
          >
            <div class="first-level">
              <p class="number">
                19.1
              </p>
              <p>
                Nothing in this Agreement is intended to, or shall be deemed to, establish
                any partnership or joint venture between any of the parties, constitute
                any party the agent of another party, or authorise any party to make or
                enter into any commitments for or on behalf of any other party.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                19.2
              </p>
              <p>
                Each party confirms it is acting on its own behalf and not for the benefit
                of any other person.
              </p>
            </div>
          </AccordionItem>
          <AccordionItem
            :number="20"
            title="FORCE MAJEURE"
          >
            <div class="first-level">
              <p class="number">
                20.1
              </p>
              <p>
                Neither party shall be in breach of this Agreement nor liable for delay in
                performing, or failure to perform, any of its obligations under this Agreement
                if such delay or failure result from events, circumstances or causes beyond
                its reasonable control. In such circumstances the affected party shall be
                entitled to a reasonable extension of the time for performing such obligations.
                If the period of delay or non-performance continues for three months, the
                party not affected may terminate this Agreement by giving ten business days’
                written notice to the affected party.
              </p>
            </div>
          </AccordionItem>
          <AccordionItem
            :number="21"
            title="NOTICES"
          >
            <div class="first-level">
              <p class="number">
                21.1
              </p>
              <p>
                Any notice given to a party under or in connection with this Agreement shall
                be in writing and shall be delivered by hand or by pre-paid first-class post
                or other next working day delivery service at its registered office (if a
                company) or its principal place of business (in any other case).
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                21.2
              </p>
              <p>Any notice shall be deemed to have been received:</p>
            </div>
            <div class="second-level">
              <p class="number">
                21.2.1
              </p>
              <p>
                if delivered by hand, on signature of a delivery receipt or at the time the
                notice is left at the proper address; or
              </p>
            </div>
            <div class="second-level">
              <p class="number">
                21.2.2
              </p>
              <p>
                if sent by pre-paid first-class post or other next working day delivery service,
                at 9.00 am on the second Business Day after posting or at the time recorded by
                the delivery service.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                21.3
              </p>
              <p>
                This clause does not apply to the service of any proceedings or other documents in
                any legal action or, where applicable, any arbitration or other method of dispute
                resolution. For the purposes of this clause, “writing” shall not include e-mail.
              </p>
            </div>
          </AccordionItem>
          <AccordionItem
            :number="22"
            title="MODIFICATION OF TERMS"
          >
            <div class="first-level">
              <p class="number">
                22.1
              </p>
              <p>
                Cloud KB may reasonably and in good faith modify these Terms of Service, the
                Standard Specification, it’s Maintenance Policies and other policies as published
                on its website (or other link as Cloud KB may provide) from time to time. The
                most recent version will be published on Cloud KB’s website (or other link as
                Cloud KB may provide).
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                22.2
              </p>
              <p>
                Cloud KB will provide reasonable notification via email of any such changes
                of terms before they become effective and, if the Customer provides reasonable
                grounds why it cannot agree to the revised terms and Cloud KB is unable to
                accommodate the Customer’s position, the Customer may within 30 days of receiving
                such notification provide notice that they wish to trigger a “No-fault Termination”
                as per Clause 11.4. Otherwise, the Customer agrees to be bound by the revised
                terms and accept the revised specification and policies as they become effective.
              </p>
            </div>
          </AccordionItem>
          <AccordionItem
            :number="23"
            title="GOVERNING LAW AND JURISDICTION"
          >
            <div class="first-level">
              <p class="number">
                23.1
              </p>
              <p>
                This Agreement and any dispute or claim arising out of or in connection with it
                or its subject matter or formation (including non-contractual disputes or claims)
                shall be governed by and construed in accordance with the law of England.
              </p>
            </div>
            <div class="first-level">
              <p class="number">
                23.2
              </p>
              <p>
                The parties irrevocably agree that the courts of England shall have exclusive
                jurisdiction to settle any dispute or claim that arises out of or in connection
                with this Agreement or its subject matter or formation (including non-contractual
                disputes or claims).
              </p>
            </div>
          </AccordionItem>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AccordionItem from '../components/AccordionItem.vue';

export default {
  components: {
    AccordionItem,
  },
};
</script>

<style scoped>

.section-inner {
  position: relative;
}

.new-tc-button {
  font-family: 'Roboto-Bold';
  color: black;
  text-decoration: underline;
  position: absolute;
  right: 0;
  top: 0;
  line-height: 45px;
}

.number {
  position: relative;
  margin-right: 20px;
}

.first-level {
  display: flex;
}
.first-level .number {
  min-width: 60px;
}

.second-level {
  display: flex;
  padding-left: 80px;
}
.second-level .number {
  min-width: 90px;
}

.third-level {
  display: flex;
  padding-left: 190px;
}
.third-level .number {
  min-width: 90px;
}

@media (max-width: 768px) {
  .content {
    font-size: 1rem;
  }

  .block-info  .title {
    text-align: left;
  }

  .info-hr {
    margin: 0;
  }

  .number {
    margin-right: 10px;
  }

  .first-level .number {
    min-width: 40px;
  }

  .second-level {
    padding-left: 50px;
  }
  .second-level .number {
    min-width: 64px;
  }

  .third-level {
    padding-left: 124px;
  }
  .third-level .number {
    min-width: 64px;
  }

  .new-tc-button {
    position: relative;
    margin-bottom: 1rem;
  }
}

@media (max-width: 480px) {
  .number {
    margin-right: 0.75rem;
  }

  .first-level .number {
    min-width: auto;
  }

  .second-level {
    padding-left: 1rem;
  }
  .second-level .number {
    min-width: auto;
  }

  .third-level {
    padding-left: 2rem;
  }
  .third-level .number {
    min-width: auto;
  }
}
</style>
