<template>
  <div class="consent-popup">
    <button
      class="close"
      @click="$emit('close')"
    >
      <svg
        viewBox="0 0 357 357"
        xml:space="preserve"
      >
        <polygon
          points="357,35.7 321.3,0 178.5,142.8 35.7,0 0,35.7 142.8,178.5 0,321.3
            35.7,357 178.5,214.2 321.3,357 357,321.3
            214.2,178.5"
        />
      </svg>
    </button>
    <div class="consent-content">
      CloudKB uses cookies to allow us to better understand how the site is
      used. By continuing to use this site, you consent to this policy.
      <br>
      <a href="/cookie-policy">Click to learn more.</a>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.consent-popup {
  background-color: #009FE4;
  color: #fff;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  padding: 27px 25px 22px 17px;
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 10000;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
}
.consent-content {
  width: 242px;
}
.consent-content a {
  color: #fff;
  text-decoration: underline;
}
.close {
  height: 13px;
  width: 13px;
  top: 9px;
  right: 11px;
}
</style>
