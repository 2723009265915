<template>
  <div id="app">
    <AppHeader />
    <router-view />
    <AppFooter />
    <CookieConsent
      v-if="showCookieConsent === true"
      @close="showCookieConsent = false"
    />
  </div>
</template>

<script>
import AppHeader from './components/Header.vue';
import AppFooter from './components/Footer.vue';
import CookieConsent from './components/CookieConsent.vue';

export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter,
    CookieConsent,
  },
  data() {
    return {
      showCookieConsent: true,
    };
  },
  watch: {
    showCookieConsent(display) {
      this.$cookies.set('showCookieConsent', display);
    },
  },
  mounted() {
    if (this.$cookies.get('showCookieConsent')) {
      this.showCookieConsent = this.$cookies.get('showCookieConsent');
    }
  },
};
</script>

<style>
@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url('../public/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Black';
  src: url('../public/fonts/Roboto/Roboto-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Condensed-Bold';
  src: url('../public/fonts/Roboto_Condensed/RobotoCondensed-Bold.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

* {
  padding: 0;
  margin: 0;
  font-family: "Roboto";
  box-sizing: content-box;
}
body {
  width: 100%;
  overflow-x: hidden;
  background-color: #f4f4f4;
}
#app {
  overflow: hidden;
}
div {
  box-sizing: border-box;
}
li {
  list-style: none;
}
button {
  background: none;
  outline: none;
  border: none;
}
a {
  text-decoration: none;
  font-size: 14px;
}
a:hover {
  text-decoration: underline;
}
.btn {
  display: inline-block;
  /* align-items: center;
  justify-content: center; */
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  border: none;
  font-size: 14px;
  cursor: pointer;
  box-sizing: border-box;
  width: auto;
}
.btn-small {
  padding: 6.5px 10px;
  min-width: 127px;
}

.btn-large {
  padding: 11px;
  min-width: 155px;
}
.btn-green {
  background: #64afb7;
}
.btn-green:hover {
  background: #457d83;
}

.btn-wide {
  padding: 0 34px;
  font-size: 14px;
  line-height: 41px;
}

.section {
  padding: 92px 15px;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  box-sizing: border-box;
  z-index: 1;
  background-color: #fff;
  overflow-y: visible;
  margin: 0 auto;
  max-width: 2560px;
}

.section-inner {
  display: flex;
  flex-direction: column;
  max-width: 1128px;
  width: 100%;
  z-index: 2;
  margin: 0 auto;
  position: relative;
}

.content {
  padding: 33px 0;
  color: #878b91;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 28px;
}
.content p {
  margin-bottom: 22px;
}

.content .margin-bottom {
  margin-bottom: 22px;
  width: 100%;
  display: inline-block;
}

.content a {
  color: #63afb7;
  font-size: inherit;
}
.content .btn {
  color: #fff;
}
.content strong {
  color: #000;
}
.content li {
  padding-left: 45px;
  margin-top: 22px;
  position: relative;
}
.content li::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 6px;
  width: 14px;
  height: 14px;
  background-color: #f99595;
  border-radius: 50%;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-content: space-between;
}

.section-transparent {
  background: none;
}
h2.title {
  font-size: 40px;
  margin-bottom: 27px;
  line-height: 45px;
}
h3 {
  font-size: 25px;
  /* padding-bottom: 15px; */
  line-height: 23px;
}

.item span {
  color: #878b91;
  font-size: 20px;
  line-height: 23px;
  width: 100%;
}

.title {
  font-weight: 700;
}
.sub-title {
  color: #878b91;
  font-size: 28px;
  line-height: 35px;
  margin-top: 29px;
}

.item {
  box-sizing: border-box;
}
.triangle {
  width: 100%;
  height: 100%;
  fill: #fff;
  display: block;
}
.triangle path {
 fill: #fff;
}
.close {
  position: absolute;
  right: 32px;
  top: 32px;
  width: 32px;
  height: 32px;
  opacity: 0.8;
}
.close:hover {
  opacity: 1;
}
.close svg {
  width: 100%;
  height: 100%;
  display: block;
}
.close polygon {
  fill: #fff;
}

.info-hr {
  width: 209px;
  height: 2px;
  background: #f99595;
  border: none;
  margin-left: 2px;
  display: block;
}

@media (max-width: 768px) {
  .content {
    font-size: 20px;
    line-height: 23px;
  }
  .row {
    justify-content: center;
  }
  .section {
    padding: 54px 18px 36px 18px;
  }
  .section .block-info {
    padding: 0;
      text-align: center;
  }
  h2.title {
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    margin-bottom: 19px;
  }

  .info-hr {
    width: 172px;
    margin: auto;
  }
  .sub-title {
    font-size: 20px;
    line-height: 25px;
    margin-top: 12px;
    text-align: center;
  }

  h3 {
    font-size: 20px;
  }
}
</style>
