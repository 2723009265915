<template>
  <AccordionItem
    :number="number"
    title="DISPUTE RESOLUTION"
  >
    <div class="first-level">
      <p class="number">
        {{ number }}.1.
      </p>
      <p>
        If any dispute or disagreement should arise between the Parties,
        either Party may call a meeting with the other in order to seek
        a resolution of the issue, and such meeting shall take place
        within a reasonable period of such request.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.2.
      </p>
      <p>
        If the Parties are unable to reach a resolution within ten (10)
        working days of the date on which a meeting was requested in
        ccordance with Clause {{ number }}.1, then they may escalate the issue to a
        representative of their executive team for resolution.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.3.
      </p>
      <p>
        If an issue remains unresolved within ten (10) working days of being
        escalated in accordance with Clause {{ number }}.2 (or if, prior to the expiry
        of this period, the individuals to whom it has been escalated agree
        that they will be unable to resolve the issue between them) then
        the Parties may agree to refer the matter to Alternative Dispute
        Resolution (ADR), in which case each Party shall participate in the
        ADR process in good faith with the aim of reaching an agreeable
        resolution. Unless otherwise agreed, each Party shall bear its
        own costs incurred during participation in the ADR process and
        shall split any joint costs (such as the costs of a mediator)
        in equal shares.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.4.
      </p>
      <p>
        Nothing in this Clause {{ number }} shall prevent the Parties from having
        recourse to the courts at any time, save that a Party may only
        commence court proceedings prior to the conclusion of the
        escalation procedure where failure to do so may prejudice that
        Party’s ability to bring a claim, where urgent interim relief
        is required or where the other Party refuses to engage with
        attempts to resolve the dispute.
      </p>
    </div>
  </AccordionItem>
</template>

<script>
import AccordionItem from '../../components/AccordionItem.vue';

export default {
  components: {
    AccordionItem,
  },
  props: {
    number: {
      type: String,
      default: null,
    },
  },
};
</script>
