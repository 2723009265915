<template>
  <div class="section">
    <div class="section-inner row">
      <div class="part-of-correla-logo">
        <img src="/img/part-of-correla.png">
      </div>
      <div class="message">
        <p>
          Cloud KB Limited is delighted to be part of the growing
          <a
            target="_blank"
            href="https://www.correla.com/"
          >
            Correla
          </a>
          family. Our cutting edge uMESH platform, expert team and unrivalled
          knowledge of the rapidly changing Energy Industry is second to none.
          Together we are system-ready to play an integral role in the
          challenge of decarbonisation and the opportunities this represents.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.part-of-correla-logo img {
  width: 393px;
}

.message {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* color: #878b91; */
  line-height: 1.66;
  font-size: 20px;
  padding-left: 2rem;
  max-width: 810px
}

.message a,
.message a:visited {
  font-size: inherit;
  color: #278ecd;
}

@media (max-width: 768px) {
  .part-of-correla-logo img {
    width: 300px;
  }
  .section-inner {
    flex-direction: column;
  }

  .message {
    padding: 2rem 0;
  }
}
</style>
