<template>
  <div class="accordion-item">
    <div
      class="accordion-header"
      :style="titleStyle"
      @click.prevent="onClick"
    >
      <span>{{ number }}.</span>{{ title }}
    </div>
    <div
      ref="content"
      :class="{
        'accordion-content': true,
        'show': isShown,
        'animating': animating,
      }"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    number: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    titleStyle: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isShown: false,
      animating: false,
    };
  },
  mounted() {
    window.addEventListener('resize', () => {
      if (this.isShown) {
        this.show();
      }
    });
  },
  methods: {
    onClick() {
      if (this.animating) {
        return;
      }

      if (this.isShown) {
        this.hide();
      } else {
        this.show();
      }
    },
    show() {
      const { content } = this.$refs;

      if (!content) {
        return;
      }

      this.isShown = true;
      this.animating = true;

      content.style.maxHeight = `${content.scrollHeight + 2 * 16 + 1}px`;

      setTimeout(() => {
        this.animating = false;
        content.style.maxHeight = 'unset';
      }, 350);
    },
    hide() {
      const { content } = this.$refs;

      if (!content) {
        return;
      }

      content.style.maxHeight = null;
      this.isShown = false;

      this.animating = true;
      setTimeout(() => {
        this.animating = false;
      }, 350);
    },
  },
};
</script>

<style scoped>
.accordion-item {
  border: 1px solid #efefef;
  border-radius: 0.5rem;
  margin: 1rem 0;
  overflow: hidden;
}
.accordion-header {
  line-height: 1.6rem;
  cursor: pointer;
  padding: 1.2rem 1rem;
  font-weight: bold;
  color: #000;
}
.accordion-header:hover {
  background-color: #efefef;
}

.accordion-header span {
  min-width: 3rem;
}

.accordion-header > * {
  display: inline-block;
}

.accordion-content {
  padding: 0 1rem;
  max-height: 0;
  overflow: hidden;
  transition: all 0.35s ease-in-out;
}

.animating {
  pointer-events: none;
}

.accordion-content.show {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-top: 1px solid #efefef;
}
</style>
