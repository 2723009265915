<template>
  <img :src="src">
</template>

<script>
export default {
  computed: {
    mobile() {
      return window.innerWidth <= 768;
    },
    src() {
      return !this.mobile ? '/img/about-us/collage.jpg' : '/img/about-us/collage-mobile.jpg';
    },
  },
};
</script>

<style scoped>
img {
  display: block;
  width: 100%;
}
</style>
