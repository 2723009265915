<template>
  <div>
    <div class="section privacy-policy">
      <div class="section-inner">
        <div class="block-info">
          <h2 class="title">
            Privacy Policy
          </h2>
          <hr class="info-hr">
        </div>
        <div class="content">
          <p>
            Cloud KB Limited (“<strong>We</strong>” or “<strong>we</strong>” or “<strong>us</strong>”) are committed to
            protecting and respecting your privacy.
          </p>
          <p>
            This policy (together with our terms of use, as set out on our website and any other documents referred to
            on it) sets out the basis on which any personal data we collect from you, or that you provide to us, will be
            processed by us. Please read the following carefully to understand our views and practices regarding your
            personal data and how we will treat it.
          </p>
          <p>
            For the purpose of the General Data Protection Regulation 2016/679 (&#34;<strong>GDPR</strong>&#34;), the
            controller of your personal data is Cloud KB Limited, a company registered in England and Wales under
            company number 07944619 whose registered office is at 65 New Rd, Solihull B91 3DL, United Kingdom. Cloud KB
            Limited is a subsidiary company of Correla Limited, a company registered in England and Wales under company
            number 13062055 whose registered office is at 65 New Road, Solihull, B91 3DL. You can find a copy of
            Correla&#39;s Privacy Notice at <a href="https://www.correla.com/privacy-policy/"
              title="Link to Correla Privacy Policy">https://www.correla.com/privacy-policy</a>.
          </p>
          <p>
            All your personal data will be held and used in accordance with the GDPR and any relevant privacy laws and
            legislation.
          </p>
        </div>

        <hr class="info-hr">

        <div class="content">
          <p>
            <strong>Information we may collect from you</strong>
          </p>
          <p>
            We may collect and process the following personal data about you:
          </p>
          <ul>
            <li>
              <strong>Information you give us directly:</strong> You may give us information about you by filling in
              forms on our site <a href="https://cloudkb.co.uk" title="CloudKB Website">https://cloudkb.co.uk</a> (our
              “site”) or by corresponding with us by phone, e-mail or otherwise. The information you give us may include
              your name, address, e-mail address and phone number.
            </li>
            <li>
              <strong>Information we collect about you.</strong> With regard to each of your visits to our site we may
              automatically collect the following information:
              <ul>
                <li>
                  technical information, including the Internet Protocol (IP) address used to connect your computer to
                  the Internet, your login information, browser type and version, time zone setting, browser plug-in
                  types and versions, operating system and platform;
                </li>
                <li>
                  information about your visit, including the full Uniform Resource Locators (URL) clickstream to,
                  through and from our site (including date and time); products you viewed or searched for; page
                  response times, download errors, length of visits to certain pages, page interaction information (such
                  as scrolling, clicks, and mouse-overs), and methods used to browse away from the page and any phone
                  number used to call our customer service number.
                </li>
              </ul>
            </li>
            <li>
              <strong>Information we receive from other sources/3<sup>rd</sup> parties.</strong> We may receive
              information about you if you use any of the other websites we operate or the other services we provide. We
              are also working closely with third parties (including, for example, business partners, sub-contractors in
              technical, payment and delivery services, advertising networks, analytics providers, search information
              providers, credit reference agencies etc.) and may receive personal information about you from them. This
              could also include customer vulnerability codes, MPRNs and MPANs.
            </li>
          </ul>
        </div>

        <hr class="info-hr">

        <div class="content">
          <p>
            <strong>Cookies</strong>
          </p>
          <p>
            Our website uses cookies to distinguish you from other users of our website. This helps us to provide you
            with a good experience when you browse our website and also allows us to improve our site. For detailed
            information on the cookies we use and the purposes for which we use them, please see our <a
              href="/cookie-policy" title="Our Cookie Policy">Cookie Policy</a> which is updated from time to time.
          </p>
          <p>
            <strong>Uses made of the information</strong>
          </p>
          <p>
            We use information held about you in the following ways:
          </p>
          <ul>
            <li>
              <strong>Information you give us.</strong> We will use this information:
              <ul>
                <li>
                  to carry out our obligations arising from any contracts entered into between you and us, to provide
                  you with the information, products and services that you request from us;
                </li>

                <li>
                  to provide customer support and maintenance processes including service level assurance;
                </li>

                <li>
                  to provide access by support staff who will have visibility to the entire job record which will
                  include vulnerability codes;
                </li>

                <li>
                  to enable us to provide customer engagement, training, access management, data management and service
                  continuity;
                </li>

                <li>
                  to ensure that content from our site is presented in the most effective manner for you and for your
                  computer.
                </li>
              </ul>
            </li>
            <li>
              <strong>Information we collect about you.</strong> We will use this information:
              <ul>
                <li>
                  to administer our site and for internal operations, including troubleshooting, data analysis, testing,
                  research, statistical and survey purposes;
                </li>

                <li>
                  to improve our site to ensure that content is presented in the most effective manner for you and for
                  your computer;
                </li>

                <li>
                  to allow you to participate in interactive features of our service, when you choose to do so; as part
                  of our efforts to keep our site safe and secure;
                </li>

                <li>
                  to measure or understand the effectiveness of advertising we serve to you and others, and to deliver
                  relevant advertising to you;
                </li>

                <li>
                  to make suggestions and recommendations to you and other users of our site about goods or services
                  that may interest you or them.
                </li>
              </ul>
            </li>
            <li>
              <strong>Information we receive from other sources.</strong> We may combine this information with
              information you give to us and information we collect about you. We may use this information and the
              combined information for the purposes set out above (depending on the types of information we receive).
            </li>
          </ul>
          <br><br>
          <p>
            <strong>The legal basis for processing your information</strong>
          </p>
          <p>
            In accordance with GDPR, the main grounds that we rely upon in order to process your information are as
            follows:
          </p>
          <ul>
            <li>
              <strong>Necessary for entering into or performing a contract.</strong> In order to perform obligations
              which arise under any contract we have entered into with you, it will be necessary for us to process your
              information.
            </li>

            <li>
              <strong>Necessary for the purposes of legitimate interests.</strong> Either we or a third party will need
              to process your information for the purposes of our (or a third party&#39;s) legitimate interests,
              provided that we have established that those interests are not overridden by your rights and freedoms
              (including your right to have your information protected). Our legitimate interests include responding to
              requests and enquiries from you or a third party, optimising our website and user experience, informing
              you about our services and ensuring that our operations are conducted in an appropriate and efficient
              manner.
            </li>
          </ul>
          <br><br>
          <p>
            <strong>Your rights</strong>
          </p>

          <p>
            Legally, you have a number of important personal data rights. In summary, these are rights to:
          </p>

          <ul>
            <li>
              Fair processing of information and transparency over how we use your personal information
            </li>
            <li>
              Access to your personal information and to certain other supplementary information
            </li>
            <li>
              Request us to correct any mistakes in your information which we hold
            </li>
            <li>
              Request us to stop processing or erase your personal information in certain situations
            </li>
          </ul>
          <br><br>
          <p>
            <strong>How to contact us</strong>
          </p>

          <p>
            We hope that we can resolve any query or concern you raise about our use of your personal information.
          </p>

          <p>
            To exercise all relevant rights, queries or complaints, we ask that in the first instance you contact the
            Data Protection Officer at <a
              href="mailto:box.correla.privacy@correla.com">box.correla.privacy@correla.com</a>.
          </p>

          <p>
            If we or our Data Protection Officer do not resolve your complaint to your satisfaction, you have the right
            to lodge a complaint with the Information Commissioners Office (ICO), or to seek a judicial remedy in the
            courts of England and Wales. The ICO can investigate your claim and take action against anyone who has
            misused personal data. Further details are available on their website <a href="https://ico.org.uk/concerns/"
              title="ICO website URL">https://ico.org.uk/concerns/</a> or via the ICO helpline: 0303 123 1113.
          </p>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
@media (max-width: 768px) {
  .block-info .title {
    text-align: left;
  }

  .info-hr {
    margin: 0;
  }
}
</style>
