<template>
  <AccordionItem
    :number="number"
    title="FORCE MAJEURE"
  >
    <div class="first-level">
      <p class="number">
        {{ number }}.1.
      </p>
      <p>
        Neither Party shall be in breach of these terms and Works Order
        nor liable for delay in performing, or failure to perform, any
        of its obligations under these terms and Works Order if such
        delay or failure result from a Force Majeure Event. In such
        circumstances the affected Party shall be entitled to a reasonable
        extension of the time for performing such obligations. If the
        period of delay or non-performance continues for three (3)
        months, the Party not affected may terminate these terms and
        Works Order by giving thirty (30) calendar days’ written
        notice to the affected party.
      </p>
    </div>
  </AccordionItem>
</template>

<script>
import AccordionItem from '../../components/AccordionItem.vue';

export default {
  components: {
    AccordionItem,
  },
  props: {
    number: {
      type: String,
      default: null,
    },
  },
};
</script>
