eslint-disable
<template>
  <footer class="footer">
    <div class="footer-inner">
      <a href="/" class="footer-logo">
        <img src="/img/part-of-correla-white.png" alt>
      </a>

      <div class="address">
        <span>
          <br v-if="!mobile">Lansdowne Gate, 65 New Road <br v-if="!mobile">Solihull, B91 3DL
        </span>
        <br v-if="!mobile">
        <br v-if="!mobile">
        <br v-if="!mobile">
      </div>
      <nav class="nav">
        <p>About Us</p>
        <ul>
          <li v-for="(item, i) in information" :key="i">
            <a :href="item.href">{{ item.text }}</a>
          </li>
        </ul>
      </nav>
      <!-- <nav class="nav">
        <ul>
          Case studies
          <li
            v-for="(item, i) in caseStudies"
            :key="i"
          >
            <a :href="item.href">{{ item.text }}</a>
          </li>
        </ul>
      </nav> -->
      <!-- <nav class="nav">
        <ul>
          CONFIGURATIONS
          <li
            v-for="(item, i) in products"
            :key="i"
          >
            <a :href="item.href">{{ item.text }}</a>
          </li>
        </ul>
      </nav> -->
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      information: [
        {
          href: '/company',
          text: 'Company',
        },
        {
          href: '/contact-us',
          text: 'Contact',
        },
        {
          href: 'https://medium.com/cloudkb',
          text: 'Blog',
        },
        {
          href: '/cookie-policy',
          text: 'Cookie policy',
        },
        {
          href: '/privacy-policy',
          text: 'Privacy policy',
        },
        {
          href: '/terms-of-service',
          text: 'Terms and conditions',
        },
        {
          href: 'https://www.notion.so/Support-and-Maintenance-Policy-09be244dad484e2da8907dfc4ab47611',
          text: 'Support and Maintenance Policy',
        },
        {
          href: '/abc',
          text: 'ABC Policy',
        },
        {
          href: '/modern-slavery',
          text: 'Modern Slavery',
        },
      ],
      // caseStudies: [
      //   {
      //     href: '/casestudy-mam',
      //     text: 'New MAM',
      //   },
      //   {
      //     href: '/casestudy-map',
      //     text: 'New MAP',
      //   },
      //   {
      //     href: '/casestudy-bespokeflow',
      //     text: 'Industry Flows',
      //   },
      // ],
      // products: [
      //   {
      //     href: '/meter-asset-manager',
      //     text: 'METER ASSET MANAGER (MAM)',
      //   },
      //   {
      //     href: '/meter-asset-provider',
      //     text: 'METER ASSET PROVIDOR (MAP)',
      //   },
      //   {
      //     href: '/smart-meter-commissioning',
      //     text: 'SMART COMMISSIONING',
      //   },
      //   {
      //     text: 'MOP',
      //     href: '/mop',
      //   },
      // ],
    };
  },
  computed: {
    mobile() {
      return window.innerWidth <= 768;
    },
  },
};
</script>

<style scoped>
.footer {
  background: #000;
  padding: 40px 15px;
  box-sizing: content-box;
  height: 100%;
  color: #fff;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  justify-content: center;
  z-index: 10;
  position: relative;
}

.footer-inner {
  width: 1122px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footer-logo img {
  height: 100px;
}

.nav,
.address {
  max-width: 220px;
  width: 20%;
  height: 100%;
}

.address {
  padding-left: 24px;
}

nav>p {
  text-transform: uppercase;
  margin-bottom: 1rem;
  font-family: "Roboto-Bold";
}

nav ul {
  text-transform: uppercase;
  font-weight: 500;
  margin-top: 5px;
}

nav li {
  text-transform: none;
  margin-bottom: 0.5rem;
}

nav li:first-child {
  margin-top: 15px;
}

a {
  font-weight: 200;
  color: inherit;
}

a:hover {
  color: #8d8f8f;
}

@media (max-width: 1280px) {
  .address {
    padding-left: 14px;
  }
}

@media (max-width: 768px) {
  .footer {
    padding: 31px 18px 72px 18px;
  }

  .footer-inner {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .footer-logo img {
    height: 62px;
  }

  .nav {
    width: 100%;
    text-align: center;
    max-width: 200px;
    margin-top: 20px;
  }

  .nav li:first-child {
    margin-top: 10px;
  }

  .address {
    width: 100%;
    text-align: center;
    padding: 0;
    margin-top: 20px;
  }
}
</style>
