<!-- eslint-disable -->
<template>
  <div class="section">
    <div class="row">
      <div class="block-info">
        <p>
          <span class="text-red">A single-source, agile, modular system</span>
          making migration remarkably straight-forward and low risk.
        </p>
        <p>
          <span class="text-red">System-Ready Solution for Decarbonisation</span>
          because uMESH is the only fully automated, integrated asset tracking and
          data management system created specifically for the whole Energy Industry.
        </p>
      </div>
      <div class="block-info">
        <p>
          <span class="text-red">Seamless industry-specific functionality</span>
          with and cross-intuitive modular learning ensures faster onboarding than any other system.
        </p>

        <p>
          <span class="text-red">Practical, pragmatic and adaptable solution</span>
          leaves behind the configuration nightmares often associated with generic
          multi-platform systems.
        </p>
      </div>
    </div>
    <div class="row features-row">
      <div
        v-for="feature in features"
        class="feature-container"
      >
        <div class="feature">
          <p class="feature-title">{{ feature.title }}</p>
          <img :src="feature.src" class="feature-image" :alt="feature.title">
          <p class="feature-text">{{ feature.text }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      features: [
        {
          title: 'UNDERSTAND',
          src: '/img/features/understand.png',
          text: `Our Industry experts identify the best way to configure the right
            modular uMESH combinations to reduce data errors, increase productivity
            and achieve full compliance`,
        },
        {
          title: 'AUTOMATE',
          src: '/img/features/automate.png',
          text: `Our intelligent uMESH cloud platform allows us to rapidly build a fully
            automated solution using transparent, no code configurations to avoid all those
            nightmares generic systems create.`,
        },
        {
          title: 'INTEGRATE',
          src: '/img/features/integrate.png',
          text: `We seamlessly integrate your chosen
            uMESH modules with your existing
            systems to fit your needs perfectly with
            a single support source - even including
            your CRM!`,
        },
        {
          title: 'IMPROVE',
          src: '/img/features/improve.png',
          text: `uMESH identifies and automates
            the handling of recurring exceptions.
            It adapts swiftly to your processes
            and emerging asset tracking needs
            in whatever sector of the Energy
            Industry you operate.`,
        },
      ],
    };
  },
};
</script>

<style scoped>
  .row {
    max-width: 1230px;
    margin: 0 auto;
  }

  .block-info {
    width: 50%;
  }

  .block-info:first-child {
    padding-right: 2rem;
  }

  .block-info:last-child {
    padding-left: 2rem;
  }

  .block-info p {
    font-size: 20px;
    color: rgb(0, 0, 0);
    line-height: 1.66;
    margin-bottom: 2rem;
  }

  .text-red {
    color: rgb(190, 30, 45);
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: "Roboto-Bold";
  }

  .features-row {
    max-width: 1173px;
  }

  .feature-container {
    margin-top: 2rem;
    width: 25%;
    padding: 0 0.5rem;
  }

  .feature {
    max-width: 270px;
    width: 100%;
    height: 100%;
    padding: 1rem 0.5rem 0.5rem 0.5rem;
    background-color: #bcdcf0;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .feature-title {
    font-size: 32px;
    font-family: "Roboto-Black";
    color: rgb(35, 31, 32);
    font-weight: bold;
    line-height: 0.844;
  }

  .feature-image {
    max-width: 206px;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .feature-text {
    font-size: 14px;
    color: rgb(35, 31, 32);
    line-height: 1.357;
    text-align: center;
  }

  @media (max-width: 1200px) {
    .feature-title {
      font-size: 28px;
    }
  }

  @media (max-width: 960px) {
    .block-info p {
      font-size: 24px;
    }
    .feature-title {
      font-size: 22px;
    }
  }

  @media (max-width: 768px) {
    .row {
        flex-direction: column;
    }
    .block-info {
      width: 100%;
    }
    .block-info:first-child,
    .block-info:last-child {
      padding: 0;
    }

    .features-row {
      flex-direction: column;
      align-items: center;
    }

    .feature-container {
      width: 100%;
      padding: 0;
    }
  }
</style>
