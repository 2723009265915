<template>
  <AccordionItem
    :number="number"
    title="INTERPRETATION"
  >
    <div class="first-level">
      <p class="number">
        {{ number }}.1.
      </p>
      <p>
        In these terms the following rules of interpretation shall apply.
        Paragraph headings shall not affect the interpretation of these terms.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.2.
      </p>
      <p>
        Unless the context otherwise requires:
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.2.1.
      </p>
      <p>
        words in the singular shall include the plural and in the plural shall include the singular;
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.2.2.
      </p>
      <p>
        a reference to a statute or statutory provision is a reference to it as amended,
        extended or re-enacted from time to time;
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.2.3.
      </p>
      <p>
        a reference to one gender shall include a reference to the other genders; and
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.2.4.
      </p>
      <p>
        any words following the terms <strong>including, include, in particular, for example</strong>
        or any similar expression shall be construed as illustrative and shall not
        limit the sense of the words, description, definition, phrase or term preceding those terms.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.3.
      </p>
      <p>
        In the case of conflict or ambiguity between any provision contained
        in the body of these terms and any provision contained in the Works
        Order (including any Special Conditions), Standard Specification or
        the policies referred to herein, the following order of priority shall apply:
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.3.1.
      </p>
      <p>
        the terms set out in the Works Order and Special Conditions which
        expressly state to override the terms set out in the main body of these terms;
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.3.2.
      </p>
      <p>
        the terms set out in the Cloud KB policies;
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.3.3.
      </p>
      <p>
        the terms set out in the main body of these terms.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.4.
      </p>
      <p>
        In the case of conflict or ambiguity between any provision contained
        in the body of these terms and an applicable Works Order, the terms
        in the Works Order shall apply.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.5.
      </p>
      <p>
        A <strong>person</strong> includes a natural person, corporate
        or unincorporated body (whether or not having separate legal
        personality) and that person’s personal representatives,
        successors and permitted assigns.
      </p>
    </div>
  </AccordionItem>
</template>

<script>
import AccordionItem from '../../components/AccordionItem.vue';

export default {
  components: {
    AccordionItem,
  },
  props: {
    number: {
      type: String,
      default: null,
    },
  },
};
</script>
