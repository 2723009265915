<template>
  <AccordionItem
    :number="number"
    title="NOTICES"
  >
    <div class="first-level">
      <p class="number">
        {{ number }}.1.
      </p>
      <p>
        Any notice given to a Party under or in connection with these
        terms and Works Order shall be in writing and shall be
        delivered by hand or by pre-paid first-class post or
        other next working day delivery service at its registered
        office (if a company) or its principal place of business
        (in any other case).
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.2.
      </p>
      <p>
        Any notice shall be deemed to have been received:
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.2.1.
      </p>
      <p>
        if delivered by hand, on signature of a delivery receipt or at
        the time the notice is left at the proper address; or
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.2.2.
      </p>
      <p>
        if sent by pre-paid first-class post or other next working day
        delivery service, at 9.00 am on the second Business Day after
        posting or at the time recorded by the delivery service.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.3.
      </p>
      <p>
        This Clause does not apply to the service of any proceedings or
        other documents in any legal action or, where applicable, any
        arbitration or other method of dispute resolution. For the purposes
        of this Clause, “writing” shall not include e-mail.
      </p>
    </div>
  </AccordionItem>
</template>

<script>
import AccordionItem from '../../components/AccordionItem.vue';

export default {
  components: {
    AccordionItem,
  },
  props: {
    number: {
      type: String,
      default: null,
    },
  },
};
</script>
