<template>
  <AccordionItem
    :number="number"
    title="LIMITATION OF LIABILITY"
  >
    <div class="first-level">
      <p class="number">
        {{ number }}.1.
      </p>
      <p>
        Except as expressly stated in Clause {{ number }}.2 Cloud KB (and its Affiliates)
        shall not in any circumstances have any liability for any losses
        or damages which may be suffered by the Customer (or any person
        claiming under or through the Customer), whether the same are
        suffered directly or indirectly or are immediate or consequential,
        and whether the same arise in contract, tort (including negligence)
        or otherwise howsoever, which fall within any of the following categories:
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.1.1.
      </p>
      <p>
        special damage even if Cloud KB was aware of the circumstances
        in which such special damage could arise;
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.1.2.
      </p>
      <p>
        loss of profits;
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.1.3.
      </p>
      <p>
        loss of anticipated savings;
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.1.4.
      </p>
      <p>
        loss of business opportunity;
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.1.5.
      </p>
      <p>
        loss of goodwill;
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.1.6.
      </p>
      <p>
        loss or corruption of data.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.2.
      </p>
      <p>
        <strong>Aggregate liability</strong>: Subject to Clause {{ number }}.1 and {{ number }}.5 but excluding
        any claim which falls within Clause {{ number }}.3, Cloud KB (and its Affiliates)
        aggregate liability in respect of all causes of action arising out of
        or in connection with these terms and Works Order for the Term (whether
        for breach of contract, strict liability tort (including negligence),
        misrepresentation or otherwise), shall in no circumstances exceed the greater of:
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.2.1.
      </p>
      <p>
        a sum equal to six (6) months of the Fees in relation to the licence only; or
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.2.2.
      </p>
      <p>
        as otherwise specified in the Works Order.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.3.
      </p>
      <p>
        <strong>Data Protection losses</strong>: Subject to Clause {{ number }}.1 and {{ number }}.5,
        Cloud KBs total aggregate liability under these terms and Works Order in
        any Contract Year (whether for breach of contract, strict liability, tort
        (including negligence), misrepresentation or otherwise) in respect of
        all causes of action arising out of or in connection with any breach of
        Clause 6 (Data Protection); or for a claim brought under any specified
        indemnity, shall not exceed two and a half million pounds (£2,500,000).
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.4.
      </p>
      <p>
        The Customer agrees that, in entering into these terms and Works Order,
        either it did not rely on any representations (whether written or oral)
        of any kind or of any person other than those expressly set out in these
        terms and Works Order or (if it did rely on any representations,
        whether written or oral, not expressly set out in these terms and
        Works Order) that it shall have no remedy in respect of such representations
        and (in either case) Cloud KB shall have no liability in any circumstances
        otherwise than in accordance with the express terms herein.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.5.
      </p>
      <p>
        The exclusions in Clause 11.5 and Clause {{ number }}.1 shall apply to the fullest
        extent permissible at law, but neither Party excludes liability for:
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.5.1.
      </p>
      <p>
        death or personal injury caused by the negligence of its officers, employees, contractors or agents;
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.5.2.
      </p>
      <p>
        fraud or fraudulent misrepresentation;
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.5.3.
      </p>
      <p>
        breach of the obligations implied by section 12 of the Sale of
        Goods Act 1979 or section 2 of the Supply of Goods and Services Act 1982;
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.5.4.
      </p>
      <p>
        breach of Modern Slavery Practices; or
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.5.5.
      </p>
      <p>
        any other liability which may not be excluded by law.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.6.
      </p>
      <p>
        All delivery dates stated in the Works Order for the Software shall
        be treated as approximate only. Cloud KB (and its Affiliates)
        shall not in any circumstances be liable for any loss or damage
        arising from any delay in delivery beyond such approximate dates.
      </p>
    </div>
  </AccordionItem>
</template>

<script>
import AccordionItem from '../../components/AccordionItem.vue';

export default {
  components: {
    AccordionItem,
  },
  props: {
    number: {
      type: String,
      default: null,
    },
  },
};
</script>
