<template>
  <div
    :class="{
      'header' : true,
      'show-menu': toggleMenu,
    }"
  >
    <div class="header-inner">
      <a
        href="/"
        class="logo"
      >
        <img src="/img/logo-header.png">
      </a>

      <button
        type="button"
        class="menu-toggle"
        @click="toggleMenu = !toggleMenu"
      >
        <span />
        <span />
        <span />
      </button>

      <nav
        class="menu"
      >
        <ul class="menu-items">
          <li
            v-for="(item, i) in menuItems"
            :key="i"
            class="menu-item"
          >
            <a :href="item.href">{{ item.text }}</a>

            <ul
              v-if="item.submenu"
              class="dropdown"
            >
              <li
                v-for="(subItem, k) in item.submenu"
                :key="k"
              >
                <a :href="subItem.href">{{ subItem.text }}</a>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>

    <nav class="mobile-menu">
      <ul class="menu-items">
        <li
          v-for="(item, i) in menuItems"
          :key="i"
          class="menu-item"
        >
          <a
            v-if="item.submenu"
            @click="selectMenu(i)"
          >{{ item.text }}</a>
          <a
            v-if="!item.submenu"
            :href="item.href"
          >{{ item.text }}</a>

          <ul
            v-if="item.submenu"
            v-show="selectedMenuIndex === i"
          >
            <li
              v-for="(subItem, k) in item.submenu"
              :key="k"
            >
              <a :href="subItem.href">{{ subItem.text }}</a>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuItems: [
        {
          text: 'Field Service APP',
          href: '/smart-meter-commissioning',
        },
        // {
        //   text: 'Case studies',
        //   href: null,
        //   submenu: [
        //     {
        //       text: 'New MAM',
        //       href: '/casestudy-mam',
        //     },
        //     {
        //       text: 'New MAP',
        //       href: '/casestudy-map',
        //     },
        //     {
        //       text: 'Industry Flows',
        //       href: '/casestudy-bespokeflow',
        //     },
        //   ],
        // },
        {
          text: 'blog',
          href: 'https://medium.com/cloudkb',
        },
        {
          text: 'About us',
          href: '/company',
        },
        {
          text: 'Contact us',
          href: '/contact-us',
        },
      ],
      toggleMenu: false,
      selectedMenuIndex: false,
    };
  },
  computed: {
    mobile() {
      return window.innerWidth <= 768;
    },
  },
  methods: {
    selectMenu(i) {
      this.selectedMenuIndex = this.selectedMenuIndex === false ? i : false;
    },
  },
};
</script>

<style scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 15px;
  background-color: #fff;
  z-index: 10;
  position: relative;
  box-sizing: border-box;
}
.header-inner {
  width: 100%;
  max-width: 1122px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header nav {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.header .mobile-menu {
  display: none;
}
.logo {
  /* width: 340px; */
  height: 60px;
  display: flex;
}

@media (max-width: 1100px) {
  .logo {
    height: 50px;
  }
}

@media (max-width: 1000px) {
  .logo {
    height: 40px;
  }
}

.logo img {
  height: 100%;
  max-width: 100%;
  width: auto;
}
.menu{
  position: relative;
}
.menu-items {
  display: flex;
  align-items: center;
  margin-right: 6px;
}
.menu-item {
  margin-right: 29px;
  text-transform: uppercase;
  cursor: pointer;
}
.menu-items .menu-item:last-child {
  margin-right: 0;
}
.dropdown {
  background: #fff;
  padding: 11px 21px 0;
  transform: translate(-21px, 0);
  box-sizing: border-box;
  position: absolute;
  display: none;
}
.dropdown li{
  line-height: 18px;
  padding: 11px 0;
}
.menu .menu-item:hover .dropdown {
  display: block;
}

.menu-item a {
  font-size: 16px;
  width: 100%;
  display: inline-block;
  font-family: "Roboto-Condensed-Bold";
  color: rgb(22, 140, 207);
  font-weight: bold;
  text-transform: uppercase;
}

.menu-item a:hover {
  color: #000;
  text-decoration: none;
}
.header .btn {
  letter-spacing: 0.5px;
}

@media (max-width: 900px) {
  .logo {
    height: 30px;
  }

  .menu-item {
    margin-right: 15px;
  }
}

@media (max-width: 768px) {
  .logo {
    height: 40px;
  }

  .header {
    width: 100%;
    padding: 9px 0 13px 18px;
    flex-direction: column;
    height: auto;
  }
  .header .menu {
    display: none;
  }

  .header .mobile-menu {
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    background: #fff;
    padding: 19px;
  }

  .header.show-menu .mobile-menu {
    display: block;
    padding: 18px 18px 0 0;
  }
  nav ul {
    width: 100%;
    flex-direction: column;
    max-height: 80vh;
    overflow-y: scroll;
  }

  .menu-item {
    margin: 0;
    line-height: 40px;
  }
  .menu-item a {
    font-size: 1.5rem;
    line-height: 64px;
  }

  .menu-toggle {
    display: block;
    padding: 14px 18px;
  }
  .menu-toggle span {
    display: block;
    width: 34px;
    height: 4px;
    background-color: #878b91;
  }
  .menu-toggle span:nth-child(2) {
    margin: 8px 0;
  }

  .header.show-menu .menu-toggle span:nth-child(1) {
    transform: rotate(45deg) translate(8px, 6px)
  }

  .header.show-menu .menu-toggle span:nth-child(2) {
    transform: rotate(-45deg) translate(2px, 0px)
  }

  .header.show-menu .menu-toggle span:nth-child(3) {
    display: none;
  }
}
</style>
