<template>
  <AccordionItem
    :number="number"
    title="COUNTERPARTS"
  >
    <div class="first-level">
      <p class="number">
        {{ number }}.1.
      </p>
      <p>
        These terms and Works Orders may be executed in any number of
        counterparts, each of which when executed shall constitute a
        duplicate original, but all the counterparts shall together
        constitute the one agreement.
      </p>
    </div>
  </AccordionItem>
</template>

<script>
import AccordionItem from '../../components/AccordionItem.vue';

export default {
  components: {
    AccordionItem,
  },
  props: {
    number: {
      type: String,
      default: null,
    },
  },
};
</script>
