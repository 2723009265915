<template>
  <div :class="parentClass">
    <p class="number">
      {{ number }}.
    </p>
    <!-- eslint-disable vue/no-v-html -->
    <p v-html="content" />
    <!-- eslint-enable -->
  </div>
</template>

<script>
export default {
  props: {
    number: {
      type: String,
      default: null,
    },
    level: {
      type: Number,
      default: 1,
    },
    content: {
      type: String,
      default: '',
    },
  },
  computed: {
    parentClass() {
      const readableLevel = [
        'first', 'second', 'third',
      ][this.level - 1];
      return `${readableLevel}-level`;
    },
  },
};
</script>

<style scoped>
.number {
  margin-right: 20px;
}

.first-level {
  display: flex;
}
.first-level .number {
  min-width: 60px;
}

.second-level {
  display: flex;
  padding-left: 80px;
}
.second-level .number {
  min-width: 90px;
}

.third-level {
  display: flex;
  padding-left: 190px;
}
.third-level .number {
  min-width: 90px;
}

@media (max-width: 768px) {
  .number {
    margin-right: 10px;
  }

  .first-level .number {
    min-width: 40px;
  }

  .second-level {
    padding-left: 50px;
  }
  .second-level .number {
    min-width: 64px;
  }

  .third-level {
    padding-left: 124px;
  }
  .third-level .number {
    min-width: 64px;
  }
}

@media (max-width: 480px) {
  .number {
    margin-right: 0.75rem;
  }
  .first-level .number {
    min-width: auto;
  }

  .second-level {
    padding-left: 1rem;
  }
  .second-level .number {
    min-width: auto;
  }

  .third-level {
    padding-left: 2rem;
  }
  .third-level .number {
    min-width: auto;
  }
}
</style>
