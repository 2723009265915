<template>
  <div class="section">
    <div class="section-inner row">
      <div>
        <div class="block-info">
          <h2 class="title">
            Our Mission
          </h2>
          <hr class="info-hr">
        </div>

        <div class="content">
          <p>
            Cloud KB's mission is to reduce the cost and complexity of data
            management within the utilities sector. We achieve this through cloud-based automation,
            seamless connectivity, rigorous data validation and streamlined exception management.
          </p>
        </div>
      </div>
      <div>
        <div class="block-info">
          <h2 class="title">
            Our Business
          </h2>
          <hr class="info-hr">
        </div>

        <div class="content">
          <p>
            As pioneers in data management and cloud-based solutions, our first
            solution for the utilities sector was in 2012 when we were
            asked to develop a system to manage the regulatory flows for gas exchanges.
            We quickly realised that the sector was reliant upon error-prone manual practices,
            spreadsheets and managed services and set out to provide a modern alternative.
            Today, our configurable intelligent cloud platform is
            trusted to automate complex data management
            and workflow solutions across the utilities sector
            including for MAM's, MAP's, MOP's and suppliers.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
.section {
  padding-top: 107px;
  padding-bottom: 87px;
}

.section-inner > div {
  width: 50%;
  max-width: 507px;
}

@media (max-width: 768px) {
  .section {
    padding-top: 59px;
    padding-bottom: 0;
  }

  .section-inner {
    flex-direction: column;
  }
  .section-inner > div {
    width: 100%;
    max-width: 100%;
    margin: 0;
  }

  .section-inner > div:first-child .block-info {
    margin: 0;
  }

  .block-info  .title {
    text-align: left;
  }

  .info-hr {
    margin: 0;
  }

  .content {
    padding: 17px 0 37px 0;
  }
}
</style>
