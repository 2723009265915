<template>
  <div class="section blog">
    <div class="section-inner">
      <div id="medium-widget" />
      <p v-if="!loading && errorMessage" />
      <p v-if="loading">
        Loading ...
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      scriptUrl: 'https://medium-widget.pixelpoint.io/widget.js',
      loading: false,
      errorMessage: null,
    };
  },
  mounted() {
    this.loading = true;
    this.loadScript()
      .then(() => {
        this.loading = false;
        this.initWidget();
      })
      .catch(() => {
        this.loading = false;
        this.errorMessage = 'Error loading Plaid Link';
      });
  },
  methods: {
    loadScript() {
      return new Promise((resolve, reject) => {
        this.scriptElement = document.createElement('script');
        document.body.appendChild(this.scriptElement);
        this.scriptElement.onload = resolve;
        this.scriptElement.onerror = reject;
        this.scriptElement.src = this.scriptUrl;
      });
    },
    initWidget() {
      window.MediumWidget.Init({
        renderTo: '#medium-widget',
        params: {
          resource: 'https://medium.com/@CloudKB',
          postsPerLine: 3,
          limit: 10,
          picture: 'big',
          fields: ['description', 'author', 'claps', 'publishAt'],
          ratio: 'landscape',
        },
      });
    },
  },
};
</script>

<style lang='css' scoped>
</style>
