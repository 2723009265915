<template>
  <AccordionItem
    :number="number"
    title="SOLUTION, DELIVERY AND ACCEPTANCE"
  >
    <div class="first-level">
      <p class="number">
        {{ number }}.1.
      </p>
      <p>
        Cloud KB shall either utilise agile methodology and processes (as
        set out in Schedule 1) or a fixed price delivery to be described in
        the relevant Works Order, to analyse the Customer’s business requirements
        to provide a technical solution based on the Solution that meets both
        the functional and non-functional needs of the Customer, the detail
        of which shall be contained within the Work Order.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.2.
      </p>
      <p>
        Cloud KB shall make the Software available for the Customer to
        access from the Customer’s own IT environment and such access
        shall constitute valid delivery of the Software.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.3.
      </p>
      <p>
        In beginning to use the Software for business purposes, the Customer
        hereby confirms that it has had the opportunity to test the Software
        and the Software operates in accordance with the Specification.
      </p>
    </div>
  </AccordionItem>
</template>

<script>
import AccordionItem from '../../components/AccordionItem.vue';

export default {
  components: {
    AccordionItem,
  },
  props: {
    number: {
      type: String,
      default: null,
    },
  },
};
</script>
