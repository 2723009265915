<template>
  <div>
    <div class="section privacy-policy">
      <div class="section-inner">
        <div class="block-info">
          <h2 class="title">
            Cloud KB Limited Modern Slavery and Human Trafficking Statement
          </h2>
          <hr class="info-hr">
        </div>
        <div class="content">
          <p>
            Cloud KB Limited ("<strong>Cloud KB</strong>") is committed to understanding potential
            modern slavery risks related to our business and ensuring that there
            is no modern slavery in our own business and supply chains. We do not
            condone modern slavery in any form and Cloud KB complies with all
            applicable laws relating to slavery or servitude, forced or compulsory
            labour and human trafficking, including the Modern Slavery Act 2015
            (the “Act”). While Cloud KB is not formally required to comply with
            the Act, as it does not pass the thresholds for compliance set out in that
            Act, we do not knowingly employ or engage any party who is in breach
            of those applicable laws.
          </p>
        </div>

        <hr class="info-hr">
        <div class="content">
          <p>
            <strong>1. Our Business</strong>
          </p>
          <p>
            Cloud KB is headquartered at 2nd Floor, 25 Gerrard Street, London, W1D 6J, United
            Kingdom. It does not have any overseas affiliates.
          </p>
          <p>
            Cloud KB offers data management and cloud-based solutions within the
            utilities sector. We operate a configurable intelligent cloud
            platform which facilitates the automation of complex data management
            and workflow solutions. Our customers include Meter Asset Managers (MAMs),
            Meter Asset Providers (MAPs), Meter Operators (MOPs) and suppliers.
          </p>
        </div>

        <hr class="info-hr">
        <div class="content">
          <p>
            <strong>2. Our Policies</strong>
          </p>
          <p>
            Cloud KB has policies in place which govern the way our employees work,
            including our <a href="/privacy-policy">privacy policy</a> and
            <a href="/abc">anti-bribery and corruption policy</a>.
            Together with the policies this modern slavery and human trafficking
            statement adds to foundations of how Cloud KB operates and ensure that
            our employees act ethically and in accordance with applicable laws.
          </p>
        </div>

        <hr class="info-hr">
        <div class="content">
          <p>
            <strong>3. Our interaction with suppliers</strong>
          </p>
          <p>
            Considering where we do business, where our suppliers are based,
            and the industry in which we operate, our assessment is that there
            is a very low risk that modern slavery and human trafficking exists
            in our supply chain that would affect our business.
          </p>
          <p>
            Cloud KB purchases [professional services and IT equipment and office
            materials], from third party suppliers, and strives to conduct business
            with suppliers who share our commitment to high ethical standards and
            who act ethically and responsibly towards their workers and suppliers.
            Our suppliers are chosen with care, properly checked, and their
            integrity and ethical business practices are taken into account as
            part of the selection criteria.
          </p>
        </div>

        <hr class="info-hr">
        <div class="content">
          <p>
            <strong>4. Our interaction with customers</strong>
          </p>
          <p>
            Cloud KB acknowledges the fact that many of our clients do qualify
            for the threshold in the Act and have obligations to monitor
            their suppliers to ensure their operations are free from modern
            slavery and human trafficking. Cloud KB is committed to working
            with our clients to support their compliance programmes and the
            voluntary adoption of this statement is a demonstration of
            that commitment.
          </p>
        </div>

        <hr class="info-hr">
        <div class="content">
          <p>
            <strong>5. The approach within Cloud KB</strong>
          </p>
          <p>
            Cloud KB is dedicated to ensuring that human rights are respected
            at all times in our business. We expect our employees to maintain
            the highest ethical standards and comply with all applicable laws and
            company policies. Cloud KB encourages its employees to report any
            suspected wrongdoing or dangers in relation to any activity that falls
            short of those standards, without fear of reprisal. Our directors
            are always available to any member of staff who may have a concern over
            any matter of ethics concerning the operations of both our business and
            those of our suppliers. We believe that encouraging a culture of openness
            and accountability can help to prevent illegal or unethical practices
            from occurring within Cloud KB.
          </p>
        </div>

        <hr class="info-hr">
        <div class="content">
          <p>
            <strong>6. Risk Assessment</strong>
          </p>
          <p>
            Considering the nature of our business, the fact our services are provided
            by professionally qualified and highly skilled people, and the fact that
            the lengths of our supply chains are relatively short, we assess the risk
            of modern slavery within our business and supply chain to be low. We are
            nonetheless committed to ensuring our corporate activities are free from slavery
            and human trafficking and will strive to ensure that our supply chains are also
            free from slavery and human trafficking.
          </p>
        </div>

        <hr class="info-hr">
        <div class="content">
          <p>
            <strong>7. A Progressive Approach to Modern Slavery</strong>
          </p>
          <p>
            Cloud KB accepts that effective action to prevent modern slavery and human
            trafficking in our business and supply chain requires regular monitoring
            and an effective response to any issues identified. Our management take a
            zero-tolerance approach to modern slavery and human trafficking and will
            react accordingly to any transgression by our staff or suppliers.
          </p>
          <p>
            Cloud KB recognises that the risks from modern slavery and human trafficking
            are constantly evolving. We also recognise that efficient methods
            to identify and address modern slavery and human trafficking will be
            developed and enhanced. Cloud KB will continue to monitor and
            evaluate such developments and adapt our approach to maintain
            such robust measures as we currently have in place.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
ul {
  margin: 0 0 2rem 0;
}

a {
  font-size: inherit;
}

@media (max-width: 768px) {
  .block-info .title {
    text-align: left;
  }

  .info-hr {
    margin: 0;
  }
}
</style>
