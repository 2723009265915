<template>
  <AccordionItem
    :number="number"
    title="CUSTOMER RESPONSIBILITIES"
  >
    <div class="first-level">
      <p class="number">
        {{ number }}.1.
      </p>
      <p>
        The Customer shall:
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.1.1.
      </p>
      <p>
        provide Cloud KB with all necessary co-operation in relation to
        these terms and the Works Order and access to such information
        as may be required by Cloud KB in order to provide the Solution;
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.1.2.
      </p>
      <p>
        ensure that the Authorised Users comply with Clause 4 and any
        acceptable use policies specified or provided by Cloud KB from
        time to time for the Solution;
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.1.3.
      </p>
      <p>
        maintain appropriate and prudent IT security arrangements as a
        minimum to those stated within the applicable policy;
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.1.4.
      </p>
      <p>
        provide necessary access to facilities and systems as reasonably
        required by Cloud KB to perform its obligations under these terms and Works Order;
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.1.5.
      </p>
      <p>
        provide necessary access to data, materials and other information
        reasonably required by Cloud KB (in such format as it may require
        from time to time) in connection with the Solution and ensure that
        such data, materials and information are accurate and complete in all material respects;
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.1.6.
      </p>
      <p>
        maintain internet connections and technical capabilities to access
        and use the Solution to comply with the Minimum Technical Requirements.
        The Customer acknowledges that as between the Parties, it is solely
        responsible for ensuring that its firewalls, security and privacy
        systems and settings, and other plug-ins or applications, do not
        interfere with or restrict the Customer's, or its Authorised Users',
        access and use of the Solution, and Cloud KB and its personnel shall
        have no responsibility or liability in relation thereto;
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.1.7.
      </p>
      <p>
        provide such co-operation and personnel assistance as may be
        reasonably requested by Cloud KB from time to time;
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.1.8.
      </p>
      <p>
        comply with all applicable laws and regulations with respect to
        its activities under these terms and Works Order;
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.1.9.
      </p>
      <p>
        carry out all other Customer responsibilities and obligations set
        out in these terms and Works Order, in accordance with any specified
        time scales (or in absence thereof in a timely and efficient manner).
        In the event of any delays in the Customer's provision of such mutually
        agreed assistance, Cloud KB may adjust any timetable or delivery schedule
        set out in these terms or Works Order as reasonably necessary;
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.1.10.
      </p>
      <p>
        ensure that the reputation, goodwill and brand of Cloud KB are
        fully preserved and protected and shall not use the Solution in
        any way as to bring the Solution or Cloud KB into disrepute;
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.1.11.
      </p>
      <p>
        before the Customer uses any updates to any third party software
        in a live environment, subject to Clause 4.3.3.3, carry out testing
        updates to any third party software to its satisfaction, to ensure
        that such updates meet the Customer's own requirements without
        causing any issues with the Customer's use of the Solution;
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.1.12.
      </p>
      <p>
        undertake appropriate back-ups to its data and to secure media with
        such regularity and in such a manner so as to ensure that it can
        restore such data and media in the event of data loss or corruption from any cause;
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.1.13.
      </p>
      <p>
        ensure that the number of persons using the Software does not
        exceed any limits agreed between the parties from time to time;
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.1.14.
      </p>
      <p>
        keep a complete and accurate record of the Customer’s use of the
        Software and its Authorised Users, and produce such record to
        Cloud KB on request from time to time; and
      </p>
    </div>
    <div class="second-level">
      <p class="number">
        {{ number }}.1.15.
      </p>
      <p>
        notify Cloud KB as soon as it becomes aware of any unauthorised
        use of the Software by any person.
      </p>
    </div>
  </AccordionItem>
</template>

<script>
import AccordionItem from '../../components/AccordionItem.vue';

export default {
  components: {
    AccordionItem,
  },
  props: {
    number: {
      type: String,
      default: null,
    },
  },
};
</script>
