<template>
  <div class="section section-transparent">
    <img
      class="api-illustration"
      src="/img/smc/illustration.png"
    >
    <div class="section-inner row api-commissioning-top">
      <div>
        <h2 class="title">
          The APIs
        </h2>
        <hr class="info-hr">
        <div class="content">
          <p>
            For those who already have existing field service apps, our
            standard set of ready-to-go APIs allow for full DCC connectivity
            via any DCC adapter.
          </p>
          <p>
            Using APIs means you can reap the benefits of any DCC Adaptor
            from within your existing field service tools or management
            systems with minimal investment in training or hardware.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.section {
  padding-top: 78px;
  padding-bottom: 78px;
  position: relative;
}

.api-illustration {
  position: absolute;
  top: 110px;
  left: 36%;
  width: 50%;
  max-width: 496px;
  overflow: visible;
  transform: translate(-100%, 0);
}

.api-commissioning-top {
  justify-content: flex-end;
}

.api-commissioning-top>div {
  width: 100%;
  max-width: 731px;
}

@media (max-width: 768px) {
  .section {
    padding: 59px 18px 0 18px;
    position: relative;
  }
  .api-illustration {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    width: 100%;
    margin-bottom: 38px;
  }

  .section-inner {
    flex-direction: column;
  }
  .section-inner > div {
    width: 100%;
    max-width: 100%;
    margin: 0;
  }

  h2.title {
    text-align: left;
  }

  .info-hr {
    margin: 0;
  }

  .content {
    padding: 17px 0 37px 0;
  }
}
</style>
