<template>
  <AccordionItem
    :number="number"
    title="INTELLECTUAL PROPERTY RIGHTS"
  >
    <div class="first-level">
      <p class="number">
        {{ number }}.1.
      </p>
      <p>
        The Customer acknowledges and agrees that Cloud KB owns all Intellectual
        Property Rights in the Solution, Software and any Maintenance Releases.
        Except as expressly stated herein, neither these terms nor any Works
        Order grants the Customer any rights in or to any Intellectual Property
        Rights in respect of the Software.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.2.
      </p>
      <p>
        Cloud KB, its Affiliates and their licensors shall have a royalty-free,
        worldwide, transferable, sub-licensable, irrevocable, perpetual licence
        to use or incorporate into the Software any enhancement requests provided
        by the Customer so long as the Customer is not identified in any way as
        the source of such feedback. For the avoidance of doubt, Cloud KB shall
        not be obliged to implement any such enhancement requires or feedback.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.3.
      </p>
      <p>
        Where either Party acquires, by operation of law, any rights, title
        and/or interest, including Intellectual Property Rights that is
        inconsistent with the allocation of title set out in Clauses {{ number }}.1
        and {{ number }}.2 (as applicable), it shall assign in writing such rights,
        title and/or interest, including Intellectual Property Rights, as it
        has acquired to the other Party on the request of the other Party
        (whenever made) without further consideration.
      </p>
    </div>
  </AccordionItem>
</template>

<script>
import AccordionItem from '../../components/AccordionItem.vue';

export default {
  components: {
    AccordionItem,
  },
  props: {
    number: {
      type: String,
      default: null,
    },
  },
};
</script>
