<template>
  <AccordionItem
    :number="number"
    title="SEVERANCE"
  >
    <div class="first-level">
      <p class="number">
        {{ number }}.1.
      </p>
      <p>
        If any provision or part-provision of these terms and Works Order
        is or becomes invalid, illegal or unenforceable, it shall be
        deemed modified to the minimum extent necessary to make it valid,
        legal and enforceable. If such modification is not possible, the
        relevant provision or part-provision shall be deemed deleted. Any
        modification to or deletion of a provision or part-provision under
        this Clause shall not affect the validity and enforceability of
        the rest of these terms and Works Order.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.2.
      </p>
      <p>
        If any provision or part-provision of these terms and Works Order
        is invalid, illegal or unenforceable, the parties shall negotiate
        in good faith to amend such provision so that, as amended, it is
        legal, valid and enforceable, and, to the greatest extent possible,
        achieves the intended commercial result of the original provision.
      </p>
    </div>
  </AccordionItem>
</template>

<script>
import AccordionItem from '../../components/AccordionItem.vue';

export default {
  components: {
    AccordionItem,
  },
  props: {
    number: {
      type: String,
      default: null,
    },
  },
};
</script>
