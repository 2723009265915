<template>
  <div id="home">
    <HeroSection />
    <IconRow />
    <PartOfCorrela />
    <DiagramSection />
    <Products />
    <FeaturesSection />
    <!-- <Popup
      v-if="popup && youTubeIframeAPIReady"
      @closePopup="popup = false"
    /> -->
  </div>
</template>

<script>
import HeroSection from './HeroSection.vue';
import IconRow from './IconRow.vue';
import DiagramSection from './DiagramSection.vue';
import FeaturesSection from './FeaturesSection.vue';
import Products from './Products.vue';
import PartOfCorrela from './PartOfCorrela.vue';
// import Popup from './Popup.vue';

export default {
  components: {
    HeroSection,
    IconRow,
    DiagramSection,
    FeaturesSection,
    Products,
    PartOfCorrela,
    // Popup,
  },
  data() {
    return {
      // popup: false,
      // youTubeIframeAPIReady: false,
    };
  },
  // created() {
  //   this.loadYouTubeIframeAPI();
  // },
  methods: {
    // showVideoPopup() {
    //   this.popup = true;
    //   if (!this.youTubeIframeAPIReady) {
    //     this.loadYouTubeIframeAPI();
    //   }
    // },
    // loadYouTubeIframeAPI() {
    //   if (window.YT) {
    //     this.youTubeIframeAPIReady = true;
    //     return true;
    //   }
    //
    //   // This code loads the IFrame Player API code asynchronously.
    //   const tag = document.createElement('script');
    //   tag.onload = () => {
    //     this.youTubeIframeAPIReady = true;
    //   };
    //   tag.src = 'https://www.youtube.com/iframe_api';
    //   const firstScriptTag = document.getElementsByTagName('script')[0];
    //   firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    //   return true;
    // },
  },
};
</script>

<style scoped>
  #home {
    background-color: #fff;
  }
</style>
