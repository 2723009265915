<!-- eslint-disable -->
<template>
  <div class="section">
    <!-- <div class="top-text">
      <h2>OPTIMISE YOUR ENERGY ASSET PERFORMANCE THROUGH A SINGLE, TOTALLY INTEGRATED SYSTEM</h2>
    </div> -->
    <div class="row">
      <div>
        <svg version="1.1" viewBox="0 0 110 100" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:bx="https://boxy-svg.com">
          <image
            width="110"
            height="100" xlink:href="/img/diagram/diagram-base.png"
          />
          <text transform="matrix(0.236127, 0, 0, 0.251684, 41.699127, 30.562263)" style="font-size: 30px; font-weight: 700; white-space: pre;" x="0 16.863 33.696 50.589" y="0">Jobs</text>
          <text transform="matrix(0.236127, 0, 0, 0.251684, 57.465225, 69.596138)" style="font-size: 30px; font-weight: 700; white-space: pre;" x="0 20.436 35.802 51.168 67.494 77.79" y="0">Assets</text>
          <text transform="matrix(0.236127, 0, 0, 0.251684, 20.973129, 69.596138)" style="fill: rgb(255, 255, 255); font-size: 30px; font-weight: 700; white-space: pre;" x="0 26.295 45.81 61.245" y="0">MPxN</text>
          <text transform="matrix(0.236127, 0, 0, 0.251684, 68.909317, 15.470772)" style="fill: rgb(35, 31, 32); font-size: 14px; font-weight: 700; white-space: pre;" x="0 8.6072 17.7744 27.6696 35.5488 44.646 53.8552 61.4404 65.5256 75.4068" y="0">SCHEDULING</text>
          <text transform="matrix(0.236127, 0, 0, 0.251684, 76.609238, 42.385872)" style="fill: rgb(35, 31, 32); font-size: 14px; font-weight: 700; white-space: pre;" x="0 9.0314 18.6928 27.2902 35.9576 43.631 53.2924 60.8678 64.9432" y="0">PORTFOLIO</text>
          <text transform="matrix(0.236127, 0, 0, 0.251684, 92.940414, 60.595955)" style="fill: rgb(35, 31, 32); font-size: 14px; font-weight: 700; white-space: pre;" x="0 8.6618 17.6036 26.8814 36.0472 44.933 49.0168 58.8966" y="0">TRACKING</text>
          <text transform="matrix(0.236127, 0, 0, 0.251684, 91.679031, 82.567451)" style="fill: rgb(35, 31, 32); font-size: 14px; font-weight: 700; white-space: pre;" x="0 7.1442 16.8084 26.3466 30.4248 39.039 47.6952 51.7734 60.9336" y="0">LOGISTICS</text>
          <text transform="matrix(0.236127, 0, 0, 0.251684, 0.90062, 82.11219)" style="fill: rgb(35, 31, 32); font-size: 14px; font-weight: 700; white-space: pre;" x="0 9.5368 18.9616" y="0">GAS</text>
          <text transform="matrix(0.236127, 0, 0, 0.251684, 14.478317, 98.093056)" style="fill: rgb(35, 31, 32); font-size: 14px; font-weight: 700; white-space: pre;" x="0 7.875 15.456 23.331 32.298 40.957 49.896 53.977 63.14 67.025 75.796" y="0">ELECTRICITY</text>
          <text transform="matrix(0.236127, 0, 0, 0.251684, 37.77063, 4.294258)" style="fill: rgb(35, 31, 32); font-size: 14px; font-weight: 700; white-space: pre;" x="0 7.6692 11.7544 19.6336 27.2188 39.802 48.4092" y="0">FIELDSERVICE</text>
          <text transform="matrix(0.236127, 0, 0, 0.251684, 17.263016, 12.873482)" style="fill: rgb(35, 31, 32); font-size: 14px; font-weight: 700; white-space: pre;" x="0 9.1602" y="0">CAPACITY</text>
          <text transform="matrix(0.236127, 0, 0, 0.251684, 21.650528, 12.873482)" style="fill: rgb(35, 31, 32); font-size: 14px; font-weight: 700; white-space: pre;" x="0" y="0"> </text>
          <text transform="matrix(0.236127, 0, 0, 0.251684, 23.251419, 12.873482)" style="fill: rgb(35, 31, 32); font-size: 14px; font-weight: 700; white-space: pre;" x="0" y="0"> </text>
        </svg>
      </div>
      <img src="/img/diagram/arrows.png" class="arrows" />
      <div class="sectors-covered">
        <p>SECTORS COVERED</p>
        <div class="sectors">
          <div
            v-for="sector in sectors"
            class="sector"
          >
            <img :src="`/img/diagram/${sector}`">
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="row system-integration">
      <p>System Integration</p>
      <img src="https://c1.sfdcstatic.com/content/dam/sfdc-docs/www/logos/logo-salesforce.svg" alt="salesforce">
      <img src="https://www.sap.com/dam/application/shared/logos/sap-logo-svg.svg"alt="salesforce">
      <img src="https://www.ifs.com/-/media10/project/ifs/ifs/content/logos/logo.svg?h=100&iar=0&w=215&hash=457C7246B75C6A310DD1B6B01C0E1809" alt="ifs">
      <img src="https://upload.wikimedia.org/wikipedia/commons/5/50/Oracle_logo.svg" class="oracle-logo" alt="oracle">
      <img src="https://static.teamviewer.com/resources/2018/04/msdynamics365.png" alt="msdynamics365">
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      sectors: [
        'iea.png',
        'ev.png',
        'esm.png',
        'gsm.png',
        'wm.png',
        'sb.png',
        'ha.png',
        'hp.png',
      ],
    };
  },
};
</script>

<style scoped>
  .section {
    background-image: url(/img/diagram/background.png);
    background-size: cover;
    background-position: bottom center;
    padding: 2rem 0 2rem 0;
  }
  /* .top-text {
    background: #fff;
    padding: 1rem 0;
    margin-bottom: 2rem;
  } */

  h2 {
    max-width: 1000px;
    font-size: 2.4rem;
    margin: 0 auto;
    color: rgb(190, 30, 45);
    text-align: center;
  }

  .row {
      max-width: 1230px;
      position: relative;
      margin: 0 auto;
      padding: 0 1rem;
  }

  .row > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  svg {
    width: 708px;
    position: relative;
    z-index: 2;
  }

  .arrows {
    width: 535px;
    height: auto;
    position: absolute;
    top: 50%;
    right: 375px;
    transform: translate(0, -50%);
    z-index: 1;
  }

  @media (max-width: 1200px) {
    svg {
      width: 560px
    }
    .arrows {
      width: 420px;
    }
  }

  @media (max-width: 1100px) {
    svg {
      width: 480px
    }
    .arrows {
      width: 360px;
    }
  }

  .sectors-covered p {
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 2;
    padding: 0 0.5rem;
  }
  .sectors {
    max-width: 364px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    padding: 1rem;
    border: 1px dashed #ccc;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.3);
  }

  .sector {
    background-image: url('/img/diagram/gradient-circle.png');
    background-size: cover;
    width: 113px;
    height: 120px;
    padding: 5px 11px 11px 5px;
    margin: 0.5rem 0;
  }

  .sector img {
    width: 100%;
  }

  .system-integration {
    background: rgba(255, 255, 255, 0.5);
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    font-weight: bold;
    margin-top: 3rem;
  }

  .system-integration p {
    width: 15%;
  }

  .system-integration img {
    height: 60px;
    margin-left: 0.5rem;
  }
  .system-integration img.oracle-logo {
    height: 30px;
  }

  @media (max-width: 960px) {
    svg {
      width: 400px
    }
    .arrows {
      width: 300px;
      right: 300px;
    }
    .sectors {
      max-width: 280px;
    }

    .sector {
      width: 94.16666px;
      height: 100px;
      padding: 5px 11px 11px 5px;
    }

    .system-integration p {
      width: auto;
    }
    .system-integration img {
      height: 44px;
    }
    .system-integration img.oracle-logo {
      height: 22px;
    }
  }

  @media (max-width: 820px) {
    .arrows {
      right: 260px;
    }
    .sectors {
      max-width: 240px;
    }
    .system-integration img {
      height: 36px;
    }
    .system-integration img.oracle-logo {
      height: 18px;
    }
  }

  @media (max-width: 768px) {
      h2 {
        font-size: 1.6rem;
      }
      .row {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
      .row > div {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      svg {
        width: 100%;
      }

      .arrows {
        display: none;
      }

      .sectors-covered {
        margin-top: 2rem;
      }

      .sectors {
        width: 100%;
        max-width: 100%;
      }
      .system-integration {
        flex-direction: row;
        flex-wrap: wrap;
      }
      .system-integration p {
        width: 100%;
        font-size: 1.4rem;
        margin-bottom: 1rem;
      }
  }
</style>
