<template>
  <div>
    <div class="section cookie-policy">
      <div class="section-inner">
        <div class="block-info">
          <h2 class="title">
            New Terms of Service Proposal
          </h2>
          <hr class="info-hr">
        </div>
        <div class="content">
          <p>
            <strong>Introduction</strong>
          </p>
          <p>
            Cloud KB offers the Solution as a cloud based service and these terms apply to the use of the Solution and
            to the provision of any Additional Services. If you choose to take the Solution by entering into a Works
            Order or you request any Additional Services, and Cloud KB agrees to provide you with the Solution and /or
            with any associated Additional Services (as applicable), these terms and the terms in the Works Order
            form a legally binding contract between you and Cloud KB. It is therefore essential that you read
            and understand these terms before taking out the Solution or requesting any Additional Services.
          </p>
        </div>
        <hr class="info-hr">
        <div class="content">
          <Definitions
            number="1"
          />

          <Interpretation
            number="2"
          />

          <Solution
            number="3"
          />

          <GrantOfRights
            number="4"
          />

          <CustomerResponsibilities
            number="5"
          />

          <DataProtection
            number="6"
          />

          <Assignment
            number="7"
          />

          <MaintenanceReleases
            number="8"
          />

          <Fees
            number="9"
          />

          <ConfidentialityAndPublicity
            number="10"
          />

          <Warranty
            number="11"
          />

          <LimitationOfLiability
            number="12"
          />

          <IntellectualPropertyRights
            number="13"
          />

          <Indemnities
            number="14"
          />

          <TermAndTerminatiion
            number="15"
          />

          <NoWaiver
            number="16"
          />

          <Remedies
            number="17"
          />

          <EntireAgreement
            number="18"
          />

          <Variation
            number="19"
          />

          <Severance
            number="20"
          />

          <Counterparts
            number="21"
          />

          <ThirdPartyRights
            number="22"
          />

          <NoPartnership
            number="23"
          />

          <ForceMajeure
            number="24"
          />

          <Notices
            number="25"
          />

          <ModificationOfTerms
            number="26"
          />

          <GoverningLaw
            number="27"
          />

          <DisputeResolution
            number="28"
          />

          <ModernSlavery
            number="29"
          />

          <Audit
            number="30"
          />
        </div>
        <div class="block-info">
          <h2 class="title">
            Schedule 1
          </h2>
          <hr class="info-hr">
        </div>
        <Schedule />
      </div>
    </div>
  </div>
</template>

<script>
import Definitions from './terms-of-service/Definitions.vue';
import Schedule from './terms-of-service/Schedule.vue';
import Interpretation from './terms-of-service/Interpretation.vue';
import Solution from './terms-of-service/Solution.vue';
import GrantOfRights from './terms-of-service/GrantOfRights.vue';
import CustomerResponsibilities from './terms-of-service/CustomerResponsibilities.vue';
import DataProtection from './terms-of-service/DataProtection.vue';
import Assignment from './terms-of-service/Assignment.vue';
import MaintenanceReleases from './terms-of-service/MaintenanceReleases.vue';
import Fees from './terms-of-service/Fees.vue';
import ConfidentialityAndPublicity from './terms-of-service/ConfidentialityAndPublicity.vue';
import Warranty from './terms-of-service/Warranty.vue';
import LimitationOfLiability from './terms-of-service/LimitationOfLiability.vue';
import IntellectualPropertyRights from './terms-of-service/IntellectualPropertyRights.vue';
import Indemnities from './terms-of-service/Indemnities.vue';
import TermAndTerminatiion from './terms-of-service/TermAndTerminatiion.vue';
import NoWaiver from './terms-of-service/NoWaiver.vue';
import Remedies from './terms-of-service/Remedies.vue';
import EntireAgreement from './terms-of-service/EntireAgreement.vue';
import Variation from './terms-of-service/Variation.vue';
import Severance from './terms-of-service/Severance.vue';
import Counterparts from './terms-of-service/Counterparts.vue';
import ThirdPartyRights from './terms-of-service/ThirdPartyRights.vue';
import NoPartnership from './terms-of-service/NoPartnership.vue';
import ForceMajeure from './terms-of-service/ForceMajeure.vue';
import Notices from './terms-of-service/Notices.vue';
import ModificationOfTerms from './terms-of-service/ModificationOfTerms.vue';
import GoverningLaw from './terms-of-service/GoverningLaw.vue';
import DisputeResolution from './terms-of-service/DisputeResolution.vue';
import ModernSlavery from './terms-of-service/ModernSlavery.vue';
import Audit from './terms-of-service/Audit.vue';

export default {
  components: {
    Definitions,
    Schedule,
    Interpretation,
    Solution,
    GrantOfRights,
    CustomerResponsibilities,
    DataProtection,
    Assignment,
    MaintenanceReleases,
    Fees,
    ConfidentialityAndPublicity,
    Warranty,
    LimitationOfLiability,
    IntellectualPropertyRights,
    Indemnities,
    TermAndTerminatiion,
    NoWaiver,
    Remedies,
    EntireAgreement,
    Variation,
    Severance,
    Counterparts,
    ThirdPartyRights,
    NoPartnership,
    ForceMajeure,
    Notices,
    ModificationOfTerms,
    GoverningLaw,
    DisputeResolution,
    ModernSlavery,
    Audit,
  },
};
</script>

<style>
.number {
  position: relative;
  margin-right: 20px;
}

.first-level {
  display: flex;
}
.first-level .number {
  min-width: 60px;
}

.second-level {
  display: flex;
  padding-left: 80px;
}
.second-level .number {
  min-width: 90px;
}

.third-level {
  display: flex;
  padding-left: 190px;
}
.third-level .number {
  min-width: 90px;
}

@media (max-width: 768px) {
  .number {
    margin-right: 10px;
  }

  .first-level .number {
    min-width: 40px;
  }

  .second-level {
    padding-left: 50px;
  }
  .second-level .number {
    min-width: 64px;
  }

  .third-level {
    padding-left: 124px;
  }
  .third-level .number {
    min-width: 64px;
  }
}

@media (max-width: 480px) {
  .number {
    margin-right: 0.75rem;
  }

  .first-level .number {
    min-width: auto;
  }

  .second-level {
    padding-left: 1rem;
  }
  .second-level .number {
    min-width: auto;
  }

  .third-level {
    padding-left: 2rem;
  }
  .third-level .number {
    min-width: auto;
  }
}
</style>

<style scoped>
@media (max-width: 768px) {
  .content {
    font-size: 1rem;
  }

  .block-info .title {
    text-align: left;
  }

  .info-hr {
    margin: 0;
  }
}
</style>
