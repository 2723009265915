<template>
  <AccordionItem
    :number="number"
    title="FEES AND SERVICE CREDITS"
  >
    <div class="first-level">
      <p class="number">
        {{ number }}.1.
      </p>
      <p>
        In respect of these terms and Works Order the Customer shall pay
        Cloud KB the Fees set out in any Works Order according to the terms
        of the Works Order within thirty (30) calendar days from receipt of
        a valid invoice. For the avoidance of doubt, any fixed price Fees
        shall be payable monthly in advance and any time and materials Fees
        shall be payable monthly in arrears, as appropriate.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.2.
      </p>
      <p>
        In the event the Customer disputes any element of an invoice it
        shall raise it to Cloud KB within five (5) working days from receipt
        of said invoice, detailing its reason for the disputed amount.
        The Parties shall follow the dispute resolution process stated in Clause 28.
        For the avoidance of doubt, the Customer shall pay the undisputed
        amount within the thirty (30) calendar days.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.3.
      </p>
      <p>
        For all undisputed invoices not paid by Customer by the invoice
        due date, and those disputed amounts subsequently found to be valid
        shall then, without limiting Cloud KBs rights and remedies, be subject
        to the application of interest on the overdue amount at the rate of
        eight percent (8%) per annum above the Bank of England’s base rate from
        time to time. Such interest shall accrue on a daily basis from the due
        date until actual payment of the overdue amount, whether before or after
        judgment. The Customer shall pay the interest together with the overdue amount.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.4.
      </p>
      <p>
        The Customer acknowledges that all Fees will be reviewed annually and
        Cloud KB at its discretion may adjust them in line with CPI index (or equivalent),
        to be notified to the Customer at least sixty (60) calendar days in writing
        prior to the increase being applied.
      </p>
    </div>
    <div class="first-level">
      <p class="number">
        {{ number }}.5.
      </p>
      <p>
        <strong>Service Credits</strong>: The Parties agree that any mutually agreed service
        levels specified in the Works Order shall be monitored and reported on by Cloud KB
        on a monthly basis (in arrears), following the period of calibration as stated in
        the Works Order. Upon request by the Customer, any failure of agreed service level
        thresholds may be subject to a service credit (if agreed), which shall be calculated
        by Cloud KB in accordance with the mechanism in the Works Order and shall become due
        and payable upon written request by the Customer at the end of the calendar month
        within which the failure of the service level occurs. In the event that Cloud KB
        achieves its service levels for the following three (3) consecutive months following
        the month of service level failure it shall be entitled to earn back the service
        credits, which shall be payable by the Customer to Cloud KB at the end of that
        third calendar month of achievement. For the avoidance of doubt, the Customer
        agrees that if it fails to provide a written request for service credits within
        three (3) months of being entitled to do so it shall be deemed to have waived
        such service credits, and any right to invoice for such service credits, and
        any obligation Cloud KB has to pay such service credits, shall be extinguished.
      </p>
    </div>
  </AccordionItem>
</template>

<script>
import AccordionItem from '../../components/AccordionItem.vue';

export default {
  components: {
    AccordionItem,
  },
  props: {
    number: {
      type: String,
      default: null,
    },
  },
};
</script>
